import { CircularProgress } from '@mui/material';
import Div from '@jumbo/shared/Div';

const Loader = () => {
  return (
    <Div
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '80vh',
      }}>
      <CircularProgress
        sx={{
          color: theme => theme.palette.primary.main,
        }}
      />
    </Div>
  );
};

export default Loader;
