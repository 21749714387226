import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  API_DELETE,
  API_GET,
  API_POST,
  API_PUT,
} from 'app/utils/constants/api/apiRequests';
import { setLoading, setLoadingFinished } from './loadingSlice';
import { showAlert } from './alertSlice';

const initialState = {
  subjects: [],
  isDataFetched: false,
  classSectionSubjects: [],
  isClassSectionSubjectsFetched: false,
};

// subjects
export const getAllSubjects = createAsyncThunk(
  'subjects/getAllSubjects',
  async (_, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data, message } = await API_GET('/subject');

      if (status) {
        return data;
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const addNewSubject = createAsyncThunk(
  'subject/addNewSubject',
  async (payload, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, message } = await API_POST('/subject', payload);
      if (status) {
        dispatch(
          showAlert({
            type: 'success',
            message: 'Subject Created Successfully',
          }),
        );
        dispatch(getAllSubjects());
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const editSubject = createAsyncThunk(
  'subject/editSubject',
  async (payload, { dispatch }) => {
    dispatch(setLoading());

    try {
      const { status, data, message } = await API_PUT(
        '/subject',
        payload.id,
        payload,
      );
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAllSubjects());
        return data;
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const deleteSubject = createAsyncThunk(
  'subject/deleteSubject',
  async (id, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data, message } = await API_DELETE('/subject', id);
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAllSubjects());
        return data;
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

// class section subjects
export const getAllClassSectionSubjects = createAsyncThunk(
  'subjects/getAllClassSectionSubjects',
  async (_, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data, message } = await API_GET('/class-section-subject');

      if (status) {
        return data;
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const addNewClassSectionSubject = createAsyncThunk(
  'subject/addNewClassSectionSubject',
  async (payload, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, message } = await API_POST(
        '/class-section-subject',
        payload,
      );
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAllClassSectionSubjects());
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const editClassSectionSubject = createAsyncThunk(
  'subject/editClassSectionSubject',
  async (payload, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data, message } = await API_PUT(
        '/class-section-subject',
        payload.id,
        payload,
      );
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAllClassSectionSubjects());
        return data;
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const deleteClassSectionSubject = createAsyncThunk(
  'subject/deleteClassSectionSubject',
  async (id, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data, message } = await API_DELETE(
        '/class-section-subject',
        id,
      );
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAllClassSectionSubjects());
        return data;
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const subjectsSlice = createSlice({
  name: 'subjects',
  initialState,

  extraReducers: builder => {
    builder.addCase(getAllSubjects.fulfilled, (state, { payload }) => {
      state.subjects = payload;
      state.isDataFetched = true;
    });
    builder.addCase(
      getAllClassSectionSubjects.fulfilled,
      (state, { payload }) => {
        state.classSectionSubjects = payload;
        state.isClassSectionSubjectsFetched = true;
      },
    );
  },
});

export default subjectsSlice.reducer;
