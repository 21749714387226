import Login from '../pages/auth-pages/Login/index2';


const authRoutes = [
  {
    path: '/auth/login',
    element: <Login />,
  },

];

export default authRoutes;
