import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import ExaminationList from '../components/ExamFollowUpList';
import ExamFollowUpSidebar from '../components/ExamFollowUpSidebar';
import { getExamFollowUps } from 'app/store/features/examinationSlice';
import { hasPermission } from 'app/utils/appHelpers';
import { PERMISSIONS, PERMISSION_TYPES } from 'app/utils/constants/permissions';

const ExamFollowUp = () => {
  const dispatch = useDispatch();
  const isDataFetched = useSelector(
    state => state.examination.isExamFollowUpsFetched,
  );

  const fetchData = useCallback(() => {
    if (!isDataFetched) dispatch(getExamFollowUps());
  }, [dispatch, isDataFetched]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);
  return (
    <>
      <JumboContentLayout
        sidebar={
          hasPermission(
            [PERMISSIONS.EXAM_TASK_FOLLOWUP],
            [PERMISSION_TYPES.ADD],
          ) && <ExamFollowUpSidebar />
        }>
        {hasPermission(
          [PERMISSIONS.EXAM_TASK_FOLLOWUP],
          [PERMISSION_TYPES.LISTING],
        ) && <ExaminationList />}
      </JumboContentLayout>
    </>
  );
};
export default ExamFollowUp;
