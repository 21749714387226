import React, { useCallback } from 'react';
import { Tooltip } from '@mui/material';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import Div from '@jumbo/shared/Div';

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <Tooltip>
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    </Tooltip>
  );
};

const GenderSeparation = ({ data, isStaff }) => {
  const calculations = useCallback(() => {
    const males = data?.filter(
      item => item?.gender?.toLowerCase().trim() === 'male',
    ).length;

    let results = [];

    if (isStaff) {
      results = [
        {
          name: 'Males',
          value: males,
          color: 'rgb(59 210 162)',
        },
        {
          name: 'Non Working Males',
          value: males,
          color: '#2FA881',
        },
        {
          name: 'Non Working Females',
          value: data.length - males,
          color: '#c0134e',
        },
        {
          name: 'Females',
          value: data.length - males,
          color: 'rgb(233 30 99)',
        },
      ];
    } else {
      results = [
        {
          name: 'Males',
          value: males,
          color: 'rgb(59 210 162)',
        },
        {
          name: 'Females',
          value: data.length - males,
          color: 'rgb(233 30 99)',
        },
      ];
    }

    return results;
  }, [data, isStaff]);

  const results = calculations();

  return (
    <Div
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
      <ResponsiveContainer width="100%" height={160}>
        <PieChart>
          <Pie
            dataKey="value"
            data={results}
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={80}
            fill={'#1e88e5'}>
            {results.map((entry, index) => (
              <Cell key={index} fill={entry.color} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </Div>
  );
};

export default GenderSeparation;
