import WelcomePage from 'app/pages/Welcome';
import AccountsDashboard from '../pages/dashboards/accounts';
import AnalyticsDashboard from '../pages/dashboards/analytics';

const user = JSON.parse(localStorage.getItem('fotanistanUser') || '{}');

const dashboardRoutes = [
  {
    path: '/dashboards/accounts',
    element: user?.user_type === 'admin' && <AccountsDashboard />,
  },
  {
    path: '/dashboards/analytics',
    element: user?.user_type === 'admin' && <AnalyticsDashboard />,
  },
  {
    path: '/welcome',
    element: <WelcomePage />,
  },
];

export default dashboardRoutes;
