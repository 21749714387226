import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNil } from 'lodash';

import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import AddNewBranch from './components/AddNewBranch';
import AllBranches from './components/AllBranches';
import Button from '@jumbo/components/JumboButton';
import { getAllBranches, addNewBranch } from 'app/store/features/branchSlice';
import { Stack, Typography } from '@mui/material';
import { hasPermission } from 'app/utils/appHelpers';
import { PERMISSIONS, PERMISSION_TYPES } from 'app/utils/constants/permissions';

const Branches = () => {
  const { showDialog, hideDialog } = useJumboDialog();
  const dispatch = useDispatch();
  const storeBranches = useSelector(state => state.branches.branches);
  const isBranchesFetched = useSelector(state => state.branches.isDataFetched);
  const user = useSelector(state => state.auth.user);
  const canAddBranch = hasPermission(
    [PERMISSIONS.BRANCH],
    [PERMISSION_TYPES.ADD],
  );
  const [branches, setBranches] = useState([]);

  const fetchBranches = useCallback(async () => {
    if (!isBranchesFetched) {
      dispatch(getAllBranches());
    }
  }, [dispatch, isBranchesFetched]);

  const addNewBranchHandler = useCallback(
    async values => {
      const payload = values;

      dispatch(addNewBranch(payload));
      hideDialog();
    },
    [dispatch, hideDialog],
  );

  const handleClick = useCallback(() => {
    showDialog({
      title: 'Add New Branch',
      content: <AddNewBranch onSave={addNewBranchHandler} />,
    });
  }, [showDialog, addNewBranchHandler]);

  useEffect(() => {
    fetchBranches();
  }, [fetchBranches]);

  useEffect(() => {
    if (canAddBranch && storeBranches.length > 0 && !isNil(user.branch_id)) {
      const filteredBranches = storeBranches.filter(
        branch => branch.id === user.branch_id,
      );
      setBranches(filteredBranches);
    } else {
      setBranches(storeBranches);
    }
  }, [canAddBranch, storeBranches, user]);

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h2" sx={{ m: 0 }}>
          Branches
        </Typography>
        {canAddBranch && (
          <Button onClick={handleClick} variant={'contained'} color="primary">
            Add New Branch
          </Button>
        )}
      </Stack>

      <AllBranches branches={branches} setBranches={setBranches} />
    </>
  );
};

export default Branches;
