import Div from '@jumbo/shared/Div';
import { Typography } from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';

const StackedChart = ({ payload }) => {
  if (!payload) {
    return (
      <Typography sx={{ textAlign: 'center' }}>
        No Attendance to Show
      </Typography>
    );
  }
  const data = Object.entries(payload).map(([classSectionId, counts]) => ({
    classSectionId,
    ...counts,
  }));

  return (
    <Div sx={{ width: '100%' }}>
      <BarChart width={1000} height={300} data={data}>
        <CartesianGrid strokeDasharray="1 1" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="present" stackId="a" fill="#82ca9d" barSize={32} />
        <Bar dataKey="excused" stackId="a" fill="#8884d8" barSize={32} />
        <Bar dataKey="unexcused" stackId="a" fill="#ffc658" barSize={32} />
        <Bar dataKey="late" stackId="a" fill="#ff7300" barSize={32} />
      </BarChart>
    </Div>
  );
};

export default StackedChart;
