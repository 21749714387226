import { memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import Div from '@jumbo/shared/Div';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';

import ExpenseForm from '../ExpenseForm';
import ExpenseCategoryForm from '../ExpenseCategoryForm';
import { hasPermission } from 'app/utils/appHelpers';
import { PERMISSIONS, PERMISSION_TYPES } from 'app/utils/constants/permissions';

const AccountsSidebar = () => {
  const navigate = useNavigate('');
  const { showDialog } = useJumboDialog();

  const showAddExpense = useCallback(() => {
    showDialog({
      title: 'Add New Expense',
      content: <ExpenseForm />,
    });
  }, [showDialog]);

  const showAddExpenseCategory = useCallback(() => {
    showDialog({
      title: 'Add New Expense Category',
      content: <ExpenseCategoryForm />,
    });
  }, [showDialog]);

  return (
    <>
      <Div
        sx={{
          display: 'flex',
          alignItems: 'center',
          mb: 3,
        }}>
        <Div sx={{ flex: '1 1 auto' }}>
          <Typography variant={'h2'} mb={0.35}>
            Expenses
          </Typography>
        </Div>
      </Div>

      {hasPermission([PERMISSIONS.BILL], [PERMISSION_TYPES.ADD]) && (
        <Button
          fullWidth
          disableElevation
          variant={'contained'}
          startIcon={<PersonAddIcon />}
          sx={{
            mb: 2,
            '& .MuiSvgIcon-root': {
              fontSize: '1.5rem',
            },
          }}
          onClick={() => navigate('/app/accounts/add-bill')}>
          Add New Bill
        </Button>
      )}

      {hasPermission([PERMISSIONS.EXPENSE], [PERMISSION_TYPES.ADD]) && (
        <Button
          fullWidth
          disableElevation
          variant={'contained'}
          startIcon={<PersonAddIcon />}
          sx={{
            mb: 2,
            '& .MuiSvgIcon-root': {
              fontSize: '1.5rem',
            },
          }}
          onClick={showAddExpense}>
          Add New Expense
        </Button>
      )}
      {hasPermission(
        [PERMISSIONS.EXPENSE_CATEGORY],
        [PERMISSION_TYPES.ADD],
      ) && (
        <Button
          fullWidth
          disableElevation
          variant={'contained'}
          sx={{
            mb: 2,
            '& .MuiSvgIcon-root': {
              fontSize: '1.5rem',
            },
          }}
          onClick={showAddExpenseCategory}>
          + Expense Category
        </Button>
      )}
    </>
  );
};
export default memo(AccountsSidebar);
