import { useDispatch } from 'react-redux';
import { Form, Formik } from 'formik';

import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import Div from '@jumbo/shared/Div';
import Button from '@jumbo/components/JumboButton';

import { schema } from '../../utils/schema';
import {
  createOccupation,
  editOccupation,
} from 'app/store/features/configurationSlice';
import JumboSwitch from '@jumbo/components/JumboFormik/JumboSwitch';

const FormComponent = ({ payload }) => {
  const dispatch = useDispatch();
  const { hideDialog } = useJumboDialog();

  const isUpdate = payload ? true : false;
  const initialValues = {
    name: payload ? payload.name : '',
    status: payload ? payload.status : false,
  };

  const addHandler = data => {
    dispatch(createOccupation(data));
  };

  const updateHandler = data => {
    const payload = {
      id: data.id,
      name: data.name,
      status: data.status,
    };
    dispatch(editOccupation(payload));
  };

  const onSave = data => {
    isUpdate ? updateHandler(data) : addHandler(data);
    hideDialog();
  };
  return (
    <Formik
      validateOnChange={true}
      initialValues={payload?.id ? payload : initialValues}
      enableReinitialize={true}
      validationSchema={schema}
      onSubmit={onSave}>
      {({ isSubmitting }) => (
        <Form noValidate autoComplete="off">
          <Div
            sx={{
              '& .MuiTextField-root': {
                mb: 3,
              },
            }}>
            <JumboTextField
              fullWidth
              size="small"
              variant="outlined"
              name="name"
              label="Occupation name"
            />
            <JumboSwitch name="status" label="Is Occupation Active?" />

            <Div
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}>
              <Button
                variant="outlined"
                sx={{
                  mr: 2,
                }}
                onClick={() => hideDialog()}>
                Close
              </Button>
              <Button type="submit" variant="contained" loading={isSubmitting}>
                Save
              </Button>
            </Div>
          </Div>
        </Form>
      )}
    </Formik>
  );
};

export default FormComponent;
