import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import DefaulterList from './List';
import { hasAnyPermissions } from 'app/utils/appHelpers';
import { PERMISSIONS } from 'app/utils/constants/permissions';

const DefaulterStudents = () => {
  return (
    <JumboContentLayout>
      {hasAnyPermissions([PERMISSIONS.FEE]) && <DefaulterList />}
    </JumboContentLayout>
  );
};
export default DefaulterStudents;
