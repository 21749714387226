import { useDispatch } from 'react-redux';

import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { Form, Formik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import Div from '@jumbo/shared/Div';

import { itemSchema } from '../../utils/schema';
import { addNewItem, editItem } from 'app/store/features/itemSlice';
import { FormControlLabel, Switch } from '@mui/material';

const ItemForm = ({ item }) => {
  const Swal = useSwalWrapper();
  const dispatch = useDispatch();
  const { hideDialog } = useJumboDialog();

  const isUpdate = item ? true : false;
  const initialValues = {
    name: item ? item.name : '',
    description: item ? item.description : '',
    user_item_code: item ? item.user_item_code : '',
    price: item ? item.price : '',
    group: item ? item.group?.id : '',
    current_stock: item ? item.current_stock : '',
    min_threshhold: item ? item.min_threshhold : '',
    is_stockable: item ? item.is_stockable : true,
    status: item ? item.status : true,
  };

  const addItemHandler = data => {
    if (!data.current_stock) {
      delete data['current_stock'];
    }

    dispatch(addNewItem(data));
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Item has been added successfully.',
      showConfirmButton: false,
      timer: 1500,
    });
    // hideDialog();
  };

  const updateItemHandler = data => {
    dispatch(editItem(data));
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Item has been updated successfully.',
      showConfirmButton: false,
      timer: 1500,
    });
    // hideDialog();
  };

  const onItemSave = (data, { setSubmitting }) => {
    setSubmitting(true);
    isUpdate ? updateItemHandler(data) : addItemHandler(data);
    setSubmitting(false);
  };
  return (
    <Formik
      validateOnChange={true}
      initialValues={item?.id ? item : initialValues}
      enableReinitialize={true}
      validationSchema={itemSchema}
      onSubmit={onItemSave}>
      {({ isSubmitting, values, setFieldValue }) => (
        <Form noValidate autoComplete="off">
          <Div
            sx={{
              '& .MuiTextField-root': {
                mb: 3,
              },
            }}>
            <JumboTextField
              fullWidth
              size="small"
              variant="outlined"
              name="name"
              label="Name"
            />
            <JumboTextField
              fullWidth
              size="small"
              variant="outlined"
              name="description"
              label="Item Description"
            />
            <JumboTextField
              fullWidth
              size="small"
              variant="outlined"
              name="user_item_code"
              label="User Item Code"
              type="number"
            />
            <JumboTextField
              fullWidth
              type="number"
              size="small"
              variant="outlined"
              name="price"
              label="Item Price"
            />

            <JumboTextField
              fullWidth
              size="small"
              variant="outlined"
              name="group"
              label="Item Group Name"
            />

            <JumboTextField
              fullWidth
              type="number"
              size="small"
              variant="outlined"
              name="current_stock"
              label="Item's Current Stock"
              disabled={!values.is_stockable}
            />

            <JumboTextField
              fullWidth
              type="number"
              size="small"
              variant="outlined"
              name="min_threshhold"
              label="Item's Minimum Threshhold"
              disabled={!values.is_stockable}
            />

            <FormControlLabel
              control={
                <Switch
                  checked={values.is_stockable}
                  onChange={e =>
                    setFieldValue('is_stockable', e.target.checked)
                  }
                />
              }
              label={'Is Item Stock able?'}
              sx={{ mb: 2 }}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={values.status}
                  onChange={e => setFieldValue('status', e.target.checked)}
                />
              }
              label={'Status of Item?'}
              sx={{ mb: 2 }}
            />

            <Div
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}>
              <LoadingButton
                type="submit"
                variant="outlined"
                sx={{ mr: 2 }}
                onClick={() => hideDialog()}>
                Close
              </LoadingButton>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}>
                {item ? 'Update' : 'Save'}
              </LoadingButton>
            </Div>
          </Div>
        </Form>
      )}
    </Formik>
  );
};
ItemForm.defaultProps = {
  onSave: () => {},
};
export default ItemForm;
