import React from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import {
  Avatar,
  Divider,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Div from '@jumbo/shared/Div';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { payFee } from 'app/store/features/feeSlice';
import { formatDate } from '@jumbo/utils';
import { paymentSchema } from '../../utils/schema';
import { getFullName } from 'app/utils/appHelpers';
import { PUBLIC_ASSETS_URL } from 'app/utils/constants/api/constants';
import LabelWithBoldContent from '@jumbo/components/Pdf/components/LabelWithBoldContent';

const PayFee = ({ fees }) => {
  const dispatch = useDispatch();
  const { hideDialog } = useJumboDialog();
  const Swal = useSwalWrapper();

  const { errors, handleSubmit, handleChange, values, touched, isSubmitting } =
    useFormik({
      initialValues: { fees, received_amount: '' },
      validationSchema: paymentSchema,
      onSubmit: data => {
        let paid_amount = 0;
        const fees = data.fees.map(item => {
          paid_amount += parseFloat(item.paid_amount || 0);
          return { paid_amount: item.paid_amount, fee_id: item.id };
        });
        dispatch(payFee({ fees }));

        if (data.received_amount - paid_amount > 0) {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: `Fee has been Paid successfully. Please return back amount Rs. ${
              data.received_amount - paid_amount
            }.`,
            showConfirmButton: true,
          });
        }
        hideDialog();
      },
    });
  return (
    <form onSubmit={handleSubmit}>
      <TextField
        fullWidth
        size="small"
        variant="outlined"
        name="received_amount"
        label="Amount Received"
        type="number"
        onChange={handleChange}
        value={values.received_amount}
        error={
          (touched.received_amount || touched.fees) &&
          Boolean(
            errors.received_amount || typeof errors.fees === 'string'
              ? errors.fees
              : '',
          )
        }
        helperText={
          (touched.received_amount || touched.fees) &&
          (errors.received_amount || typeof errors.fees === 'string'
            ? errors.fees
            : '')
        }
      />
      {values.fees.map((fee, index) => (
        <React.Fragment key={fee?.id}>
          <Stack direction={'row'} gap={3} my={4}>
            <Avatar
              alt={fee?.student?.first_name}
              src={`${PUBLIC_ASSETS_URL}/${fee?.student?.image}`}
              variant="circular"
              sx={{
                height: 100,
                width: 100,
              }}
            />
            <Stack>
              <LabelWithBoldContent
                label={'Student: '}
                content={getFullName({
                  first_name: fee?.student?.father_name,
                  last_name: fee?.student?.last_name,
                  father_name: fee?.student?.father_name,
                })}
              />
              <LabelWithBoldContent
                label={'Registration #: '}
                content={fee?.student?.registration_no}
              />
            </Stack>
          </Stack>
          <TableContainer
            sx={{
              boxShadow: 0,
              mb: 6,
              maxHeight: '350px',
            }}
            component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Fee Name</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fee?.fee_items.map((item, idx) => (
                  <TableRow key={idx}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.quantity}</TableCell>
                    <TableCell>
                      {formatDate(item.createdAt, 'DD MMMM YYYY hh:mm A')}
                    </TableCell>
                    <TableCell>Rs. {item.amount}</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={3} sx={{ fontWeight: 'bold' }}>
                    Total Amount
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>
                    Rs. {fee?.total}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                name={`fees[${index}].paid_amount`}
                label="Fee added"
                type="number"
                onChange={handleChange}
                value={fee?.paid_amount}
                error={
                  touched?.fees?.[index]?.paid_amount &&
                  Boolean(errors.fees?.[index]?.paid_amount)
                }
                helperText={
                  touched.fees?.[index]?.paid_amount &&
                  errors.fees?.[index]?.paid_amount
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                name={`fees[${index}].confirm_amount`}
                label="Confirm Amount"
                type="number"
                onChange={handleChange}
                value={fee?.confirm_amount}
                error={Boolean(errors.fees?.[index]?.confirm_amount)}
                helperText={errors.fees?.[index]?.confirm_amount}
              />
            </Grid>
          </Grid>
          <Divider sx={{ my: 4 }} />
        </React.Fragment>
      ))}
      <Div
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}>
        <LoadingButton
          variant="outlined"
          sx={{ mr: 2 }}
          onClick={() => hideDialog()}>
          Close
        </LoadingButton>
        <LoadingButton
          type="submit"
          variant="contained"
          size="medium"
          loading={isSubmitting}
          sx={{ mr: 2 }}>
          Pay
        </LoadingButton>
      </Div>
    </form>
  );
};

export default PayFee;
