import React, { useCallback, useRef, useState } from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Stack,
} from '@mui/material';
import { formatDate } from '@jumbo/utils';

import {
  StyledTableCell,
  StyledTableCellLeftAlign,
} from '../components/styled';
import { LoadingButton } from '@mui/lab';
import { useReactToPrint } from 'react-to-print';

const ExpenseReport = ({ expenses }) => {
  const [loading, setLoading] = useState();
  const componentRef = useRef(null);

  const handleBeforePrint = useCallback(() => {
    setLoading(true);
  }, []);

  const handleAfterPrint = useCallback(() => {
    setLoading(false);
  }, []);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Expense Report ${formatDate(Date.now(), 'DD-MMMM-YYYY-hh:mm A')}`,
    removeAfterPrint: true,
    onBeforePrint: handleBeforePrint,
    onAfterPrint: handleAfterPrint,
  });

  return (
    <>
      <LoadingButton
        variant="contained"
        size="small"
        loading={loading}
        onClick={handlePrint}
        sx={{ position: 'absolute', top: 15 }}>
        Print
      </LoadingButton>
      <Box px={2} py={0} ref={componentRef} minWidth={950}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="start"
          borderBottom="2.5px solid"
          py={2}>
          <Stack>
            <Typography>
              From Date: {formatDate(new Date(), 'DD-MMMM-YYYY')}
            </Typography>
          </Stack>
          <Typography variant="h3" fontWeight={600} fontSize={16}>
            Expense Report
          </Typography>

          <Typography variant="h4" fontWeight={600} fontSize={16}>
            FOTAN Central School
          </Typography>
        </Stack>
        <TableContainer sx={{ boxShadow: 0 }}>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                <StyledTableCell>#</StyledTableCell>
                <StyledTableCell>Title</StyledTableCell>
                <StyledTableCell>Details</StyledTableCell>
                <StyledTableCell>Expense Category</StyledTableCell>
                <StyledTableCell>Is Compulsory</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
              </TableRow>
            </TableHead>

            {expenses.map((expense, index) => (
              <TableBody key={expense.id}>
                <StyledTableCell>{index + 1}</StyledTableCell>
                <StyledTableCellLeftAlign>
                  {expense?.title}
                </StyledTableCellLeftAlign>
                <StyledTableCellLeftAlign>
                  {expense.detail}
                </StyledTableCellLeftAlign>

                <StyledTableCellLeftAlign>
                  {expense?.expense_category?.title}
                </StyledTableCellLeftAlign>

                <StyledTableCell>
                  {expense?.is_compulsory ? 'Yes' : 'No'}
                </StyledTableCell>
                <StyledTableCell>
                  {expense?.status ? 'Active' : 'Inactive'}
                </StyledTableCell>
              </TableBody>
            ))}
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};
export default ExpenseReport;
