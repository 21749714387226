import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  alpha,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Tooltip,
  Avatar,
  IconButton,
  CardHeader,
} from '@mui/material';
import {
  Person,
  AcUnit,
  FilterTiltShift,
  InsertInvitation,
  Book,
  FollowTheSigns,
  Message,
} from '@mui/icons-material';

import Divider from '@mui/material/Divider';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import List from '@mui/material/List';
import Div from '@jumbo/shared/Div';
import Scrollbars from 'react-custom-scrollbars-2';

const ItemDetails = ({ item, onClose }) => {
  return (
    <Div sx={{ m: theme => theme.spacing(-2.5, -3) }}>
      <CardHeader
        title={
          <Tooltip title="Branch">
            <Typography variant={'h6'} color={'text.primary'} mb={0.25}>
              {item?.branch?.name}
            </Typography>
          </Tooltip>
        }
        subheader={
          <Tooltip title="Final Reviewer">
            <Typography variant={'body1'} color={'text.secondary'}>
              {item?.al_final_reviewer?.username}
            </Typography>
          </Tooltip>
        }
        avatar={<Avatar src={item?.profile_pic} />}
        action={
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        }
      />

      <Scrollbars style={{ height: 500 }}>
        <List disablePadding>
          {/* dates */}
          <ListItem sx={{ px: 4 }}>
            <ListItemAvatar sx={{ minWidth: 66 }}>
              <Avatar
                variant="rounded"
                sx={{
                  height: 48,
                  width: 48,
                  bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
                }}>
                <InsertInvitation sx={{ color: 'primary.light' }} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography variant={'body1'} color={'text.secondary'} mb={0.5}>
                  Date Sheet Date
                </Typography>
              }
              secondary={
                <Typography variant={'body2'} mb={0}>
                  {item?.date_sheet_date}
                </Typography>
              }
            />
          </ListItem>
          <Divider component={'li'} />

          <ListItem sx={{ px: 4 }}>
            <ListItemAvatar sx={{ minWidth: 66 }}>
              <Avatar
                variant="rounded"
                sx={{
                  height: 48,
                  width: 48,
                  bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
                }}>
                <InsertInvitation sx={{ color: 'primary.light' }} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography variant={'body1'} color={'text.secondary'} mb={0.5}>
                  Exam Start Date
                </Typography>
              }
              secondary={
                <Typography variant={'body2'} mb={0}>
                  {item?.exam_start_date}
                </Typography>
              }
            />
          </ListItem>
          <Divider component={'li'} />

          <ListItem sx={{ px: 4 }}>
            <ListItemAvatar sx={{ minWidth: 66 }}>
              <Avatar
                variant="rounded"
                sx={{
                  height: 48,
                  width: 48,
                  bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
                }}>
                <InsertInvitation sx={{ color: 'primary.light' }} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography variant={'body1'} color={'text.secondary'} mb={0.5}>
                  Exam Result Date
                </Typography>
              }
              secondary={
                <Typography variant={'body2'} mb={0}>
                  {item?.exam_result_date}
                </Typography>
              }
            />
          </ListItem>

          {/* exam types/terms */}
          <Divider component={'li'} />
          <ListItem sx={{ px: 4 }}>
            <ListItemAvatar sx={{ minWidth: 66 }}>
              <Avatar
                variant="rounded"
                sx={{
                  height: 48,
                  width: 48,
                  bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
                }}>
                <MailOutlineIcon sx={{ color: 'primary.light' }} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography variant={'body1'} color={'text.secondary'} mb={0.5}>
                  Exam Type
                </Typography>
              }
              secondary={
                <Typography variant={'body2'} mb={0}>
                  {item?.exam_type?.charAt(0)?.toUpperCase() +
                    item?.exam_type?.slice(1)}
                </Typography>
              }
            />
          </ListItem>
          <Divider component={'li'} />
          <ListItem sx={{ px: 4 }}>
            <ListItemAvatar sx={{ minWidth: 66 }}>
              <Avatar
                variant="rounded"
                sx={{
                  height: 48,
                  width: 48,
                  bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
                }}>
                <Book sx={{ color: 'primary.light' }} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography variant={'body1'} color={'text.secondary'} mb={0.5}>
                  Exam Term
                </Typography>
              }
              secondary={
                <Typography variant={'body2'} mb={0}>
                  {item.exam_term}
                </Typography>
              }
            />
          </ListItem>
          <Divider component={'li'} />

          {/* users */}
          <ListItem sx={{ px: 4 }}>
            <ListItemAvatar sx={{ minWidth: 66 }}>
              <Avatar
                variant="rounded"
                sx={{
                  height: 48,
                  width: 48,
                  bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
                }}>
                <Person sx={{ color: 'primary.light' }} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography variant={'body1'} color={'text.secondary'} mb={0.5}>
                  Date Sheet Initiator
                </Typography>
              }
              secondary={
                <Typography variant={'body2'}>
                  {item?.date_sheet_initiator?.username}
                </Typography>
              }
            />
          </ListItem>
          <Divider component={'li'} />
          <ListItem sx={{ px: 4 }}>
            <ListItemAvatar sx={{ minWidth: 66 }}>
              <Avatar
                variant="rounded"
                sx={{
                  height: 48,
                  width: 48,
                  bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
                }}>
                <Person sx={{ color: 'primary.light' }} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography variant={'body1'} color={'text.secondary'} mb={0.5}>
                  Head Examiner
                </Typography>
              }
              secondary={
                <Typography variant={'body2'}>
                  {item?.head_examiner?.username}
                </Typography>
              }
            />
          </ListItem>
          <Divider component={'li'} />
          <ListItem sx={{ px: 4 }}>
            <ListItemAvatar sx={{ minWidth: 66 }}>
              <Avatar
                variant="rounded"
                sx={{
                  height: 48,
                  width: 48,
                  bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
                }}>
                <Person sx={{ color: 'primary.light' }} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography variant={'body1'} color={'text.secondary'} mb={0.5}>
                  Remarks Final Reviewer
                </Typography>
              }
              secondary={
                <Typography variant={'body2'}>
                  {item?.remarks_final_reviewer?.username}
                </Typography>
              }
            />
          </ListItem>
          <Divider component={'li'} />

          {/* stats */}
          <ListItem sx={{ px: 4 }}>
            <ListItemAvatar sx={{ minWidth: 66 }}>
              <Avatar
                variant="rounded"
                sx={{
                  height: 48,
                  width: 48,
                  bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
                }}>
                <AcUnit sx={{ color: 'primary.light' }} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography variant={'body1'} color={'text.secondary'} mb={0.5}>
                  Is Exam Freezed?
                </Typography>
              }
              secondary={
                <Typography variant={'body2'}>
                  {item?.is_freeze ? 'Yes' : 'No'}
                </Typography>
              }
            />
          </ListItem>
          <Divider component={'li'} />

          <ListItem sx={{ px: 4 }}>
            <ListItemAvatar sx={{ minWidth: 66 }}>
              <Avatar
                variant="rounded"
                sx={{
                  height: 48,
                  width: 48,
                  bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
                }}>
                <Book sx={{ color: 'primary.light' }} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography variant={'body1'} color={'text.secondary'} mb={0.5}>
                  Is Exam Published?
                </Typography>
              }
              secondary={
                <Typography variant={'body2'}>
                  {item?.is_publish ? 'Yes' : 'No'}
                </Typography>
              }
            />
          </ListItem>
          <Divider component={'li'} />

          <ListItem sx={{ px: 4 }}>
            <ListItemAvatar sx={{ minWidth: 66 }}>
              <Avatar
                variant="rounded"
                sx={{
                  height: 48,
                  width: 48,
                  bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
                }}>
                <Book sx={{ color: 'primary.light' }} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography variant={'body1'} color={'text.secondary'} mb={0.5}>
                  Is Exam Remarks Available?
                </Typography>
              }
              secondary={
                <Typography variant={'body2'}>
                  {item?.is_remarks ? 'Yes' : 'No'}
                </Typography>
              }
            />
          </ListItem>
          <Divider component={'li'} />

          <ListItem sx={{ px: 4 }}>
            <ListItemAvatar sx={{ minWidth: 66 }}>
              <Avatar
                variant="rounded"
                sx={{
                  height: 48,
                  width: 48,
                  bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
                }}>
                <Message sx={{ color: 'primary.light' }} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography variant={'body1'} color={'text.secondary'} mb={0.5}>
                  Is SMS sent?
                </Typography>
              }
              secondary={
                <Typography variant={'body2'}>
                  {item?.sms_flag ? 'Yes' : 'No'}
                </Typography>
              }
            />
          </ListItem>
          <Divider component={'li'} />

          <ListItem sx={{ px: 4 }}>
            <ListItemAvatar sx={{ minWidth: 66 }}>
              <Avatar
                variant="rounded"
                sx={{
                  height: 48,
                  width: 48,
                  bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
                }}>
                <FollowTheSigns sx={{ color: 'primary.light' }} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography variant={'body1'} color={'text.secondary'} mb={0.5}>
                  Is Exam Initiator Test?
                </Typography>
              }
              secondary={
                <Typography variant={'body2'}>
                  {item?.test_flag ? 'Yes' : 'No'}
                </Typography>
              }
            />
          </ListItem>
          <Divider component={'li'} />

          <ListItem sx={{ px: 4 }}>
            <ListItemAvatar sx={{ minWidth: 66 }}>
              <Avatar
                variant="rounded"
                sx={{
                  height: 48,
                  width: 48,
                  bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
                }}>
                <FilterTiltShift sx={{ color: 'primary.light' }} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography variant={'body1'} color={'text.secondary'} mb={0.5}>
                  Shift
                </Typography>
              }
              secondary={
                <Typography component={'div'}>
                  {item?.shift?.charAt(0).toUpperCase() + item?.shift?.slice(1)}
                </Typography>
              }
            />
          </ListItem>
          <Divider component={'li'} />
        </List>
      </Scrollbars>
    </Div>
  );
};

export default ItemDetails;
