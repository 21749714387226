import * as yup from 'yup';

export const classSchema = yup.object().shape({
  branch_id: yup.number().positive().required('Branch is required'),
  class: yup.string().max(25).required('Class is required'),
  section: yup.string().max(25).required('Section is required'),
  shift: yup
    .string()
    .oneOf(['morning', 'evening'])
    .required('Shift is required'),
  incharge_id: yup.number().positive().required('Incharge is required'),
  class_section_title: yup
    .string()
    .max(50)
    .required('Class Section Title is required'),
  max_strength: yup.number().positive().required('Max Strength is required'),
  min_strength: yup.number().positive().required('Min Strength is required'),
  fee_type: yup.string().required('Fee Type is required'),
  fee_frequency: yup
    .string()
    .required('Fee Frequency is required')
    .label('Fee Frequency'),
  fee_amount: yup.number().min(0).required('Fee Amount is required'),
  is_pre_admission: yup.boolean().required('Pre Admission is required'),
  is_refundable: yup.boolean().required('Refundable is required'),
  is_full_day: yup.boolean().required('Full Day is required'),
  status: yup.boolean().required('Status is required'),
  class_section_subject: yup
    .array()
    .of(
      yup.object().shape({
        subject_id: yup.number().positive().required('Subject is required'),
        teacher_id: yup.number().positive().required('Teacher is required'),
        max_marks: yup.number().positive().required('Max Marks are required'),
        passing_percentage: yup
          .number()
          .min(0)
          .max(100)
          .required('Passing Percentage is required'),
        is_optional: yup.boolean().required('Is Optional is required'),
        student_paper_review: yup
          .boolean()
          .required('Student Paper Review is required'),
        status: yup.boolean(),
      }),
    )
    .min(1, 'At least one subject is required'),
});
