import { Alert, Snackbar } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';

import { hideAlert } from 'app/store/features/alertSlice';

const CustomAlert = () => {
  const dispatch = useDispatch();

  const showAlert = useSelector(state => state.alert.showAlert);
  const alertMessage = useSelector(state => state.alert.alertMessage);
  const alertType = useSelector(state => state.alert.alertType);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(hideAlert());
  };

  return showAlert ? (
    <Snackbar
      open={showAlert}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      autoHideDuration={3000}
      onClose={handleClose}>
      <Alert severity={alertType} onClose={handleClose}>
        {alertMessage}
      </Alert>
    </Snackbar>
  ) : (
    <></>
  );
};

export default CustomAlert;
