import React from 'react';
import { Typography } from '@mui/material';
import Span from '@jumbo/shared/Span';

const LabelWithBoldContent = ({ label = '', content = '' }) => {
  return (
    <Typography variant="subtitle1" sx={{ mt: 1 }}>
      {label} <Span sx={{ fontWeight: 'bold' }}>{content}</Span>
    </Typography>
  );
};

export default LabelWithBoldContent;
