import React, { useCallback, useRef, useState } from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Stack,
} from '@mui/material';
import { formatDate } from '@jumbo/utils';

import {
  StyledTableCell,
  StyledTableCellLeftAlign,
} from '../components/styled';
import { LoadingButton } from '@mui/lab';
import { useReactToPrint } from 'react-to-print';

const NonExpenseReport = ({ nonExpenses }) => {
  const [loading, setLoading] = useState();
  const componentRef = useRef(null);

  const handleBeforePrint = useCallback(() => {
    setLoading(true);
  }, []);

  const handleAfterPrint = useCallback(() => {
    setLoading(false);
  }, []);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `None Expense Report ${formatDate(Date.now(), 'DD-MMMM-YYYY-hh:mm A')}`,
    removeAfterPrint: true,
    onBeforePrint: handleBeforePrint,
    onAfterPrint: handleAfterPrint,
  });
  const calculateTotal = () => {
    return nonExpenses?.reduce((total, item) => {
      return total + item?.paid_amount;
    }, 0);
  };
  return (
    <>
      <LoadingButton
        variant="contained"
        size="small"
        loading={loading}
        onClick={handlePrint}
        sx={{ position: 'absolute', top: 15 }}>
        Print
      </LoadingButton>
      <Box px={2} py={0} ref={componentRef} minWidth={950}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="start"
          borderBottom="2.5px solid"
          py={2}>
          <Stack>
            <Typography>
              From Date: {formatDate(new Date(), 'DD-MMMM-YYYY')}
            </Typography>
          </Stack>
          <Typography variant="h3" fontWeight={600} fontSize={16}>
            Non Expense Report
          </Typography>

          <Typography variant="h4" fontWeight={600} fontSize={16}>
            FOTAN Central School
          </Typography>
        </Stack>
        <TableContainer sx={{ boxShadow: 0 }}>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                <StyledTableCell>#</StyledTableCell>
                <StyledTableCell>Details</StyledTableCell>
                <StyledTableCell>Approved Status</StyledTableCell>
                <StyledTableCell>Paid By</StyledTableCell>
                <StyledTableCell>Paid To</StyledTableCell>
                <StyledTableCell>Payment Mode</StyledTableCell>
                <StyledTableCell>Paid Amount</StyledTableCell>
              </TableRow>
            </TableHead>

            {nonExpenses.map((item, index) => (
              <TableBody key={item.id}>
                <StyledTableCell>{index + 1}</StyledTableCell>
                <StyledTableCellLeftAlign>
                  {item.description}
                </StyledTableCellLeftAlign>
                <StyledTableCellLeftAlign>
                  {item?.is_approved ? 'Approved' : 'Not Approved'}
                </StyledTableCellLeftAlign>
                <StyledTableCell>{item?.paid_by?.name}</StyledTableCell>
                <StyledTableCell>{item?.paid_to}</StyledTableCell>
                <StyledTableCell>{item?.payment_mode}</StyledTableCell>
                <StyledTableCell>{item?.paid_amount}</StyledTableCell>
              </TableBody>
            ))}
            <StyledTableCell
              colSpan={7}
              sx={{ textAlign: 'right', border: 'none', fontWeight: 600 }}>
              Total: {calculateTotal()}
            </StyledTableCell>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};
export default NonExpenseReport;
