import React, { useCallback, useRef, useState } from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Stack,
  Avatar,
} from '@mui/material';
import { formatDate } from '@jumbo/utils';
import { getFullName } from 'app/utils/appHelpers';
import { PUBLIC_ASSETS_URL } from 'app/utils/constants/api/constants';
import { StyledTableCell } from '../components/styled';
import LabelWithBoldContent from '../components/LabelWithBoldContent';
import { LoadingButton } from '@mui/lab';
import { useReactToPrint } from 'react-to-print';

const FeeReceipt = ({ fees }) => {
  const [loading, setLoading] = useState();
  const componentRef = useRef(null);

  const handleBeforePrint = useCallback(() => {
    setLoading(true);
  }, []);

  const handleAfterPrint = useCallback(() => {
    setLoading(false);
  }, []);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'OK',
    removeAfterPrint: true,
    onBeforePrint: handleBeforePrint,
    onAfterPrint: handleAfterPrint,
  });

  return (
    <>
      <LoadingButton
        variant="contained"
        size="small"
        loading={loading}
        onClick={handlePrint}
        sx={{ position: 'absolute', top: 15 }}>
        Print
      </LoadingButton>
      <Box px={2} py={0} ref={componentRef}>
        {fees.map(fee => (
          <div
            key={fee.id}
            style={{ pageBreakAfter: 'always', marginTop: 140 }}>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="start"
              gap={5}
              sx={{ mt: 3 }}>
              <Avatar
                alt={fee.student?.first_name}
                src={`${PUBLIC_ASSETS_URL}/${fee.student?.image}`}
                variant="square"
                sx={{
                  height: 100,
                  width: 100,
                  mt: 1.2,
                }}
              />
              <Box>
                <LabelWithBoldContent
                  label={'Student ID: '}
                  content={fee.student?.registration_no}
                />

                <LabelWithBoldContent
                  label={"Student's Name: "}
                  content={getFullName({
                    first_name: fee.student.first_name,
                    last_name: fee.student.last_name,
                  })}
                />

                <LabelWithBoldContent
                  label={"Father's Name: "}
                  content={fee.student?.father_name}
                />
                <LabelWithBoldContent
                  label={'Class: '}
                  content={fee.student?.class_section?.class}
                />
              </Box>
            </Stack>

            <TableContainer sx={{ boxShadow: 0, mt: 3 }}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                    <StyledTableCell>Sr #</StyledTableCell>
                    <StyledTableCell>Receipt Date </StyledTableCell>
                    <StyledTableCell>Total Payable</StyledTableCell>
                    <StyledTableCell>Paid Amount</StyledTableCell>
                    <StyledTableCell>Balance</StyledTableCell>
                    <StyledTableCell>Received By</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fee?.fee_updates?.map((history, index) => (
                    <TableRow key={history?.id}>
                      <StyledTableCell>{index + 1}</StyledTableCell>
                      <StyledTableCell>
                        {formatDate(history?.createdAt, 'DD-MMM-YYYY')}
                      </StyledTableCell>
                      <StyledTableCell>{history?.total} Rs.</StyledTableCell>
                      <StyledTableCell>
                        {history?.paid_amount} Rs.
                      </StyledTableCell>
                      <StyledTableCell>{history?.balance} Rs.</StyledTableCell>
                      <StyledTableCell>
                        {history?.received_by?.name}
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <Stack direction={'row'} gap={4} justifyContent={'end'} mt={4}>
              <LabelWithBoldContent
                label={'Received By : '}
                content={fee?.received_by?.name}
              />
              <LabelWithBoldContent
                label={formatDate(fee?.createdAt)}
                content={''}
              />
            </Stack> */}
          </div>
        ))}
      </Box>
    </>
  );
};

export default FeeReceipt;
