import { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Card } from '@mui/material';

import JumboRqList from '@jumbo/components/JumboReactQuery/JumboRqList';
import JumboListToolbar from '@jumbo/components/JumboList/components/JumboListToolbar';
import JumboSearch from '@jumbo/components/JumboSearch';
import Item from './Item';

const List = () => {
  const listRef = useRef();
  const data = useSelector(state => state.configuration.holidays);

  const [dataToShow, setDataToShow] = useState(data);

  useEffect(() => {
    setDataToShow(data);
  }, [data]);

  const renderItem = useCallback(item => {
    return <Item item={item} />;
  }, []);

  const handleOnChange = useCallback(
    keyword => {
      let filteredData = [];
      filteredData = data?.filter(singleItem => {
        return (
          singleItem?.title?.toLowerCase().search(keyword?.toLowerCase()) !== -1
        );
      });
      setDataToShow(filteredData);
    },
    [data],
  );

  return (
    <JumboRqList
      ref={listRef}
      data={dataToShow}
      wrapperComponent={Card}
      primaryKey={'id'}
      itemsPerPage={10}
      itemsPerPageOptions={[8, 15, 20]}
      renderItem={renderItem}
      componentElement={'div'}
      isClasses
      wrapperSx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
      toolbar={
        <JumboListToolbar hideItemsPerPage={true}>
          <JumboSearch
            onChange={handleOnChange}
            sx={{
              display: { xs: 'none', md: 'block' },
            }}
          />
        </JumboListToolbar>
      }
    />
  );
};

export default List;
