import * as yup from 'yup';

export const schema = yup.object().shape({
  name: yup.string().max(50).required().label('Name'),
  user_village_code: yup
    .number()
    .positive()
    .integer()
    .nullable()
    .label('User Village Code'),
  status: yup.boolean().required().label('Status'),
});
