import React from 'react';
import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';

import { LocationOn, LocalPhoneOutlined } from '@mui/icons-material';

const Contacts = ({ student }) => {
  return (
    <JumboCardQuick title={'Contact'} noWrapper>
      <List disablePadding sx={{ mb: 2 }}>
        <ListItem
          alignItems="flex-start"
          sx={{ p: theme => theme.spacing(0.5, 3) }}>
          <ListItemIcon sx={{ minWidth: 36, color: 'text.secondary' }}>
            <LocalPhoneOutlined />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1" color="text.secondary">
                Father Phone
              </Typography>
            }
            secondary={<>{student?.father_mobile_number}</>}
          />
        </ListItem>
        <ListItem
          alignItems="flex-start"
          sx={{ p: theme => theme.spacing(0.5, 3) }}>
          <ListItemIcon sx={{ minWidth: 36, color: 'text.secondary' }}>
            <LocalPhoneOutlined />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1" color="text.secondary">
                Mother Phone
              </Typography>
            }
            secondary={<>{student?.mother_mobile_number}</>}
          />
        </ListItem>
        <ListItem
          alignItems="flex-start"
          sx={{ p: theme => theme.spacing(0.5, 3) }}>
          <ListItemIcon sx={{ minWidth: 36, color: 'text.secondary' }}>
            <LocationOn />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1" color="text.secondary">
                Address, Village, Tehsil, District:
              </Typography>
            }
            secondary={
              <>
                {student?.address}, {student?.village?.name} , {student?.tehsil}
                , {student?.district}
              </>
            }
          />
        </ListItem>
      </List>
    </JumboCardQuick>
  );
};

export default Contacts;
