import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import ClassSectionSubjectSidebar from './components/ClassSectionSubjectSidebar';
import ClassSectionSubjects from './components/ClassSectionSubjectList';
import { getAllClassSectionSubjects } from 'app/store/features/subjectsSlice';

const ClassSectionSubject = () => {
  const dispatch = useDispatch();
  const isStoreSubjectsFetched = useSelector(
    state => state.subjects.isClassSectionSubjectsFetched,
  );

  const fetchData = useCallback(async () => {
    if (!isStoreSubjectsFetched) {
      dispatch(getAllClassSectionSubjects());
    }
  }, [dispatch, isStoreSubjectsFetched]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <JumboContentLayout sidebar={<ClassSectionSubjectSidebar />}>
        <ClassSectionSubjects />
      </JumboContentLayout>
    </>
  );
};
export default ClassSectionSubject;
