import moment from 'moment';
import { config } from '../config/main';
import { PUBLIC_ASSETS_URL } from './constants/api/constants';
import { PERMISSION_TYPES } from './constants/permissions';
import { jwtDecode } from 'jwt-decode';
import { MONTHS } from 'app/shared/constants';

export const getAssetPath = (url, size) => {
  if (config.useImagePlaceHolder) {
    return `https://via.placeholder.com/${size}.png`;
  }

  return url;
};

export const removeEmptyFromObject = obj => {
  let result = {};
  Object.keys(obj).forEach(key => {
    if (obj[key] !== '') {
      result = { ...result, obj: obj[key] };
    }
  });
};

export const titlalize = word =>
  word ? word[0].toUpperCase() + word.slice(1) : word;

export const getCurrentFormattedDate = () => {
  let month = moment().month().toString();
  let date = moment().date().toString();

  if (month.length === 1) {
    month = `0${Number(month) + 1}`;
  }
  if (date.length === 1) {
    date = `0${date}`;
  }

  return `${moment().year()}-${month}-${date}`;
};

export const formatRoleName = role => {
  return role
    .split('-')
    .map(word => word[0].toUpperCase() + word.slice(1))
    .join(' ');
};

export const getSchoolCode = (id, villages) => {
  let village = villages.find(village => village.id === id);

  village = village.name;

  switch (village.toLowerCase()) {
    case 'Bheega-A'.toLowerCase():
      // return 'BHGA';
      return 1;
    case 'Bheega-B'.toLowerCase():
      // return 'BHGB';
      return 2;
    case 'Ghaiyyewala'.toLowerCase():
      // return 'GYW';
      return 3;
    case 'Dera Jammu'.toLowerCase():
      // return 'DRJ';
      return 4;
    case 'Ladhewala'.toLowerCase():
      // return 'LDW';
      return 5;
    case 'Kalaske'.toLowerCase():
      // return 'KLS';
      return 6;
    case 'Mohlinke'.toLowerCase():
      // return 'MLK';
      return 7;

    case 'Mallan Wali'.toLowerCase():
      // return 'MWL';
      return 8;
    case 'Hassan Wali'.toLowerCase():
      // return 'HSW';
      return 9;
    case 'Kot Qadir Bakhsh'.toLowerCase():
      // return 'KQB';
      return 10;
    case 'Kot Jahangeer'.toLowerCase():
      // return 'KJH';
      return 11;
    case 'Bhoma Bath'.toLowerCase():
      // return 'BBT';
      return 12;
    default:
      // return 'GWR';
      return 13;
  }
};

export const formatPhoneNumber = value => {
  const numbersOnly = value.replace(/\D/g, '');
  let countryCode = numbersOnly.slice(0, 2);
  let areaCode = numbersOnly.slice(2, 5);
  let remaining = numbersOnly.slice(5, 12);

  countryCode = countryCode.replace(/^0+/, '');
  areaCode = areaCode.replace(/^0+/, '');

  let formattedValue = '';
  if (countryCode) {
    formattedValue += `+${countryCode}`;
  }
  if (areaCode) {
    formattedValue += `-${areaCode}`;
  }
  if (remaining) {
    formattedValue += ` ${remaining}`;
  }

  return formattedValue;
};

export const formatResourcePermissions = permissions => {
  const permissionMap = {};

  permissions.forEach(permission => {
    const resourceId = permission.resource_id;
    if (!permissionMap[resourceId]) {
      permissionMap[resourceId] = {
        resource_id: resourceId,
        permissions: [],
      };
    }
    permissionMap[resourceId].permissions.push({
      permission_id: permission.permission_id,
      allowed: permission.allowed,
    });
  });

  const groupedPermissions = Object.values(permissionMap);
  return groupedPermissions;
};

export function removeEmptyKeys(obj) {
  const newObj = {};

  for (const key in obj) {
    if (obj[key] !== '' && obj[key] !== null && obj[key] !== undefined) {
      newObj[key] = obj[key];
    }
  }

  return newObj;
}

export const getPublicAsset = url => {
  const filePath = url?.split('/')[url?.split('/')?.length - 1];

  return `${PUBLIC_ASSETS_URL}/${filePath}`;
};

export const generateYears = () => {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = 2000; i <= currentYear; i++) {
    years.push({ label: `${i}`, value: i });
  }
  return years.reverse();
};

export const getFullName = data => {
  if (!data) return 'N/A';

  let formattedName = data?.first_name;

  if (data?.last_name) {
    formattedName += ` ${data?.last_name} `;
  }

  if (data?.father_name) {
    formattedName += ` S/O ${data?.father_name} `;
  }
  return formattedName;
};

export const getMonthName = monthNumber => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  return months[monthNumber - 1];
};

export const getDecodedToken = () => {
  const token = localStorage.getItem('fotanistanAccessToken');
  if (!token) return {};
  const user = jwtDecode(token);
  return user?.data;
};

export const user = getDecodedToken();
const userPermissions = user?.permissions;

export const hasPermission = (requiredPermissions, permissionTypes) => {
  if (!Object.keys(user).length) return false;

  if (user?.user_type === 'admin') return true;

  for (const permission of requiredPermissions) {
    for (const type of permissionTypes) {
      if (userPermissions[permission] && userPermissions[permission][type]) {
        return true;
      }
    }
  }
  return false;
};

export const hasAnyPermissions = permissions =>
  hasPermission(permissions, [
    PERMISSION_TYPES.ADD,
    PERMISSION_TYPES.EDIT,
    PERMISSION_TYPES.DELETE,
    PERMISSION_TYPES.LISTING,
  ]);

export const isSuperAdmin = () => user?.user_type === 'admin';

export const getMonthsByYear = year => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  if (year === currentYear) MONTHS.filter(month => month.value <= currentMonth);

  return MONTHS;
};

export const capitalizeFirstLetter = (e, setFieldValue, fieldName) => {
  const { value } = e.target;

  // Handle null or undefined values
  if (value === null || value === undefined) {
    return;
  }

  // Convert to string if it's not already
  const stringValue = String(value);

  // Check if the first character is a letter before capitalizing
  if (stringValue.length > 0 && /[a-zA-Z]/.test(stringValue.charAt(0))) {
    const capitalizedValue =
      stringValue.charAt(0).toUpperCase() + stringValue.slice(1);
    e.target.value = capitalizedValue;
    setFieldValue(fieldName, capitalizedValue);
  } else {
    // If the first character is not a letter, just set the value as is
    setFieldValue(fieldName, stringValue);
  }
};
