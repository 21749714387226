export const extractBranches = branches => {
  const array = [];
  for (let i = 0; i < branches.length; i++) {
    const obj = {
      id: i + 1,
      label: branches[i].name,
      value: branches[i].name,
    };
    array.push(obj);
  }
  return array;
};

export const calculateGraph = (branches, branch) => {
  const tempArray = [];
  const { revenueSummary } = branches.filter(
    item => item.name.toLowerCase().trim() === branch.toLowerCase().trim(),
  )[0];
  for (let i = 0; i < revenueSummary.length; i++) {
    const obj = {};

    obj.name = revenueSummary[i].month;
    obj.Earning = revenueSummary[i].earning;
    obj.Expenses = revenueSummary[i].expense;

    tempArray.push(obj);
  }

  return tempArray;
};

export const calculateMonthlyAverage = (branches, month) => {
  const tempArray = [];

  for (let i = 0; i < branches.length; i++) {
    let obj = {};
    obj.name = branches[i].name;
    // console.log(branches[i].revenueSummary);
    const filtered = branches[i].revenueSummary.filter(
      item => item.month.toLowerCase().trim() === month.toLowerCase().trim(),
    )[0];
    obj.Earning = filtered.earning;
    obj.Expenses = filtered.expense;

    tempArray.push(obj);
  }
  return tempArray;
};

export const calculateEachBranchRevenue = branches => {
  const results = [];

  for (let i = 0; i < branches.length; i++) {
    const branch = {};

    branch.name = branches[i].name;
    let sum = 0;
    let expenses = 0;
    for (let j = 0; j < branches[i].revenueSummary.length; j++) {
      sum += branches[i].revenueSummary[j].earning;
      expenses += branches[i].revenueSummary[j].expense;
    }

    branch.amount = sum - expenses;

    results.push(branch);
  }
  return results;
};
