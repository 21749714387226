import { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useParams } from 'react-router-dom';
import { Card } from '@mui/material';

import JumboRqList from '@jumbo/components/JumboReactQuery/JumboRqList';
import JumboListToolbar from '@jumbo/components/JumboList/components/JumboListToolbar';
import JumboSearch from '@jumbo/components/JumboSearch';

import StudentItem from './StudentItem';
import { getAllStudents } from 'app/store/features/studentsSlice';

const StudentsList = () => {
  const { category } = useParams();
  const dispatch = useDispatch();

  const listRef = useRef();
  const students = useSelector(state => state.students.students);
  const isStudentsFetched = useSelector(state => state.students.isDataFetched);

  const [studentsToShow, setStudentsToShow] = useState(students);

  const fetchData = useCallback(async () => {
    if (!isStudentsFetched) dispatch(getAllStudents());
  }, [isStudentsFetched, dispatch]);

  useEffect(() => {
    fetchData();
    setStudentsToShow(students);
  }, [students, fetchData]);

  useEffect(() => {
    if (category === 'all') {
      setStudentsToShow(students);
    } else {
      const filteredStudents = students?.filter(
        student => category === student?.branch?.name.toLowerCase(),
      );

      setStudentsToShow(filteredStudents);
    }
  }, [category, students]);

  const renderClass = useCallback(student => {
    return <StudentItem contact={student} view={'list'} />;
  }, []);

  const handleOnChange = useCallback(
    keyword => {
      // const filteredClasses = students?.filter(
      //   ({ name }) => keyword?.toLowerCase().indexOf(name.toLowerCase()) !== 1,
      // );
      // console.log(filteredClasses);
      // setClassesToShow(filteredClasses);
      // setQueryOptions(state => ({
      //   ...state,
      //   queryParams: {
      //     ...state.queryParams,
      //     keywords: keyword,
      //   },
      // }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [students],
  );

  return (
    <JumboRqList
      ref={listRef}
      data={studentsToShow}
      wrapperComponent={Card}
      primaryKey={'id'}
      itemsPerPage={8}
      itemsPerPageOptions={[8, 15, 20]}
      renderItem={renderClass}
      componentElement={'div'}
      isClasses
      // sx={view === 'grid' && { p: theme => theme.spacing(1, 3, 3) }}
      wrapperSx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
      toolbar={
        <JumboListToolbar hideItemsPerPage={true}>
          <JumboSearch
            onChange={handleOnChange}
            sx={{
              display: { xs: 'none', md: 'block' },
            }}
          />
        </JumboListToolbar>
      }
      view={'list'}
    />
  );
};

export default StudentsList;
