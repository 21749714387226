import { useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Typography, LinearProgress, Grid } from '@mui/material';

import Div from '@jumbo/shared/Div';
import DropDown from 'app/shared/components/DropDown';
import { ASSET_IMAGES } from 'app/utils/constants/paths';
import { getAssetPath } from 'app/utils/appHelpers';
import JumboCardQuick from '@jumbo/components/JumboCardQuick';

import {
  calculateHighestAverage,
  calculateLowestAverage,
} from '../../utils/index';
import { months } from '../../utils/months';
const years = [
  {
    id: 1,
    label: 2022,
    value: 2022,
  },
];

const MonthlyAverage = () => {
  const { branches } = useSelector(state => state.accounts.accounts);

  const [month, setMonth] = useState('Jan');
  const [year, setYear] = useState(2022);
  const graphResults = useMemo(
    () => calculateHighestAverage(branches, month),
    [branches, month],
  );
  const lowest = useMemo(
    () => calculateLowestAverage(branches, month),
    [branches, month],
  );

  const handleMonthChange = useCallback(e => {
    setMonth(e.target.value);
  }, []);

  return (
    <div height={152} sx={{ height: '100%' }}>
      <Div
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginTop: '5px',
          marginBottom: '10px',
          backgroundColor: '#fff',
          padding: '20px',
          borderRadius: '10px',
        }}>
        <Div sx={{ width: '80%' }}>
          <DropDown
            label={'Select Month'}
            options={months}
            value={month}
            onChange={handleMonthChange}
          />
        </Div>

        <Div sx={{ paddingLeft: 1, width: '20%' }}>
          <DropDown
            label={'Select Year'}
            options={years}
            value={year}
            onChange={e => setYear(e.target.value)}
          />
        </Div>
      </Div>

      <Grid container spacing={3.75}>
        <Grid item xs={12} sm={6} md={4}>
          <JumboCardQuick
            title="Highest Performing Branch"
            sx={{
              '& .MuiCardHeader-action': {
                my: '-4px',
              },
            }}>
            <Div
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                textAlign: 'center',
              }}>
              <Div sx={{ display: 'flex', mb: 2 }}>
                <img
                  src={getAssetPath(
                    `${ASSET_IMAGES}/dashboard/icon-german.png`,
                    '68x68',
                  )}
                  alt={''}
                />
              </Div>
              <Typography variant={'h3'} mb={2}>
                {graphResults[0].name}
              </Typography>
              <Div
                sx={{
                  display: 'flex',
                  minWidth: 0,
                  flexDirection: 'column',
                  alignItems: 'center',
                  alignSelf: 'stretch',
                }}>
                <LinearProgress
                  variant={'determinate'}
                  color={'success'}
                  value={85}
                  sx={{
                    width: '85%',
                    borderRadius: 4,
                    height: 5,
                    mb: 1,
                    backgroundColor: '#E9EEEF',
                  }}
                />
                <Typography variant={'body1'} color={'text.secondary'} mb={3}>
                  Earnings: Rs. {graphResults[0].Earning}
                </Typography>
              </Div>
            </Div>
          </JumboCardQuick>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <JumboCardQuick
            title="Lowest Performing Branch"
            sx={{
              '& .MuiCardHeader-action': {
                my: '-4px',
              },
            }}>
            <Div
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                textAlign: 'center',
              }}>
              <Div sx={{ display: 'flex', mb: 2 }}>
                <img
                  src={getAssetPath(
                    `${ASSET_IMAGES}/dashboard/icon-german.png`,
                    '68x68',
                  )}
                  alt={''}
                />
              </Div>
              <Typography variant={'h3'} mb={2}>
                {lowest[0].name}
              </Typography>
              <Div
                sx={{
                  display: 'flex',
                  minWidth: 0,
                  flexDirection: 'column',
                  alignItems: 'center',
                  alignSelf: 'stretch',
                }}>
                <LinearProgress
                  variant={'determinate'}
                  color={'success'}
                  value={25}
                  sx={{
                    width: '85%',
                    borderRadius: 4,
                    height: 5,
                    mb: 1,
                    backgroundColor: '#E9EEEF',
                  }}
                />
                <Typography variant={'body1'} color={'text.secondary'} mb={3}>
                  Earnings: Rs. {lowest[0].Earning}
                </Typography>
              </Div>
            </Div>
          </JumboCardQuick>
        </Grid>
      </Grid>
    </div>
  );
};

export default MonthlyAverage;
