import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, Typography } from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import { getAllOccupations } from 'app/store/features/configurationSlice';

import List from './components/List';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import FormComponent from './components/Form';
import Loader from 'app/shared/components/Loader';
import { hasPermission } from 'app/utils/appHelpers';
import { PERMISSIONS, PERMISSION_TYPES } from 'app/utils/constants/permissions';

const Occupations = () => {
  const dispatch = useDispatch();
  const { isOccupationsFetched } = useSelector(state => state.configuration);

  const { showDialog } = useJumboDialog();

  const showAddButton = useCallback(() => {
    showDialog({
      title: 'Add New Occupation',
      content: <FormComponent />,
    });
  }, [showDialog]);

  useEffect(() => {
    if (!isOccupationsFetched) dispatch(getAllOccupations());
  }, [dispatch, isOccupationsFetched]);

  if (!isOccupationsFetched) {
    return <Loader />;
  }

  return (
    <>
      <JumboContentLayout>
        <Box width="100%">
          {hasPermission([PERMISSIONS.OCCUPATION], [PERMISSION_TYPES.ADD]) && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
              <Typography variant={'h2'} mb={0.35}>
                Occupations
              </Typography>

              <Button
                disableElevation
                variant={'contained'}
                startIcon={<PersonAddIcon />}
                sx={{
                  mb: 2,
                  '& .MuiSvgIcon-root': {
                    fontSize: '1.5rem',
                  },
                }}
                onClick={showAddButton}>
                Add New Occupation
              </Button>
            </Box>
          )}
          {hasPermission(
            [PERMISSIONS.OCCUPATION],
            [PERMISSION_TYPES.LISTING],
          ) && <List />}
        </Box>
      </JumboContentLayout>
    </>
  );
};
export default Occupations;
