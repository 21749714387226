import { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import DropDown from 'app/shared/components/DropDown';

import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import FeeStats from './components/FeeStats';
import BranchWiseFee from './components/BranchWiseFee';
import Div from '@jumbo/shared/Div';

import { months } from '../../utils/data';
import {
  calculateForMonth,
  calculateBranchForMonth,
} from './utils/calculations';

const years = [
  {
    id: 1,
    label: 2022,
    value: 2022,
  },
];

const FeesAnalytics = () => {
  const students = useSelector(state => state.fees.students);
  const [month, setMonth] = useState('Jan');
  const [year, setYear] = useState(2022);
  const value = useMemo(
    () => calculateForMonth(students, month),
    [students, month],
  );
  const branchResult = useMemo(
    () => calculateBranchForMonth(students, month),
    [students, month],
  );
  const [graphData, setGraphData] = useState(branchResult);
  const [dataToShow, setDataToShow] = useState(value);

  const handleMonthChange = useCallback(e => {
    setMonth(e.target.value);
  }, []);

  useEffect(() => {
    setDataToShow(calculateForMonth(students, month));
    setGraphData(calculateBranchForMonth(students, month));
  }, [month, students]);

  return (
    <JumboCardQuick>
      <Div sx={{ display: 'flex', alignItems: 'center' }}>
        <Div sx={{ width: '80%' }}>
          <DropDown
            label={'Select Month'}
            options={months}
            value={month}
            onChange={handleMonthChange}
          />
        </Div>

        <Div sx={{ paddingLeft: 1, width: '20%' }}>
          <DropDown
            label={'Select Year'}
            options={years}
            value={year}
            onChange={e => setYear(e.target.value)}
          />
        </Div>
      </Div>
      <Grid container spacing={3.75}>
        <Grid item xs={12} sm={5}>
          <Typography>Fee Stats Overview</Typography>
          <FeeStats dataToShow={dataToShow} />
        </Grid>
        <Grid item xs={12} sm={7}>
          <Typography>Branch Fee Collection Overview</Typography>
          <BranchWiseFee data={graphData} />
        </Grid>
      </Grid>
    </JumboCardQuick>
  );
};

export default FeesAnalytics;
