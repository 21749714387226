import { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';

import { Card } from '@mui/material';

import JumboRqList from '@jumbo/components/JumboReactQuery/JumboRqList';
import JumboListToolbar from '@jumbo/components/JumboList/components/JumboListToolbar';
import JumboSearch from '@jumbo/components/JumboSearch';

import ExpenseItem from './ExpenseItem';

const NonExpensePaymentsList = () => {
  const listRef = useRef();

  const nonExpensePayments = useSelector(
    state => state.nonExpensePayments.nonExpensePayments,
  );
  const [dataToShow, setDataToShow] = useState(nonExpensePayments);

  useEffect(() => {
    setDataToShow(nonExpensePayments);
  }, [nonExpensePayments]);

  const renderItem = useCallback(item => {
    return <ExpenseItem item={item} view={'list'} />;
  }, []);

  const handleOnChange = useCallback(
    keyword => {
      const filtered = nonExpensePayments?.filter(item =>
        item?.paid_to.toLowerCase().includes(keyword?.toLowerCase()),
      );
      setDataToShow(filtered);
    },
    [nonExpensePayments],
  );

  return (
    <JumboRqList
      ref={listRef}
      data={dataToShow}
      wrapperComponent={Card}
      primaryKey={'id'}
      itemsPerPage={8}
      itemsPerPageOptions={[8, 15, 20]}
      renderItem={renderItem}
      componentElement={'div'}
      // sx={view === 'grid' && { p: theme => theme.spacing(1, 3, 3) }}
      wrapperSx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
      toolbar={
        <JumboListToolbar hideItemsPerPage={true}>
          <JumboSearch
            onChange={handleOnChange}
            sx={{
              display: { xs: 'none', md: 'block' },
            }}
          />
        </JumboListToolbar>
      }
      view={'list'}
    />
  );
};

export default NonExpensePaymentsList;
