import { useDispatch, useSelector } from 'react-redux';

import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import { Form, Formik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import Div from '@jumbo/shared/Div';

import { assignItemSchema } from '../../utils/schema';
import { getAllItems } from 'app/store/features/itemSlice';
import { useCallback, useEffect } from 'react';
import JumboAutoComplete from '@jumbo/components/JumboFormik/JumboAutoComplete';
import { getAllStudents } from 'app/store/features/studentsSlice';
import { assignItem } from 'app/store/features/assignedItemsSlice';
import { Box, TextField } from '@mui/material';

const AssignItemForm = () => {
  const dispatch = useDispatch();
  const { hideDialog } = useJumboDialog();

  const user = useSelector(state => state.auth.user);

  const isItemsFetched = useSelector(state => state.item.isDataFetched);
  const items = useSelector(state => state.item.items);

  const isStudentsFetched = useSelector(state => state.students.isDataFetched);
  const students = useSelector(state => state.students.students);

  const fetchData = useCallback(() => {
    if (!isItemsFetched) dispatch(getAllItems());
    if (!isStudentsFetched) dispatch(getAllStudents());
  }, [dispatch, isItemsFetched, isStudentsFetched]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const initialValues = {
    student_id: [],
    item: null,
    quantity: 0,
    receivable_amount: 0,
    assigned_by_id: user.id,
    assigned_date: new Date(),
  };

  const onItemSave = (data, { setSubmitting }) => {
    const payload = {
      student_id: data?.student_id,
      item_id: data?.item?.id,
      assigned_by_id: data?.assigned_by_id,
      quantity: data?.quantity,
      receivable_amount: data?.quantity * data?.item?.price,
      assigned_date: data?.assigned_date,
    };
    setSubmitting(true);
    dispatch(assignItem(payload));
    hideDialog();
    setSubmitting(false);
  };
  return (
    <Formik
      validateOnChange={true}
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={assignItemSchema}
      onSubmit={onItemSave}>
      {({ isSubmitting, values }) => (
        <Form noValidate autoComplete="off">
          <Div
            sx={{
              '& .MuiTextField-root': {
                mb: 3,
              },
            }}>
            <JumboAutoComplete
              fullWidth
              size="small"
              variant="outlined"
              name="student_id"
              label="Student"
              options={students}
              isForStudents
              isMulti
            />
            <JumboAutoComplete
              fullWidth
              size="small"
              variant="outlined"
              name="item"
              label="Item"
              options={items}
              pickKeys={['id', 'price']}
            />

            <JumboTextField
              fullWidth
              size="small"
              variant="outlined"
              name="quantity"
              label="Item Quantity"
              type="number"
            />
            <TextField
              fullWidth
              type="number"
              size="small"
              variant="outlined"
              name="unit_price"
              label="Unit Price"
              value={values?.item?.price || 0}
              disabled
            />
            <TextField
              fullWidth
              type="number"
              size="small"
              variant="outlined"
              name="receivable_amount"
              label="Receivable Amount"
              value={values.quantity * values?.item?.price}
              disabled
            />

            <JumboTextField
              fullWidth
              type="date"
              size="small"
              variant="outlined"
              name="assigned_date"
              label="Assigned Date"
              InputLabelProps={{ shrink: true }}
            />
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'flex-end'}>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}>
                {'Save'}
              </LoadingButton>
            </Box>
          </Div>
        </Form>
      )}
    </Formik>
  );
};
AssignItemForm.defaultProps = {
  onSave: () => {},
};
export default AssignItemForm;
