import { useMemo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import StudentsSidebar from './components/StudentsSidebar';
import StudentsList from './components/StudentsList';

import { calculateMales } from './utils/calculations';
import { hasPermission } from 'app/utils/appHelpers';
import { PERMISSIONS, PERMISSION_TYPES } from 'app/utils/constants/permissions';

const Students = () => {
  const { category } = useParams();
  const students = useSelector(state => state.students.students);
  const [filteredData, setFilteredData] = useState(students);

  // eslint-disable-next-line no-unused-vars
  const maleStudents = useMemo(
    () => calculateMales(filteredData),
    [filteredData],
  );

  useEffect(() => {
    if (category === 'all') {
      setFilteredData(students);
    } else {
      const filteredStudents = students?.filter(
        single => category === single.branch?.name.toLowerCase(),
      );
      setFilteredData(filteredStudents);
    }
  }, [category, students]);

  return (
    <JumboContentLayout
      sidebar={
        hasPermission([PERMISSIONS.STUDENT], [PERMISSION_TYPES.ADD]) && (
          <StudentsSidebar />
        )
      }>
      {hasPermission([PERMISSIONS.STUDENT], [PERMISSION_TYPES.LISTING]) && (
        <StudentsList />
      )}
    </JumboContentLayout>
  );
};
export default Students;
