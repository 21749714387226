import React from 'react';
import { useDispatch } from 'react-redux';

import {
  ListItemText,
  ListItemIcon,
  ListItemAvatar,
  Tooltip,
  Typography,
  Avatar,
  Stack,
} from '@mui/material';
import { MoreHoriz, Edit, Delete } from '@mui/icons-material';
import styled from '@emotion/styled';

import JumboDdMenu from '@jumbo/components/JumboDdMenu';
import Span from '@jumbo/shared/Span';
import JumboListItem from '@jumbo/components/JumboList/components/JumboListItem';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';

import ClassForm from '../ItemForm';
import ClassesDetail from '../ItemDetail';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { deleteItem } from 'app/store/features/itemSlice';
import { hasPermission } from 'app/utils/appHelpers';
import { PERMISSIONS, PERMISSION_TYPES } from 'app/utils/constants/permissions';

const Item = styled(Span)(({ theme }) => ({
  minWidth: 0,
  flexGrow: 0,
  padding: theme.spacing(0, 1),
}));

const InventoryItem = ({ item, view }) => {
  const { showDialog, hideDialog } = useJumboDialog();

  const dispatch = useDispatch();
  const Swal = useSwalWrapper();

  const showContactDetail = React.useCallback(() => {
    showDialog({
      content: <ClassesDetail item={item} onClose={hideDialog} />,
    });
  }, [showDialog, item, hideDialog]);

  const handleDeleteItem = data => {
    dispatch(deleteItem(data.id));
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Item has been deleted successfully.',
      showConfirmButton: false,
      timer: 1500,
    });
    hideDialog();
  };

  const handleItemAction = menuItem => {
    switch (menuItem.action) {
      case 'edit':
        showDialog({
          title: 'Update Item',
          content: <ClassForm item={item} />,
        });
        break;
      case 'delete':
        showDialog({
          variant: 'confirm',
          title: 'Are you sure about deleting this item?',
          content: "You won't be able to recover this item later",
          onYes: () => handleDeleteItem(item),
          onNo: hideDialog,
        });
        break;
      default:
        break;
    }
  };

  return (
    <JumboListItem
      componentElement={'div'}
      itemData={item}
      secondaryAction={
        hasPermission(
          [PERMISSIONS.ITEM],
          [PERMISSION_TYPES.EDIT, PERMISSION_TYPES.DELETE],
        ) && (
          <>
            <JumboDdMenu
              icon={<MoreHoriz />}
              menuItems={[
                hasPermission([PERMISSIONS.ITEM], [PERMISSION_TYPES.EDIT]) && {
                  icon: <Edit />,
                  title: 'Edit',
                  action: 'edit',
                },
                hasPermission(
                  [PERMISSIONS.ITEM],
                  [PERMISSION_TYPES.DELETE],
                ) && {
                  icon: <Delete />,
                  title: 'Delete',
                  action: 'delete',
                },
              ]}
              onClickCallback={handleItemAction}
            />
          </>
        )
      }
      sx={{
        cursor: 'pointer',
        borderTop: 1,
        borderColor: 'divider',
        '&:hover': {
          bgcolor: 'action.hover',
        },
      }}>
      <ListItemIcon sx={{ minWidth: 40 }}></ListItemIcon>
      <ListItemAvatar onClick={showContactDetail}>
        <Avatar alt={item?.name} src={item?.profile_pic} />
      </ListItemAvatar>
      <ListItemText
        onClick={showContactDetail}
        primary={
          <Typography variant={'body1'} component={'div'}>
            <Stack direction={'row'} alignItems={'center'} sx={{ minWidth: 0 }}>
              <Item
                sx={{
                  flexBasis: { xs: '100%', sm: '50%', md: '25%' },
                }}>
                <Tooltip title={'Item Name'}>
                  <Typography
                    variant={'h5'}
                    fontSize={14}
                    lineHeight={1.25}
                    mb={0}
                    noWrap>
                    {item.name}
                  </Typography>
                </Tooltip>
              </Item>

              <Item
                sx={{
                  flexBasis: { sm: '50%', md: '28%' },
                  display: { xs: 'none', sm: 'block' },
                }}>
                <Tooltip title={'Group'}>
                  <Typography variant={'body1'} noWrap>
                    {item?.group}
                  </Typography>
                </Tooltip>
              </Item>
              <Item
                sx={{
                  flexBasis: { sm: '50%', md: '28%' },
                  display: { xs: 'none', sm: 'block' },
                }}>
                <Tooltip title={'Price of Item'}>
                  <Typography variant={'body1'} noWrap>
                    {item.price}
                  </Typography>
                </Tooltip>
              </Item>

              <Item
                sx={{
                  flexBasis: { sm: '50%', md: '28%' },
                  display: { xs: 'none', sm: 'block' },
                }}>
                <Tooltip title={'Current Stock of Item'}>
                  <Typography variant={'body1'} noWrap>
                    {item.current_stock}
                  </Typography>
                </Tooltip>
              </Item>
              <Item
                sx={{
                  flexBasis: { md: '25%' },
                  display: { xs: 'none', md: 'block' },
                }}>
                <Tooltip title={'Minimum Threshhold'}>
                  <Typography variant={'body1'} noWrap>
                    {item.min_threshhold}
                  </Typography>
                </Tooltip>
              </Item>
              <Item
                sx={{
                  flexBasis: { md: '22%' },
                  display: { xs: 'none', md: 'block' },
                }}>
                <Tooltip title={'User Item Code'}>
                  <Typography variant={'body1'} noWrap>
                    {item.user_item_code}
                  </Typography>
                </Tooltip>
              </Item>
            </Stack>
          </Typography>
        }
      />
    </JumboListItem>
  );
};

export default InventoryItem;
