import React from 'react';
import { Card, Grid, Typography } from '@mui/material';
import LineChartSales from './LineChartSales';
import ChartOrderRevenue from './ChartOrderRevenue';
import renderSalesData from './renderSalesData';
import JumboContent from '@jumbo/components/JumboContent';
import Div from '@jumbo/shared/Div';
import { useTheme } from '@mui/material/styles';

export const dataItems = [
  {
    name: 'Average Fee Collection',
    amount: 4000,
    fill: '#7352C7',
  },
  {
    name: 'Total Fee',
    amount: 3200,
    fill: '#2EB5C9',
  },
  {
    name: 'Average Expenses',
    amount: 2800,
    fill: '#3BD2A2',
  },
  {
    name: 'Average Earnings',
    amount: 3000,
    fill: '#F39711',
  },
];

export const data = [
  {
    name: 'Page A',
    month: 'Jan',
    sale: 1200,
  },
  {
    name: 'Page B',
    month: 'Feb',
    sale: 1600,
  },
  {
    name: 'Page C',
    month: 'Mar',
    sale: 3000,
  },
  {
    name: 'Page D',
    month: 'Apr',
    sale: 3600,
  },
  {
    name: 'Page E',
    month: 'May',
    sale: 4800,
  },
  {
    name: 'Page F',
    month: 'Jun',
    sale: 4200,
  },
  {
    name: 'Page G',
    month: 'Jul',
    sale: 4600,
  },
  {
    name: 'Page C',
    month: 'Aug',
    sale: 3800,
  },
  {
    name: 'Page D',
    month: 'Sep',
    sale: 5200,
  },
  {
    name: 'Page E',
    month: 'Oct',
    sale: 5800,
  },
  {
    name: 'Page F',
    month: 'Nov',
    sale: 6800,
  },
  {
    name: 'Page G',
    month: 'Dec',
    sale: 7200,
  },
];

const BranchesStatistics = () => {
  const theme = useTheme();
  return (
    <Card>
      <JumboContent
        title={
          <Typography variant={'h5'} color={'common.white'}>
            Accounts Summary: Per Branch
          </Typography>
        }
        bgColor={theme => theme.palette.primary.main}
        sx={{ color: 'common.white' }}>
        {renderSalesData()}
      </JumboContent>
      <JumboContent>
        <Grid container>
          <Grid item xs={12} lg={6}>
            <Div
              sx={{
                p: theme => theme.spacing(3, 2, 3, 0),
              }}>
              <LineChartSales data={data} />
            </Div>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Div
              sx={{
                p: theme => theme.spacing(3, 0, 3, 2),
                [theme.breakpoints.up('lg')]: {
                  borderLeft: theme => `solid 2px ${theme.palette.divider}`,
                },
                [theme.breakpoints.down('lg')]: {
                  borderTop: theme => `solid 2px ${theme.palette.divider}`,
                },
              }}>
              <ChartOrderRevenue data={dataItems} />
            </Div>
          </Grid>
        </Grid>
      </JumboContent>
    </Card>
  );
};

export default BranchesStatistics;
