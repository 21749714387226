import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { Grid } from '@mui/material';

import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import Div from '@jumbo/shared/Div';
import Button from '@jumbo/components/JumboButton';

import { schema } from '../../utils/schema';
import { createGroup, editGroup } from 'app/store/features/configurationSlice';
import JumboDropDown from '@jumbo/components/JumboFormik/JumboDropDown';
import { useEffect } from 'react';
import { getAllBranches } from 'app/store/features/branchSlice';
import { isSuperAdmin } from 'app/utils/appHelpers';

const FormComponent = ({ payload }) => {
  const dispatch = useDispatch();
  const { hideDialog } = useJumboDialog();
  const id = payload?.id;

  const storeBranches = useSelector(state => state.branches.branches);
  const isStoreBranchesFetched = useSelector(
    state => state.branches.isDataFetched,
  );

  const user = useSelector(state => state.auth.user);

  useEffect(() => {
    if (!isStoreBranchesFetched) dispatch(getAllBranches());
  }, [dispatch, isStoreBranchesFetched]);

  const isUpdate = payload ? true : false;
  const initialValues = {
    title: payload ? payload.title : '',
    description: payload ? payload.description : '',
    branch_id: payload
      ? payload?.branch?.id
      : !isSuperAdmin()
        ? user?.branch_id
        : '',
  };

  const addHandler = data => {
    dispatch(createGroup(data));
  };

  const updateHandler = data => {
    const payload = {
      id,
      title: data.title,
      description: data.description,
      branch_id: data.branch_id,
    };
    dispatch(editGroup(payload));
  };

  const onSave = data => {
    isUpdate ? updateHandler(data) : addHandler(data);
    hideDialog();
  };

  return (
    <Formik
      validateOnChange={true}
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={schema}
      onSubmit={onSave}>
      {({ isSubmitting }) => (
        <Form noValidate autoComplete="off">
          <Div
            sx={{
              '& .MuiTextField-root': {
                mb: 3,
              },
            }}>
            <JumboDropDown
              size="small"
              variant="outlined"
              name="branch_id"
              label="Branch Name*"
              options={storeBranches}
              fullWidth
              disabled={!isSuperAdmin()}
            />
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <JumboTextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  name="title"
                  label="Group Title"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <JumboTextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  name="description"
                  label="Group Description"
                />
              </Grid>
            </Grid>

            <Div
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}>
              <Button
                variant="outlined"
                sx={{
                  mr: 2,
                }}
                onClick={() => hideDialog()}>
                Close
              </Button>
              <Button type="submit" variant="contained" loading={isSubmitting}>
                Save
              </Button>
            </Div>
          </Div>
        </Form>
      )}
    </Formik>
  );
};

export default FormComponent;
