import Branches from 'app/pages/apps/branches';
import Classes from 'app/pages/apps/classes';
import Users from 'app/pages/apps/users';
import Inventory from 'app/pages/apps/inventory';
import Attendance from 'app/pages/apps/attendance';
import Fees from 'app/pages/apps/fees';
import Students from 'app/pages/apps/students';
import StudentProfile from 'app/pages/apps/StudentProfile';
import TeacherProfile from 'app/pages/apps/TeacherProfile';
import AddAttendance from 'app/pages/apps/attendance/components/AddAttendance';
import Subjects from 'app/pages/apps/subjects/subject';
import Accounts from 'app/pages/apps/accounts';
import BillForm from 'app/pages/apps/accounts/components/BillForm';
import BillDetails from 'app/pages/apps/accounts/components/BillDetail';
import StudentForm from 'app/pages/apps/students/components/StudentForm';
import ExamInitiatorForm from 'app/pages/apps/examination/components/ExamInitiatorForm';
import ExamFollowUpForm from 'app/pages/apps/examination/components/ExamFollowUpForm';
import ExamInitiator from 'app/pages/apps/examination/ExamInitiator';
import ExamFollowUp from 'app/pages/apps/examination/ExamFollowUp';
import ClassSectionSubject from 'app/pages/apps/subjects/classSectionSubject';
import NonExpensePayments from 'app/pages/apps/nonExpensePayments';
import Roles from 'app/pages/apps/roles';
import RoleForm from 'app/pages/apps/roles/components/RoleForm';
import ClassForm from 'app/pages/apps/classes/components/ClassForm';
import EditProfile from 'app/pages/apps/editProfile';
import StudentsInventory from 'app/pages/apps/inventory/StudentsInventory';
import Reports from 'app/pages/apps/reports/components/ReportList';
import ExpenseReports from 'app/pages/apps/reports/components/ExpenseReports';
import NonExpenseReports from 'app/pages/apps/reports/components/NonExpenseReports';
import FeeItems from 'app/pages/apps/fees/components/FeeItems';

import { hasPermission, hasAnyPermissions } from 'app/utils/appHelpers';
import { PERMISSIONS, PERMISSION_TYPES } from 'app/utils/constants/permissions';
import DefaulterStudents from 'app/pages/apps/reports/components/DefaulterStudents';

const appsRoutes = [
  {
    path: ['/app/accounts/:category', '/app/accounts/:category/:id'],
    element: hasAnyPermissions([
      PERMISSIONS.BILL,
      PERMISSIONS.EXPENSE,
      PERMISSIONS.EXPENSE_CATEGORY,
    ]) ? (
      <Accounts />
    ) : null,
  },
  {
    path: ['/app/accounts/bill'],
    element: hasPermission([PERMISSIONS.BILL], [PERMISSION_TYPES.LISTING]) ? (
      <BillDetails />
    ) : null,
  },
  {
    path: ['app/accounts/add-bill'],
    element: hasPermission(
      [PERMISSIONS.BILL],
      [PERMISSION_TYPES.ADD, PERMISSION_TYPES.EDIT],
    ) ? (
      <BillForm />
    ) : null,
  },
  {
    path: ['/app/branches'],
    element: hasAnyPermissions([PERMISSIONS.BRANCH]) ? <Branches /> : null,
  },
  {
    path: ['/app/classes/:category', '/app/classes/:category/:id'],
    element: hasAnyPermissions([PERMISSIONS.CLASS_SECTION]) ? (
      <Classes />
    ) : null,
  },
  {
    path: ['/app/subjects'],
    element: hasAnyPermissions([PERMISSIONS.CLASS_SECTION_SUBJECTS]) ? (
      <Subjects />
    ) : null,
  },
  {
    path: '/app/class-section-subjects',
    element: hasAnyPermissions(['class_section_subject']) ? (
      <ClassSectionSubject />
    ) : null,
  },
  {
    path: ['/app/students/:category'],
    element: hasAnyPermissions([PERMISSIONS.STUDENT]) ? <Students /> : null,
  },
  {
    path: ['/app/students/new'],
    element: hasPermission([PERMISSIONS.STUDENT], [PERMISSION_TYPES.ADD]) ? (
      <StudentForm />
    ) : null,
  },
  {
    path: ['/app/student/:id'],
    element: hasPermission(
      [PERMISSIONS.STUDENT],
      [PERMISSION_TYPES.LISTING],
    ) ? (
      <StudentProfile />
    ) : null,
  },
  {
    path: ['/app/teacher/:id'],
    element: hasAnyPermissions([PERMISSIONS.USER]) ? <TeacherProfile /> : null,
  },
  // {
  //   path: ['/app/teachers/:category', '/app/teachers/:category/:id'],
  //   element: hasAnyPermissions([PERMISSIONS.USER]) ? <Teachers /> : null,
  // },
  {
    path: ['/app/users/:category', '/app/users/:category/:id'],
    element: hasAnyPermissions([PERMISSIONS.USER]) ? <Users /> : null,
  },
  {
    path: ['/app/inventory'],
    element: hasAnyPermissions([PERMISSIONS.ITEM]) ? <Inventory /> : null,
  },
  {
    path: ['/app/inventory-students'],
    element: hasAnyPermissions([PERMISSIONS.STUDENT_ITEM]) ? (
      <StudentsInventory />
    ) : null,
  },
  {
    path: ['/app/attendance'],
    element: hasPermission(
      [PERMISSIONS.ATTENDANCE],
      [PERMISSION_TYPES.ADD, PERMISSION_TYPES.LISTING],
    ) ? (
      <Attendance />
    ) : null,
  },
  {
    path: ['/app/attendance/add'],
    element: hasPermission([PERMISSIONS.ATTENDANCE], [PERMISSION_TYPES.ADD]) ? (
      <AddAttendance />
    ) : null,
  },
  {
    path: ['/app/fee/items'],
    element: hasAnyPermissions([PERMISSIONS.FEE]) ? <FeeItems /> : null,
  },

  {
    path: ['/app/fees/:category', '/app/fees/:category/:id'],
    element: hasAnyPermissions([PERMISSIONS.FEE]) ? <Fees /> : null,
  },
  {
    path: ['/app/exam-init/:category'],
    element: hasAnyPermissions([PERMISSIONS.EXAM_INITIATOR]) ? (
      <ExamInitiator />
    ) : null,
  },
  {
    path: ['/app/exam-follow/:category'],
    element: hasAnyPermissions([PERMISSIONS.EXAM_TASK_FOLLOWUP]) ? (
      <ExamFollowUp />
    ) : null,
  },
  {
    path: ['/app/examination/initiator'],
    element: hasPermission(
      [PERMISSIONS.EXAM_INITIATOR],
      [PERMISSION_TYPES.ADD],
    ) ? (
      <ExamInitiatorForm />
    ) : null,
  },
  {
    path: '/app/examination/followup',
    element: hasPermission(
      [PERMISSIONS.EXAM_TASK_FOLLOWUP],
      [PERMISSION_TYPES.ADD],
    ) ? (
      <ExamFollowUpForm />
    ) : null,
  },
  {
    path: '/app/nonExpensePayments',
    element: hasAnyPermissions([PERMISSIONS.NON_EXPENSE_PAYMENT]) ? (
      <NonExpensePayments />
    ) : null,
  },
  {
    path: '/app/roles',
    element: hasAnyPermissions([PERMISSIONS.ROLES]) ? <Roles /> : null,
  },
  {
    path: '/app/roles/form',
    element: hasAnyPermissions(
      [PERMISSIONS.ROLES],
      [PERMISSION_TYPES.ADD, PERMISSION_TYPES.EDIT],
    ) ? (
      <RoleForm />
    ) : null,
  },
  {
    path: '/profile/edit',
    element: <EditProfile />,
  },
  {
    path: '/app/classes/form',
    element: hasPermission(
      [PERMISSIONS.CLASS_SECTION],
      [PERMISSION_TYPES.ADD, PERMISSION_TYPES.EDIT],
    ) ? (
      <ClassForm />
    ) : null,
  },
  {
    path: '/app/reports',
    element: hasPermission(
      [PERMISSIONS.CLASS_SECTION],
      [PERMISSION_TYPES.ADD, PERMISSION_TYPES.EDIT],
    ) ? (
      <Reports />
    ) : null,
  },
  {
    path: '/app/expense-reports',
    element: hasPermission(
      [PERMISSIONS.CLASS_SECTION],
      [PERMISSION_TYPES.ADD, PERMISSION_TYPES.EDIT],
    ) ? (
      <ExpenseReports />
    ) : null,
  },
  {
    path: '/app/non-expense-reports',
    element: hasPermission(
      [PERMISSIONS.CLASS_SECTION],
      [PERMISSION_TYPES.ADD, PERMISSION_TYPES.EDIT],
    ) ? (
      <NonExpenseReports />
    ) : null,
  },
  {
    path: '/app/defaulter-students',
    element: hasPermission(
      [PERMISSIONS.CLASS_SECTION],
      [PERMISSION_TYPES.ADD, PERMISSION_TYPES.EDIT],
    ) ? (
      <DefaulterStudents />
    ) : null,
  },
];

export default appsRoutes;
