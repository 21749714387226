import React, { useCallback, useRef, useState } from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Stack,
} from '@mui/material';
import { formatDate } from '@jumbo/utils';
import { getFullName } from 'app/utils/appHelpers';
import { StyledTableCell } from '../components/styled';
import { LoadingButton } from '@mui/lab';
import { useReactToPrint } from 'react-to-print';

const FeeReportDetails = ({ fees, toDate, fromDate }) => {
  const [loading, setLoading] = useState();
  const componentRef = useRef(null);

  const calculateCurrentMonthDues = () => {
    return fees?.reduce((total, item) => {
      return total + item?.paid_amount;
    }, 0);
  };

  const handleBeforePrint = useCallback(() => {
    setLoading(true);
  }, []);

  const handleAfterPrint = useCallback(() => {
    setLoading(false);
  }, []);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Receipt Dairy Report ${formatDate(Date.now(), 'DD-MMMM-YYYY-hh:mm A')}`,
    removeAfterPrint: true,
    onBeforePrint: handleBeforePrint,
    onAfterPrint: handleAfterPrint,
  });

  return (
    <>
      <LoadingButton
        variant="contained"
        size="small"
        loading={loading}
        onClick={handlePrint}
        sx={{ position: 'absolute', top: 15 }}>
        Print
      </LoadingButton>
      <Box px={2} py={0} ref={componentRef} minWidth={950}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="start"
          borderBottom="2.5px solid"
          py={2}>
          <Stack>
            <Typography>From Date: {fromDate}</Typography>
            <Typography>To Date: {toDate}</Typography>
          </Stack>
          <Typography variant="h3" fontWeight={600}>
            Receipt(s) Dairy Report
          </Typography>
          <Stack>
            <Typography variant="h4" fontWeight={600}>
              FOTAN Central School,
            </Typography>
            <Typography variant="h4" fontWeight={600}>
              {' '}
              {fees[0]?.branch?.name}
            </Typography>
          </Stack>
        </Stack>
        <TableContainer sx={{ boxShadow: 0 }}>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                <StyledTableCell>#</StyledTableCell>
                <StyledTableCell>Student Code</StyledTableCell>
                <StyledTableCell>Roll No</StyledTableCell>
                <StyledTableCell>Student Name</StyledTableCell>
                <StyledTableCell>Father Name</StyledTableCell>
                <StyledTableCell>Class</StyledTableCell>
                <StyledTableCell>Section</StyledTableCell>
                <StyledTableCell>Received Amount</StyledTableCell>
                <StyledTableCell>Received Date</StyledTableCell>
                <StyledTableCell>Received By</StyledTableCell>
              </TableRow>
            </TableHead>

            {fees.map((fee, index) => (
              <TableBody key={fee.id}>
                <StyledTableCell>{index + 1}</StyledTableCell>
                <StyledTableCell>{'---'}</StyledTableCell>
                <StyledTableCell>{fee.student.roll_no}</StyledTableCell>
                <StyledTableCell>
                  {getFullName({
                    first_name: fee.student.first_name,
                    last_name: fee.student.last_name,
                  })}
                </StyledTableCell>
                <StyledTableCell>{fee.student.father_name}</StyledTableCell>
                <StyledTableCell>
                  {fee.student?.class_section?.class}
                </StyledTableCell>
                <StyledTableCell>
                  {fee.student?.class_section?.section}
                </StyledTableCell>
                <StyledTableCell>{fee?.paid_amount}</StyledTableCell>
                <StyledTableCell>{'---'}</StyledTableCell>
                <StyledTableCell>{fee.received_by?.name}</StyledTableCell>
              </TableBody>
            ))}
            <StyledTableCell
              colSpan={8}
              sx={{ textAlign: 'right', border: 'none', fontWeight: 600 }}>
              {calculateCurrentMonthDues()}
            </StyledTableCell>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};
export default FeeReportDetails;
