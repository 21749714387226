import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@jumbo/components/JumboButton';

import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import { Form, Formik } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import Div from '@jumbo/shared/Div';
import JumboDropDown from '@jumbo/components/JumboFormik/JumboDropDown';

import { expenseSchema } from '../../utils/schema';

import {
  getAllExpenseCategories,
  addNewExpense,
} from 'app/store/features/expenseSlice';

import { GENERIC_YES_NO } from 'app/shared/constants';

const ExpenseForm = ({ expenseData }) => {
  const dispatch = useDispatch();
  const { hideDialog } = useJumboDialog();
  const expenseCategories = useSelector(
    state => state.expense.expenseCategories,
  );
  const isExpenseCategoriesFetched = useSelector(
    state => state.expense.isExpenseCategoriesFetched,
  );

  const fetchData = useCallback(async () => {
    if (!isExpenseCategoriesFetched) dispatch(getAllExpenseCategories());
  }, [dispatch, isExpenseCategoriesFetched]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const isUpdate = expenseData ? true : false;
  const initialValues = {
    expense_category_id: '',
    title: '',
    code: 1,
    detail: '',
    is_compulsory_expense: '',
    status: true,
  };

  const addExpense = data => {
    const payload = {
      ...data,
      is_compulsory_expense:
        data.is_compulsory_expense.toLowerCase() === 'yes' ? true : false,
    };
    dispatch(addNewExpense(payload));
    hideDialog();
  };

  const updateExpense = data => {
    // console.log(data);
    // hideDialog();
  };

  const onClassSave = data => {
    isUpdate ? updateExpense(data) : addExpense(data);
  };
  return (
    <Formik
      validateOnChange={true}
      initialValues={expenseData?.id ? expenseData : initialValues}
      enableReinitialize={true}
      validationSchema={expenseSchema}
      onSubmit={onClassSave}>
      {({ isSubmitting }) => (
        <Form noValidate autoComplete="off">
          <Div
            sx={{
              '& .MuiTextField-root': {
                mb: 3,
              },
            }}>
            <JumboTextField
              name="title"
              label="Title"
              variant="outlined"
              margin="normal"
              fullWidth
            />
            <JumboDropDown
              fullWidth
              size="small"
              variant="outlined"
              name="expense_category_id"
              label="Please Select Expense Category"
              options={expenseCategories}
            />
            <JumboTextField
              name="detail"
              label="Detail"
              variant="outlined"
              margin="normal"
              fullWidth
            />

            <JumboDropDown
              fullWidth
              size="small"
              variant="outlined"
              name="is_compulsory_expense"
              label="Is Expense Compulsory?"
              options={GENERIC_YES_NO}
            />

            <Div
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}>
              <Button
                type="submit"
                variant="outlined"
                sx={{ marginRight: 2 }}
                onClick={() => hideDialog()}>
                Close
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                loading={isSubmitting}>
                Save
              </Button>
            </Div>
          </Div>
        </Form>
      )}
    </Formik>
  );
};

export default ExpenseForm;
