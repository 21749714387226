import React from 'react';
import TabContext from '@mui/lab/TabContext';
import Div from '@jumbo/shared/Div';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import List from '@mui/material/List';

import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import StudentOverview from './TeacherOverview';
import Resume from './Resume';
import SalaryHistory from './SalaryHistory';

const About = ({ teacher }) => {
  const [value, setValue] = React.useState('Overview');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <JumboCardQuick
      title={'About'}
      action={
        <TabContext value={value}>
          <Div
            sx={{
              marginTop: -2.25,
              marginBottom: -2.5,

              '& .MuiTab-root': {
                py: 2.5,
              },
            }}>
            <TabList onChange={handleChange} aria-label="Teacher Details">
              <Tab label="Overview" value="Overview" />
              <Tab
                label="Previous Experience(Resume)"
                value="Previous Experience(Resume)"
              />
              <Tab label="Salary History" value="Salary History" />
            </TabList>
          </Div>
        </TabContext>
      }
      headerSx={{
        borderBottom: 1,
        borderColor: 'divider',
      }}
      sx={{ mb: 3.75 }}>
      <List
        disablePadding
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          margin: theme => theme.spacing(0, -2),
        }}>
        {value === 'Overview' ? <StudentOverview student={teacher} /> : null}
        {value === 'Previous Experience(Resume)' ? (
          <Resume teacher={teacher} />
        ) : null}
        {value === 'Salary History' ? (
          <SalaryHistory teacher={teacher} />
        ) : null}
      </List>
    </JumboCardQuick>
  );
};

export default About;
