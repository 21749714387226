import { Autocomplete } from '@mui/material';
import { styled } from '@mui/system';

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  '& legend': { display: 'none' },
  '& fieldset': { top: 0 },
  '& .MuiTextField-root': {
    marginBottom: 0,
  },
}));

export default StyledAutocomplete;
