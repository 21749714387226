import { UPLOAD_IMAGE_URL } from 'app/utils/constants/api/constants';
import axios from 'axios';

export const uploadImage = async formData => {
  try {
    const {
      data: { status, data, message },
    } = await axios.post(UPLOAD_IMAGE_URL, formData);
    if (status) {
      const image = data?.image?.split('/');
      return { data: { ...data, image: image?.[image?.length - 1] }, message };
    }
    return { message };
  } catch (error) {
    console.log(error);
    return error.response?.data;
  }
};
