import * as yup from 'yup';

export const feeSchema = yup.object().shape({
  student_ids: yup
    .array()
    .min(1, 'Student is Required')
    .required('Student is Required'),
  month: yup
    .number()
    .min(1, 'Month cannot be less than 1')
    .max(12, 'Month cannot be greater than 12')
    .required('Month is Required'),
  year: yup
    .number()
    .min(2000, 'Year cannot be less than 2000')
    .max(3000, 'Year cannot be greater than 3000')
    .required('Year is Required'),
  branch_id: yup.number().optional(),
});

export const feeSchema2 = yup.object().shape({
  student_ids: yup.number().required('Student is Required'),
  month: yup
    .number()
    .min(1, 'Month cannot be less than 1')
    .max(12, 'Month cannot be greater than 12')
    .required('Month is Required'),
  year: yup
    .number()
    .min(2000, 'Year cannot be less than 2000')
    .max(3000, 'Year cannot be greater than 3000')
    .required('Year is Required'),
  branch_id: yup.number().optional(),
});

export const feeSchema3 = yup.object().shape({
  student_ids: yup.array().optional('Student is Required'),
  month: yup
    .number()
    .min(1, 'Month cannot be less than 1')
    .max(12, 'Month cannot be greater than 12')
    .required('Month is Required'),
  year: yup
    .number()
    .min(2000, 'Year cannot be less than 2000')
    .max(3000, 'Year cannot be greater than 3000')
    .required('Year is Required'),
  branch_id: yup.number().required('Branch is Required'),
});
export const addFeeSchema = yup.object().shape({
  student: yup.string().required('Student is Required'),
  fee: yup
    .number()
    .positive('Fee cannot be Negative')
    .required('Fee is Required'),
});

export const paymentSchema = yup.object().shape({
  received_amount: yup
    .number()
    .min(1, 'Received amount cannot be less than 1')
    .required('Received amount is required')
    .label('Received Amount is required'),
  fees: yup
    .array()
    .of(
      yup.object().shape({
        paid_amount: yup
          .number()
          .required('Paid amount is required')
          .min(0, 'Paid amount must be greater than or equal to 0'),
        confirm_amount: yup
          .number()
          .required('Confirm amount is required')
          .min(0, 'Confirm amount must be greater than or equal to 0')
          .test(
            'matchPaidAmount',
            'Confirm amount must match the paid amount',
            function (value) {
              return value === this.parent.paid_amount;
            },
          ),
      }),
    )
    .min(1, 'At least one fee must be entered')
    .test({
      name: 'totalPaidAmount',
      test: function (fees) {
        const totalPaidAmount = fees.reduce(
          (acc, fee) => acc + fee.paid_amount,
          0,
        );
        return totalPaidAmount <= this.parent.received_amount;
      },
      message:
        'Received amount must be greater than or equal to total paid amount',
    }),
});
