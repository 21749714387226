import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  CircularProgress,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
  Card,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { Form, Formik } from 'formik';

import Div from '@jumbo/shared/Div';

// sub forms
import PersonalInfo from './SubForms/PersonalInfo';
import ParentalInfo from './SubForms/ParentalInfo';
import SchoolInfo from './SubForms/SchoolInfo';

import {
  parentalInfoSchema,
  personalInfoSchema,
  schoolInfoSchema,
} from '../../utils/schema';
import LoadingButton from '@mui/lab/LoadingButton';
import { addNewStudent, updateStudent } from 'app/store/features/studentsSlice';
import moment from 'moment';
import { removeEmptyKeys } from 'app/utils/appHelpers';
import { uploadImage } from 'app/services/uploadImage';

const FormikStep = ({ children }) => {
  return <>{children}</>;
};

const FormikStepper = ({ handleSubmit, children, ...props }) => {
  const childrenArray = React.Children.toArray(children);
  const [step, setStep] = useState(0);
  const currentChild = childrenArray[step];
  const [completed, setCompleted] = useState(false);

  const isLastStep = () => step === childrenArray.length - 1;

  return (
    <Formik
      {...props}
      validationSchema={currentChild.props.validationSchema}
      validateOnChange={true}
      enableReinitialize={true}
      onSubmit={async (values, helpers) => {
        try {
          await currentChild.props.validationSchema.validate(values, {
            abortEarly: true,
          });
          if (isLastStep()) {
            handleSubmit(values, helpers);
            setCompleted(true);
          } else {
            setStep(s => s + 1);
            helpers.setTouched({});
          }
        } catch (validationErrors) {
          console.log(JSON.stringify(validationErrors));

          const extractedErrors = validationErrors.errors.reduce(
            (acc, curr) => {
              return {
                ...acc,
                [validationErrors.path]: validationErrors.errors.join(', '), // Join multiple errors if they exist
              };
            },
            {},
          );

          helpers.setErrors(extractedErrors);

          helpers.setTouched({
            [validationErrors.path]: true,
          });
        }
      }}>
      {({ isSubmitting, values, setFieldValue, errors, touched }) => (
        <Form autoComplete="off">
          <Stepper alternativeLabel activeStep={step}>
            {childrenArray.map((child, index) => (
              <Step
                key={child.props.label}
                completed={step > index || completed}>
                <StepLabel>{child.props.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {currentChild}
          <Div sx={{ my: 2 }}>
            {Object.entries(errors).map(([key, value]) => {
              if (touched[key]) {
                return (
                  <Typography key={key} variant="body2" my={1} color="red">
                    {value}
                  </Typography>
                );
              } else {
                return null;
              }
            })}
          </Div>

          <Grid
            container
            spacing={2}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              paddingTop: 2,
            }}>
            {step > 0 && (
              <Grid item sx={{ marginRight: 2 }}>
                <Button
                  disabled={isSubmitting}
                  variant="outlined"
                  size="large"
                  onClick={() => setStep(s => s - 1)}>
                  Back
                </Button>
              </Grid>
            )}
            <Grid item>
              <LoadingButton
                startIcon={
                  isSubmitting ? <CircularProgress size="1rem" /> : null
                }
                disabled={isSubmitting}
                type="submit"
                variant="contained"
                size="large">
                {isSubmitting ? 'Submitting' : isLastStep() ? 'Submit' : 'Next'}
              </LoadingButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

const StudentForm = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();

  const isUpdate = state ? true : false;

  const handleSubmit = async values => {
    const payload = removeEmptyKeys(values);
    if (payload?.first_school) {
      delete payload.pre_school_name;
    }
    if (values.image && typeof values.image !== 'string') {
      const formData = new FormData();
      formData.append('image', values.image);

      const { data } = await uploadImage(formData);

      if (data) {
        payload.image = data?.image;
      }
    }

    payload.date_of_birth = moment(values.date_of_birth).format('MM-DD-YYYY');
    payload.admission_date = moment(values.admission_date).format('MM-DD-YYYY');
    if (values.inactive_from)
      payload.inactive_from = moment(values.inactive_from).format('MM-DD-YYYY');

    if (state) {
      const temp = removeEmptyKeys(values);
      const payload = { ...temp, id: state.id };
      delete payload['createdAt'];
      delete payload['updatedAt'];
      dispatch(updateStudent(payload));
    } else {
      dispatch(addNewStudent(payload));
    }
  };

  return (
    <Card sx={{ padding: 4 }}>
      <Typography fontSize={20} mb={2} fontWeight={500} textAlign={'center'}>
        {isUpdate ? 'Update Student' : 'Add New Student'}
      </Typography>
      <FormikStepper
        handleSubmit={handleSubmit}
        initialValues={{
          image: state ? state.image : null,
          first_name: state ? state.first_name : '',
          last_name: state ? state.last_name : '',
          nick_name: state ? state.nick_name : '',
          cnic: state ? state.cnic : ' ',
          gender: state
            ? state.gender === '0' || state.gender === '' || !state.gender
              ? '0'
              : '1'
            : '0',
          date_of_birth: state ? state.date_of_birth : '',
          admission_date: state ? state.admission_date : '',
          age: state ? state.age : '',
          district: state ? state.district : '',
          tehsil: state ? state.tehsil : '',
          village_id: state ? state.village?.id : '',
          address: state ? state.address : '',
          blood_group: state ? state.blood_group : '',
          nationality: state ? state.nationality : 'Pakistani',
          religion: state ? state.religion : 'Islam',
          home_language: state ? state.home_language : 'Punjabi',
          registration_no: state ? state.registration_no : 1,

          father_name: state ? state.father_name : '',
          father_cnic: state ? state.father_cnic : '',
          father_occupation_id: state ? state.father_occupation?.id : '',
          father_qualification: state ? state.father_qualification : '',
          father_mobile_number: state ? state.father_mobile_number : '+92',
          mother_name: state ? state.mother_name : '',
          mother_cnic: state ? state.mother_cnic : '',
          mother_mobile_number: state ? state.mother_mobile_number : '+92',
          mother_qualification: state ? state.mother_qualification : '',
          mother_occupation_id: state ? state.mother_occupation?.id : '',
          other_mobile_number: state ? state.other_mobile_number : '+92',
          whats_app_mobile_number: state
            ? state?.whats_app_mobile_number
            : '+92',

          primary_contact: state ? state.primary_contact : '',
          no_of_siblings: state ? state.no_of_siblings : '',
          caste: state ? state.caste : '',
          family_code: state ? state.family_code : '',

          branch_id: state ? state.branch?.id : '',
          class_section_id: state ? state.class_section?.id : '',
          status: state ? state.status : true,
          roll_no: state ? state.roll_no : 1,
          pre_school_name: state
            ? state.pre_school_name
              ? state.pre_school_name
              : ''
            : '',
          prev_no_of_schools: state
            ? state.prev_no_of_schools
              ? state.prev_no_of_schools
              : 0
            : 0,
          prev_no_of_failures: state ? state.prev_no_of_failures : 0,
          caution: state ? state.caution : '',
          medium: state ? state.medium : 'english',
          inactive_from: state
            ? state.inactive_from
              ? state.inactive_from
              : ''
            : '',
          inactive_reason_id: state ? state.inactive_reason_id : '',
          is_special_case: state ? state.is_special_case : false,
          special_remarks: state ? state.special_remarks : '',
          first_month_fee: state ? state.first_month_fee : '',
          register_fee_discount: state ? state.register_fee_discount : '',
          school_transport: state ? state.school_transport : false,
          is_employee_child: state ? state.is_employee_child : false,
          first_school: state ? state.first_school : true,
          sd_adjustment: state ? state.sd_adjustment : 0,
          give_full_discount: state ? state.give_full_discount : false,
          sd_adjustment_from: state ? state.sd_adjustment_from : '',
        }}>
        <FormikStep label="Personal Info" validationSchema={personalInfoSchema}>
          <Div
            sx={{
              marginTop: 2,
              '& .MuiTextField-root': {
                mb: 3,
              },
            }}>
            <PersonalInfo />
          </Div>
        </FormikStep>
        <FormikStep label="Parental Info" validationSchema={parentalInfoSchema}>
          <Div
            sx={{
              '& .MuiTextField-root': {
                mb: 3,
              },
            }}>
            <ParentalInfo />
          </Div>
        </FormikStep>
        <FormikStep label="School Info" validationSchema={schoolInfoSchema}>
          <Div
            sx={{
              marginTop: 2,
              '& .MuiTextField-root': {
                mb: 3,
              },
            }}>
            <SchoolInfo />
          </Div>
        </FormikStep>
      </FormikStepper>
    </Card>
  );
};

export default StudentForm;
