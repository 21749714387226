import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            width: '100vw',
            textAlign: 'center',
          }}>
          <div>
            <h2>Oops, something went wrong!</h2>
            <p>We're sorry, but an error occurred while rendering this page.</p>
            <p>
              Please try again later, or click the button below to refresh the
              page.
            </p>
            <button
              onClick={() => window.location.reload(true)}
              style={{
                border: 'none',
                background: 'transparent',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}>
              Refresh Page
            </button>

            <button
              onClick={() => window.location.replace('/')}
              style={{
                border: 'none',
                background: 'transparent',
                textDecoration: 'underline',
                cursor: 'pointer',
                marginTop: 15,
              }}>
              Back to Home
            </button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
