import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import Header from './Header';
import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import { useJumboTheme } from '@jumbo/hooks';
import { alpha } from '@mui/material/styles';
import About from './components/About';
import { ASSET_IMAGES } from 'app/utils/constants/paths';
import { getAssetPath } from 'app/utils/appHelpers';

import Div from '@jumbo/shared/Div';
import Contacts from './components/Contacts';

const TeacherProfile = () => {
  const { theme } = useJumboTheme();
  const teachers = useSelector(state => state.teachers.teachers);
  const { id } = useParams();
  const selectedTeacher = teachers?.filter(
    teacher => teacher.id === Number(id),
  )[0];

  if (!selectedTeacher) {
    return (
      <Div
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          minHeight: '60vh',
        }}>
        <Div sx={{ width: '500', height: '500' }}>
          <img
            src="/images/empty.png"
            alt="No Results"
            width="100%"
            height="100%"
          />
        </Div>
        <Typography fontSize={20} fontWeight={500}>
          {' '}
          No User Found Try A gun
        </Typography>
      </Div>
    );
  }

  return (
    <JumboContentLayout
      header={<Header teacher={selectedTeacher} />}
      layoutOptions={{
        header: {
          sx: {
            mt: -4,
            mb: -7.25,
            mx: { xs: -4, lg: -6 },
            p: { xs: theme.spacing(6, 4, 11), lg: theme.spacing(6, 6, 11) },
            background: `#002447 url(${getAssetPath(
              `${ASSET_IMAGES}/profile-bg.jpg`,
              '1920x580',
            )})s no-repeat center`,
            backgroundSize: 'cover',
            color: 'common.white',
            position: 'relative',

            '&::after': {
              display: 'inline-block',
              position: 'absolute',
              content: `''`,
              inset: 0,
              backgroundColor: alpha(theme.palette.common.black, 0.65),
            },
          },
        },
        wrapper: {
          sx: {
            [theme.breakpoints.down('lg')]: {
              flexDirection: 'column',
            },
          },
        },
        main: {
          sx: {
            [theme.breakpoints.down('lg')]: {
              minHeight: 0,
            },
          },
        },
      }}>
      <Grid container spacing={3.75}>
        <Grid item xs={12} sm={6} md={4}>
          <Contacts teacher={selectedTeacher} />
        </Grid>
        <Grid item xs={12} sm={6} md={8}>
          <About teacher={selectedTeacher} />
        </Grid>
      </Grid>
    </JumboContentLayout>
  );
};

export default TeacherProfile;
