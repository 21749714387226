import * as yup from 'yup';

export const expenseSchema = yup.object().shape({
  expense_category_id: yup.number().required('Expense Category is required'),
  title: yup.string().required('Expense Title is required'),
  detail: yup.string().required('Expense Detail is required'),
  is_compulsory_expense: yup.string().required('Is Expense Compulsory?'),
});

export const billSchema = yup.object().shape({
  branch_id: yup
    .number()
    .min(0, 'Please provide a positive Value')
    .required('Branch is Required'),
  vendor_id: yup
    .number()
    .min(0, 'Please provide a positive Value')
    .required('Vendor is Required'),
  paid_to: yup.string().required('Paid to is Required'),
  bill_date: yup
    .date()
    .max(new Date(), 'Bill date cannot be in the future')
    .required('Bill Date is Required'),
  paid_by_other: yup.string().required('Paid by Other is Required'),
  bill_amount: yup
    .number()
    .min(0, 'Please provide a positive Value')
    .required('Bill Amount is Required'),
  paid_bill_amount: yup
    .number()
    .min(0, 'Please provide a positive Value')
    .max(
      yup.ref('bill_amount'),
      'Paid bill amount cannot be greater than bill amount',
    )
    .required('Paid bill Amount is Required'),
  status: yup.string().required('Status is Required'),
});
