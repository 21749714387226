import { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  useJumboTheme,
  useJumboLayoutSidebar,
  useJumboHeaderTheme,
  useJumboLayoutHeader,
  useJumboSidebarTheme,
} from '@jumbo/hooks';
import { sidebarTheme as defaultSidebarTheme } from './themes/sidebar/default';
import { sidebarTheme as sidebarTheme1 } from './themes/sidebar/theme1';

const UserTheme = () => {
  const { userTheme } = useSelector(state => state.auth);
  const { theme, setTheme } = useJumboTheme();
  const { setSidebarOptions } = useJumboLayoutSidebar();
  const { setHeaderTheme } = useJumboHeaderTheme();
  const { setHeaderOptions } = useJumboLayoutHeader();
  const { sidebarTheme, setSidebarTheme } = useJumboSidebarTheme();

  useLayoutEffect(() => {
    if (userTheme?.mainTheme) {
      setTheme(userTheme?.mainTheme?.themeObject);
    }

    if (userTheme?.sidebar) {
      setSidebarOptions(userTheme?.sidebar);
    }

    if (userTheme?.headerTheme) {
      setHeaderTheme({
        ...theme,
        ...userTheme?.headerTheme,
      });
    }

    if (userTheme?.headerFixed) {
      setHeaderOptions(userTheme?.headerFixed?.fixed);
    }

    /**
     * sidebar positioning
     */
    if (userTheme?.sidebarScrollType) {
      setSidebarOptions({
        scrollType: userTheme?.sidebarScrollType,
      });
    }

    /**
     * sidebar options
     * 1 - when color or gradient is selected
     */
    if (userTheme?.sidebarBgColor) {
      /**
       * 2 - if bg image is selected
       */
      if (userTheme?.sidebarBgImage) {
        /**
         * 3- if color is single solid color
         */
        if (userTheme?.sidebarBgColor?.type === 'single') {
          setSidebarTheme({
            ...sidebarTheme,
            ...sidebarTheme1,
            bgImage: userTheme?.sidebarBgImage?.full,
            overlay: {
              bgColor: userTheme?.sidebarBgColor?.color,
              opacity: 0.85,
            },
          });
        } else {
          /**
           * 4- if gradient
           */
          setSidebarTheme({
            ...sidebarTheme,
            ...sidebarTheme1,
            bgImage: userTheme?.sidebarBgImage?.full,
            overlay: {
              bgColor: userTheme?.sidebarBgColor?.colors,
              opacity: 0.85,
            },
          });
        }
      } else if (userTheme?.sidebarBgColor?.type === 'single') {
        /**
         * 5- if no image is applied but a single solid color is selected
         */
        setSidebarTheme({
          ...sidebarTheme,
          ...sidebarTheme1,
          overlay: {
            bgColor: userTheme?.sidebarBgColor?.color,
          },
        });
      } else {
        /**
         * 6- if no image but gradient is selected
         */
        setSidebarTheme({
          ...sidebarTheme,
          ...sidebarTheme1,
          overlay: {
            bgColor: userTheme?.sidebarBgColor.colors,
          },
        });
      }
    } else {
      /**
       * 7- if no color nor gradient is selected
       */
      setSidebarTheme({
        ...sidebarTheme,
        ...defaultSidebarTheme,
        bgImage: null,
        overlay: null,
      });
    }
  }, [userTheme]);

  return <></>;
};

export default UserTheme;
