export const calculateMonthlyAverage = (branches, month) => {
  const tempArray = [];

  for (let i = 0; i < branches.length; i++) {
    let obj = {};
    obj.name = branches[i].name;
    // console.log(branches[i].revenueSummary);
    const filtered = branches[i].revenueSummary.filter(
      item => item.month.toLowerCase().trim() === month.toLowerCase().trim(),
    )[0];
    obj.Earning = filtered.earning;
    obj.Expenses = filtered.expense;

    tempArray.push(obj);
  }
  return tempArray;
};

export const calculateHighestAverage = (branches, month) => {
  const tempArray = [];

  for (let i = 0; i < branches.length; i++) {
    let obj = {};
    obj.name = branches[i].name;
    // console.log(branches[i].revenueSummary);
    const filtered = branches[i].revenueSummary.filter(
      item => item.month.toLowerCase().trim() === month.toLowerCase().trim(),
    )[0];
    obj.Earning = filtered.earning;
    obj.Expenses = filtered.expense;

    tempArray.push(obj);
  }

  let highestPerforming = tempArray[0];

  for (let i = 0; i < tempArray.length; i++) {
    if (tempArray[i].Earning >= highestPerforming.Earning) {
      highestPerforming = tempArray[i];
    }
  }

  return [highestPerforming];
};

export const calculateLowestAverage = (branches, month) => {
  const tempArray = [];

  for (let i = 0; i < branches.length; i++) {
    let obj = {};
    obj.name = branches[i].name;
    // console.log(branches[i].revenueSummary);
    const filtered = branches[i].revenueSummary.filter(
      item => item.month.toLowerCase().trim() === month.toLowerCase().trim(),
    )[0];
    obj.Earning = filtered.earning;
    obj.Expenses = filtered.expense;

    tempArray.push(obj);
  }

  let lowestPerforming = tempArray[0];

  for (let i = 0; i < tempArray.length; i++) {
    if (tempArray[i].Earning <= lowestPerforming.Earning) {
      lowestPerforming = tempArray[i];
    }
  }

  return [lowestPerforming];
};
