import {
  alpha,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Avatar,
  IconButton,
  CardHeader,
  Divider,
  List,
  Chip,
} from '@mui/material';

import {
  FamilyRestroom,
  Close,
  Business,
  School,
  ToggleOff,
  ToggleOn,
  Man2,
  Category,
  PhoneAndroid,
  DocumentScanner,
  SettingsAccessibility,
} from '@mui/icons-material';

import Div from '@jumbo/shared/Div';
import Span from '@jumbo/shared/Span';
import { titlalize } from 'app/utils/appHelpers';

const UserDetail = ({ teacher, onClose }) => {
  return (
    <Div sx={{ m: theme => theme.spacing(-2.5, -3) }}>
      <CardHeader
        title={teacher?.name}
        avatar={<Avatar src={teacher?.image} />}
        subheader={<Typography>{teacher?.role?.display_title}</Typography>}
        action={
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        }
      />
      <List disablePadding>
        <ListItem sx={{ px: 4 }}>
          <ListItemAvatar sx={{ minWidth: 66 }}>
            <Avatar
              variant="rounded"
              sx={{
                height: 48,
                width: 48,
                bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
              }}>
              <FamilyRestroom sx={{ color: 'primary.light' }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Span
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: 0.5,
                }}>
                <Span>
                  <Typography color="gray">User's Role</Typography>
                  <Span>
                    <Typography variant="body2">
                      {teacher?.role?.display_title}
                    </Typography>
                  </Span>
                </Span>
              </Span>
            }
          />
        </ListItem>
        <Divider component={'li'} />

        <ListItem sx={{ px: 4 }}>
          <ListItemAvatar sx={{ minWidth: 66 }}>
            <Avatar
              variant="rounded"
              sx={{
                height: 48,
                width: 48,
                bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
              }}>
              <PhoneAndroid sx={{ color: 'primary.light' }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Span
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: 0.5,
                }}>
                <Span>
                  <Typography color="gray">User's Phone Number</Typography>
                  <Span>
                    <Typography variant="body2">{teacher?.phone_no}</Typography>
                  </Span>
                </Span>
              </Span>
            }
          />
        </ListItem>
        <Divider component={'li'} />

        {teacher?.phone_no_2 && (
          <>
            <ListItem sx={{ px: 4 }}>
              <ListItemAvatar sx={{ minWidth: 66 }}>
                <Avatar
                  variant="rounded"
                  sx={{
                    height: 48,
                    width: 48,
                    bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
                  }}>
                  <Man2 sx={{ color: 'primary.light' }} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Span
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      marginBottom: 0.5,
                    }}>
                    <Span>
                      <Typography color="gray">
                        User's Phone Number 2
                      </Typography>
                      <Span>
                        <Typography variant="body2">
                          {teacher?.phone_no_2}
                        </Typography>
                      </Span>
                    </Span>
                  </Span>
                }
              />
            </ListItem>
            <Divider component={'li'} />
          </>
        )}

        <ListItem sx={{ px: 4 }}>
          <ListItemAvatar sx={{ minWidth: 66 }}>
            <Avatar
              variant="rounded"
              sx={{
                height: 48,
                width: 48,
                bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
              }}>
              <Man2 sx={{ color: 'primary.light' }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Span
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: 0.5,
                }}>
                <Span>
                  <Typography color="gray">User's username</Typography>
                  <Span>
                    <Typography variant="body2">{teacher?.username}</Typography>
                  </Span>
                </Span>
              </Span>
            }
          />
        </ListItem>
        <Divider component={'li'} />

        <ListItem sx={{ px: 4 }}>
          <ListItemAvatar sx={{ minWidth: 66 }}>
            <Avatar
              variant="rounded"
              sx={{
                height: 48,
                width: 48,
                bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
              }}>
              <Business sx={{ color: 'primary.light' }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant={'body1'} color={'text.secondary'} mb={0.5}>
                User Branch
              </Typography>
            }
            secondary={
              <Span>
                <Typography variant={'body2'} color="gray" mb={0}>
                  {teacher?.branch?.name}
                </Typography>
              </Span>
            }
          />
        </ListItem>
        <Divider component={'li'} />
        <ListItem sx={{ px: 4 }}>
          <ListItemAvatar sx={{ minWidth: 66 }}>
            <Avatar
              variant="rounded"
              sx={{
                height: 48,
                width: 48,
                bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
              }}>
              <School sx={{ color: 'primary.light' }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant={'body1'} color={'text.secondary'} mb={0.5}>
                User's Assigned Color
              </Typography>
            }
            secondary={
              <Typography component={'div'}>
                <Chip
                  label={teacher?.color?.title}
                  color={
                    teacher?.color?.title === 'Excellent' ||
                    teacher?.color?.title === 'Best'
                      ? 'primary'
                      : teacher?.color.title === 'Average'
                      ? 'secondary'
                      : 'error'
                  }
                />
              </Typography>
            }
          />
        </ListItem>

        <Divider component="li" />
        <ListItem sx={{ px: 4 }}>
          <ListItemAvatar sx={{ minWidth: 66 }}>
            <Avatar
              variant="rounded"
              sx={{
                height: 48,
                width: 48,
                bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
              }}>
              {teacher?.status ? (
                <ToggleOn sx={{ color: 'primary.light' }} />
              ) : (
                <ToggleOff sx={{ color: 'primary.light' }} />
              )}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant={'body1'} color={'text.secondary'} mb={0.5}>
                User's Status
              </Typography>
            }
            secondary={
              <Typography component={'div'}>
                {teacher?.status ? 'Active' : 'InActive'}
              </Typography>
            }
          />
        </ListItem>

        {teacher?.reference && (
          <>
            <Divider component="li" />
            <ListItem sx={{ px: 4 }}>
              <ListItemAvatar sx={{ minWidth: 66 }}>
                <Avatar
                  variant="rounded"
                  sx={{
                    height: 48,
                    width: 48,
                    bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
                  }}>
                  <SettingsAccessibility sx={{ color: 'primary.light' }} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography
                    variant={'body1'}
                    color={'text.secondary'}
                    mb={0.5}>
                    User's Reference
                  </Typography>
                }
                secondary={
                  <Typography component={'div'}>
                    {teacher?.reference}
                  </Typography>
                }
              />
            </ListItem>
          </>
        )}

        <Divider component="li" />
        <ListItem sx={{ px: 4 }}>
          <ListItemAvatar sx={{ minWidth: 66 }}>
            <Avatar
              variant="rounded"
              sx={{
                height: 48,
                width: 48,
                bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
              }}>
              <Category sx={{ color: 'primary.light' }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant={'body1'} color={'text.secondary'} mb={0.5}>
                User's Type
              </Typography>
            }
            secondary={
              <Typography component={'div'}>
                {titlalize(teacher?.user_type)}
              </Typography>
            }
          />
        </ListItem>

        {teacher?.cv ||
          (teacher?.contract && (
            <>
              <Divider component="li" />
              <ListItem sx={{ px: 4 }}>
                <ListItemAvatar sx={{ minWidth: 66 }}>
                  <Avatar
                    variant="rounded"
                    sx={{
                      height: 48,
                      width: 48,
                      bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
                    }}>
                    <DocumentScanner sx={{ color: 'primary.light' }} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography
                      variant={'body1'}
                      color={'text.secondary'}
                      mb={0.5}>
                      User's Documents
                    </Typography>
                  }
                  secondary={
                    <Typography component={'div'}>
                      {/* {titlalize(teacher?.user_type)} */}
                      coming soon
                    </Typography>
                  }
                />
              </ListItem>
            </>
          ))}
      </List>
    </Div>
  );
};

export default UserDetail;
