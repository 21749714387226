import { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';
import { Card } from '@mui/material';

import JumboRqList from '@jumbo/components/JumboReactQuery/JumboRqList';
import JumboListToolbar from '@jumbo/components/JumboList/components/JumboListToolbar';
import JumboSearch from '@jumbo/components/JumboSearch';

import InventoryItem from './InventoryItem';

const InventoryList = () => {
  const { category } = useParams();
  const listRef = useRef();
  const items = useSelector(state => state.item.items);

  const [itemsToShow, setItemsToShow] = useState(items);

  useEffect(() => {
    setItemsToShow(items);
  }, [items]);

  useEffect(() => {
    if (category === 'all') {
      setItemsToShow(items);
    } else {
      const filteredClasses = items?.filter(item => {
        const branchName = item.branch;
        if (category === branchName?.toLowerCase()) {
          return item;
        }
        return null;
      });
      setItemsToShow(filteredClasses);
    }
  }, [category, items]);

  const renderItem = useCallback(item => {
    return <InventoryItem item={item} view={'list'} />;
  }, []);

  const handleOnChange = useCallback(
    keyword => {
      let filtered = [];
      filtered = items?.filter(
        item => item?.name?.toLowerCase().search(keyword?.toLowerCase()) !== -1,
      );
      setItemsToShow(filtered);
    },
    [items],
  );

  return (
    <JumboRqList
      ref={listRef}
      data={itemsToShow}
      wrapperComponent={Card}
      primaryKey={'id'}
      itemsPerPage={8}
      itemsPerPageOptions={[8, 15, 20]}
      renderItem={renderItem}
      componentElement={'div'}
      isClasses
      // sx={view === 'grid' && { p: theme => theme.spacing(1, 3, 3) }}
      wrapperSx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
      toolbar={
        <JumboListToolbar hideItemsPerPage={true}>
          <JumboSearch
            onChange={handleOnChange}
            sx={{
              display: { xs: 'none', md: 'block' },
            }}
          />
        </JumboListToolbar>
      }
      view={'list'}
    />
  );
};

export default InventoryList;
