import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Stack, Typography, Switch, styled } from '@mui/material';

import Div from '@jumbo/shared/Div';
import DropDown from 'app/shared/components/DropDown';
import AttendanceCard from './components/AttendanceCard';
import AttendancePaginatedTable from './components/AttendancePaginatedTable';

import { getAttendance } from 'app/store/features/studentsSlice';
import { getAllBranches } from 'app/store/features/branchSlice';
import { hasPermission, isSuperAdmin } from 'app/utils/appHelpers';
import { PERMISSIONS, PERMISSION_TYPES } from 'app/utils/constants/permissions';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const Attendance = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const isAttendanceFetched = useSelector(
    state => state.students.isAttendancesFetched,
  );
  const attendances = useSelector(state => state.students.attendances);
  const storeBranches = useSelector(state => state.branches.branches);
  const isStoreBranches = useSelector(state => state.branches.isDataFetched);

  const [selectedBranch, setSelectedBranch] = useState(
    isSuperAdmin() ? storeBranches[0]?.id : user?.branch_id,
  );
  const [filteredAttendance, setFilteredAttendance] = useState([]);
  const [value, setValue] = useState('graph');

  const fetchData = useCallback(() => {
    if (!isAttendanceFetched) dispatch(getAttendance());
    if (!isStoreBranches) dispatch(getAllBranches());
  }, [isAttendanceFetched, dispatch, isStoreBranches]);

  useEffect(() => {
    fetchData();
    if (!user.branch_id) {
      if (!selectedBranch) setFilteredAttendance(attendances);
      else {
        const filtered = attendances.filter(
          singleAttendance =>
            singleAttendance?.class_section?.branch_id === selectedBranch,
        );
        setFilteredAttendance(filtered);
      }
    }
  }, [fetchData, attendances, selectedBranch, user.branch_id]);

  return (
    <div>
      <Div
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <Typography variant="h1">Attendance</Typography>
        {hasPermission([PERMISSIONS.ATTENDANCE], [PERMISSION_TYPES.ADD]) && (
          <Button
            variant="contained"
            onClick={() => navigate('/app/attendance/add')}>
            Add Attendance
          </Button>
        )}
      </Div>
      {hasPermission([PERMISSIONS.ATTENDANCE], [PERMISSION_TYPES.LISTING]) && (
        <>
          {!user?.branch_id && (
            <Div
              sx={{
                mt: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
              <DropDown
                options={storeBranches}
                value={selectedBranch}
                label="Select Branch"
                onChange={e => setSelectedBranch(e.target.value)}
                sx={{ width: '45%' }}
                disabled={!isSuperAdmin()}
              />
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography>Graphs</Typography>
                <AntSwitch
                  checked={value === 'table'}
                  onChange={() =>
                    setValue(value === 'table' ? 'graph' : 'table')
                  }
                  inputProps={{ 'aria-label': 'ant design' }}
                />
                <Typography>Table</Typography>
              </Stack>
            </Div>
          )}
          {value === 'graph' ? (
            <>
              <Div sx={{ marginBottom: 2 }}>
                <AttendanceCard
                  title="Daily Attendance"
                  data={filteredAttendance}
                  flag="daily"
                  selectedBranch={selectedBranch}
                />
              </Div>
              <Div sx={{ marginBottom: 2 }}>
                <AttendanceCard
                  title="Weekly Attendance"
                  data={filteredAttendance}
                  flag="weekly"
                  selectedBranch={selectedBranch}
                />
              </Div>
              <Div sx={{ marginBottom: 2 }}>
                <AttendanceCard
                  title="Monthly Attendance"
                  data={filteredAttendance}
                  flag="monthly"
                  selectedBranch={selectedBranch}
                />
              </Div>
              <Div sx={{ marginBottom: 2 }}>
                <AttendanceCard
                  title="All Time Attendance"
                  data={filteredAttendance}
                  flag="all"
                  selectedBranch={selectedBranch}
                />
              </Div>
            </>
          ) : (
            <>
              <AttendancePaginatedTable
                data={filteredAttendance}
                selectedBranch={selectedBranch}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Attendance;
