import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_POST } from 'app/utils/constants/api/apiRequests';
import { setLoading, setLoadingFinished } from './loadingSlice';
import { showAlert } from './alertSlice';

const initialState = {
  feeList: [],
  isFeeListFetched: false,
  expenseList: [],
  isExpenseListFetched: false,
  nonExpenseList: [],
  isNonExpenseListFetched: false,
  defaulterStudentsList: [],
  isDefaulterStudentsListFetched: false,
};

export const getAllFeeList = createAsyncThunk(
  'report/getAllFeeList',
  async (payload, { dispatch }) => {
    dispatch(setLoading());

    try {
      const { status, data, message } = await API_POST(
        '/fee/account-statement',
        payload,
      );

      if (status) {
        return data;
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const getExpenseList = createAsyncThunk(
  'report/getExpenseList',
  async (payload, { dispatch }) => {
    dispatch(setLoading());

    try {
      const { status, data, message } = await API_POST(
        '/expense/report',
        payload,
      );

      if (status) {
        return data;
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const getDefaulterStudentsList = createAsyncThunk(
  'report/getDefaulterStudentsList',
  async (payload, { dispatch }) => {
    dispatch(setLoading());

    try {
      const { status, data, message } = await API_POST(
        '/fee/fee-defaulter',
        payload,
      );

      if (status) {
        return data;
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const getNoneExpenseList = createAsyncThunk(
  'report/getNoneExpenseList',
  async (payload, { dispatch }) => {
    dispatch(setLoading());

    try {
      const { status, data, message } = await API_POST(
        '/non-expense-payment/report',
        payload,
      );

      if (status) {
        return data;
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const colorSlice = createSlice({
  name: 'report',
  initialState,

  extraReducers: builder => {
    builder.addCase(getAllFeeList.fulfilled, (state, { payload }) => {
      state.feeList = payload;
      state.isFeeListFetched = true;
    });

    builder.addCase(getExpenseList.fulfilled, (state, { payload }) => {
      state.expenseList = payload;
      state.isExpenseListFetched = true;
    });

    builder.addCase(
      getDefaulterStudentsList.fulfilled,
      (state, { payload }) => {
        state.defaulterStudentsList = payload;
        state.isDefaulterStudentsListFetched = true;
      },
    );

    builder.addCase(getNoneExpenseList.fulfilled, (state, { payload }) => {
      state.nonExpenseList = payload;
      state.isNonExpenseListFetched = true;
    });
  },
});

export default colorSlice.reducer;
