import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';

import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import Div from '@jumbo/shared/Div';
import Button from '@jumbo/components/JumboButton';

import { schema } from '../../utils/schema';
import {
  createTaskToUser,
  editTaskToUser,
} from 'app/store/features/configurationSlice';
import { Grid } from '@mui/material';
import JumboDropDown from '@jumbo/components/JumboFormik/JumboDropDown';
import { useEffect, useState } from 'react';
import { getAllUsers } from 'app/store/features/usersSlice';

const FormComponent = ({ payload }) => {
  const dispatch = useDispatch();
  const { hideDialog } = useJumboDialog();
  const loggedInUser = useSelector(state => state.auth.user);
  const { users, isDataFetched } = useSelector(state => state.users);

  const [filteredUsers, setFilteredUsers] = useState(users || []);

  useEffect(() => {
    if (!isDataFetched) {
      dispatch(getAllUsers());
    }
  }, [isDataFetched, dispatch]);

  useEffect(() => {
    if (users) {
      const filtered = users.filter(user => user.id !== loggedInUser.id);
      setFilteredUsers(filtered);
    }
  }, [loggedInUser.id, users]);

  const isUpdate = payload ? true : false;
  const initialValues = {
    user_id: payload ? payload.assigned_to?.id : '',
    description: payload ? payload.description : '',
    due_date: payload ? payload.due_date : '',
    status: payload ? payload.status : 'Just got assigned',
  };

  const addHandler = data => {
    const payload = { ...data, assigned_by_id: loggedInUser?.id };

    dispatch(createTaskToUser(payload));
  };

  const updateHandler = data => {
    const payloadToSend = {
      id: payload.id,
      user_id: data.user_id,
      description: data.description,
      due_date: data.due_date,
      status: data.status,
      assigned_by_id: loggedInUser?.id,
    };

    dispatch(editTaskToUser(payloadToSend));
  };

  const onSave = data => {
    isUpdate ? updateHandler(data) : addHandler(data);
    hideDialog();
  };
  return (
    <Formik
      validateOnChange={true}
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={schema}
      onSubmit={onSave}>
      {({ isSubmitting }) => (
        <Form noValidate autoComplete="off">
          <Div
            sx={{
              '& .MuiTextField-root': {
                mb: 3,
              },
            }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <JumboDropDown
                  fullWidth
                  size="small"
                  variant="outlined"
                  name="user_id"
                  label="User"
                  options={filteredUsers}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <JumboTextField
                  fullWidth
                  type="date"
                  size="small"
                  variant="outlined"
                  name="due_date"
                  label="Due Date"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    inputProps: { min: '1990-01-01', max: '9999-01-01' },
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <JumboTextField
                  fullWidth
                  size="large"
                  variant="outlined"
                  name="description"
                  label="Description of task"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <JumboTextField
                  fullWidth
                  size="large"
                  variant="outlined"
                  name="status"
                  label="Status of task"
                />
              </Grid>
            </Grid>

            <Div
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}>
              <Button
                variant="outlined"
                sx={{
                  mr: 2,
                }}
                onClick={() => hideDialog()}>
                Close
              </Button>
              <Button type="submit" variant="contained" loading={isSubmitting}>
                Save
              </Button>
            </Div>
          </Div>
        </Form>
      )}
    </Formik>
  );
};

export default FormComponent;
