import { isInDevelopment } from 'app/utils/constants/api/constants';
import {
  UNAUTHORIZED,
  UNAUTHENTICATED,
} from 'app/utils/constants/api/apiResponses';

export const onRequestSuccess = config => {
  if (isInDevelopment) console.log('request success', config);
  const token = localStorage.getItem('auth_token');
  if (token) {
    if (isInDevelopment) console.log('token', token);
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};
export const onRequestFail = error => {
  if (isInDevelopment) console.log('request error', error);
  return Promise.reject(error);
};

// response methods
export const onResponseSuccess = response => {
  if (isInDevelopment) console.log('response success', response);
  return response;
};
export const onResponseFail = error => {
  if (isInDevelopment) console.log('response error', error);
  const status = error.status || error.response.status;
  if (status === UNAUTHENTICATED || status === UNAUTHORIZED) {
    if (isInDevelopment)
      console.log(`Error ${UNAUTHENTICATED || UNAUTHORIZED}`);
    //dispatch action logout
  }
  return Promise.reject(error);
};
