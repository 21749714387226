import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import { Form, Formik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import Div from '@jumbo/shared/Div';

import { classSectionSubjectSchema } from '../../utils/schema';
import JumboDropDown from '@jumbo/components/JumboFormik/JumboDropDown';
import { GENERIC_YES_NO } from 'app/shared/constants';
import {
  addNewClassSectionSubject,
  editClassSectionSubject,
  getAllSubjects,
} from 'app/store/features/subjectsSlice';
import { getAllClasses } from 'app/store/features/classesSlice';
import { getAllUsers } from 'app/store/features/usersSlice';

const ClassSectionSubjectForm = ({ sectionData }) => {
  const dispatch = useDispatch();
  const { hideDialog } = useJumboDialog();

  const storeClasses = useSelector(state => state.classes.classes);
  const isStoreClassesFetched = useSelector(
    state => state.classes.isDataFetched,
  );
  const storeSubjects = useSelector(state => state.subjects.subjects);
  const isSubjectsFetched = useSelector(state => state.subjects.isDataFetched);

  const storeUsers = useSelector(state => state.users.users);
  const isStoreUsersFetched = useSelector(state => state.users.isDataFetched);

  const fetchData = useCallback(() => {
    if (!isStoreClassesFetched) dispatch(getAllClasses());
    if (!isSubjectsFetched) dispatch(getAllSubjects());
    if (!isStoreUsersFetched) dispatch(getAllUsers());
  }, [dispatch, isStoreClassesFetched, isStoreUsersFetched, isSubjectsFetched]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const isUpdate = sectionData ? true : false;
  const initialValues = {
    class_section_id: sectionData ? sectionData.class_section_id : '',
    subject_id: sectionData ? sectionData.subject_id : '',
    teacher_id: sectionData ? sectionData.teacher_id : '',
    max_marks: sectionData ? sectionData.max_marks : '',
    passing_percentage: sectionData ? sectionData.passing_percentage : '',
    is_optional: sectionData ? (sectionData.is_optional ? 'yes' : 'no') : 'yes',
    student_paper_review: sectionData
      ? sectionData.student_paper_review
        ? 'yes'
        : 'no'
      : 'yes',
    status: sectionData ? (sectionData.status ? 'yes' : 'no') : 'yes',
  };

  const addClassHandler = data => {
    data.is_optional = data.is_optional.toLowerCase() === 'yes' ? true : false;
    data.student_paper_review =
      data.student_paper_review.toLowerCase() === 'yes' ? true : false;
    data.status = data.status.toLowerCase() === 'yes' ? true : false;

    dispatch(addNewClassSectionSubject(data));
    hideDialog();
  };

  const updateClassHandler = data => {
    data.is_optional = data.is_optional.toLowerCase() === 'yes' ? true : false;
    data.student_paper_review =
      data.student_paper_review.toLowerCase() === 'yes' ? true : false;
    data.status = data.status.toLowerCase() === 'yes' ? true : false;
    dispatch(editClassSectionSubject(data));
    hideDialog();
  };

  const onClassSave = data => {
    isUpdate ? updateClassHandler(data) : addClassHandler(data);
  };
  return (
    <Formik
      validateOnChange={true}
      initialValues={sectionData?.id ? sectionData : initialValues}
      enableReinitialize={true}
      validationSchema={classSectionSubjectSchema}
      onSubmit={onClassSave}>
      {({ isSubmitting }) => (
        <Form noValidate autoComplete="off">
          <Div
            sx={{
              '& .MuiTextField-root': {
                mb: 3,
              },
            }}>
            <JumboDropDown
              fullWidth
              size="small"
              variant="outlined"
              name="class_section_id"
              label="Please Select Class Section"
              options={storeClasses}
            />
            <JumboDropDown
              fullWidth
              size="small"
              variant="outlined"
              name="subject_id"
              label="Please Select Subject"
              options={storeSubjects}
            />
            <JumboDropDown
              fullWidth
              size="small"
              variant="outlined"
              name="teacher_id"
              label="Please Select Teacher"
              options={storeUsers?.filter(item => item.role === 'teacher')}
            />
            <JumboTextField
              fullWidth
              size="small"
              variant="outlined"
              name="max_marks"
              label="Max Marks"
              type="number"
            />
            <JumboTextField
              fullWidth
              size="small"
              variant="outlined"
              name="passing_percentage"
              label="Passing Percentage"
              type="number"
            />
            <JumboDropDown
              fullWidth
              size="small"
              variant="outlined"
              name="is_optional"
              label="Is Class Section Subject Optional?"
              options={GENERIC_YES_NO}
            />
            <JumboDropDown
              fullWidth
              size="small"
              variant="outlined"
              name="student_paper_review"
              label="Is Student Paper Review Allowed?"
              options={GENERIC_YES_NO}
            />

            <JumboDropDown
              fullWidth
              size="small"
              variant="outlined"
              name="status"
              label="Is Class Section Subject Active(Status)?"
              options={GENERIC_YES_NO}
            />

            <Div
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-around',
              }}>
              <LoadingButton
                type="submit"
                variant="contained"
                size="large"
                sx={{
                  mb: 3,
                  backgroundColor: '#E44A77',
                  ':hover': {
                    backgroundColor: '#e44a77',
                  },
                }}
                onClick={() => hideDialog()}>
                Close
              </LoadingButton>
              <LoadingButton
                type="submit"
                variant="contained"
                size="large"
                sx={{ mb: 3 }}
                loading={isSubmitting}>
                Save
              </LoadingButton>
            </Div>
          </Div>
        </Form>
      )}
    </Formik>
  );
};

export default ClassSectionSubjectForm;
