import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';

import { getAllFeeItems } from 'app/store/features/feeSlice';
import { getFullName, hasPermission, isSuperAdmin } from 'app/utils/appHelpers';
import { Box, Stack, TextField, Tooltip } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import JumboChipsGroup from '@jumbo/components/JumboChipsGroup';
import { getAllBranches } from 'app/store/features/branchSlice';
import StyledAutocomplete from 'app/shared/components/Autocomplete';
import { PERMISSIONS, PERMISSION_TYPES } from 'app/utils/constants/permissions';
import { FEE_STATUS, MONTHS } from 'app/shared/constants';
import { generateYears } from 'app/utils/appHelpers';
import { getAllClasses } from 'app/store/features/classesSlice';

import DeleteFee from '../DeleteFee';
import { formatDate } from '@jumbo/utils';

const FeeList = () => {
  const dispatch = useDispatch();
  const { showDialog } = useJumboDialog();

  const fee = useSelector(state => state.fee.feeItems);
  const isFeeFetched = useSelector(state => state.fee.isFeeItemsFetched);

  const branches = useSelector(state => state.branches.branches);
  const isBranchesFetched = useSelector(state => state.branches.isDataFetched);

  const isClassesFetched = useSelector(state => state.classes.isDataFetched);
  const storeClasses = useSelector(state => state.classes.classes);

  const [selectionModel, setSelectionModel] = useState([]);
  const [classes, setClasses] = useState(storeClasses);
  const [dataToShow, setDataToShow] = useState(fee);

  // Filters
  const [searchText, setSearchText] = useState('');
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedClass, setSelectedClass] = useState(null);

  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);

  const YEARS = generateYears();

  const handleSearchChange = event => {
    setSearchText(event.target.value);
  };

  useEffect(() => {
    if (!isFeeFetched) {
      dispatch(getAllFeeItems({ descOrder: true }));
    }
    if (!isBranchesFetched) {
      dispatch(getAllBranches());
    }

    if (!isClassesFetched) {
      dispatch(getAllClasses());
    }
  }, [dispatch, isFeeFetched, isBranchesFetched, isClassesFetched]);

  useEffect(() => {
    setClasses(storeClasses);
  }, [storeClasses]);

  useEffect(() => {
    if (!fee?.length) return;
    let filteredFees = fee;

    if (selectedBranch?.id) {
      filteredFees = filteredFees?.filter(
        fee => fee?.student?.branch_id === selectedBranch?.id,
      );
    }

    if (year) {
      filteredFees = filteredFees?.filter(fee => fee?.year === year.value);
    }

    if (month) {
      filteredFees = filteredFees?.filter(fee => fee?.month === month.value);
    }

    if (searchText) {
      filteredFees = filteredFees.filter(fee =>
        getFullName(fee.student)
          .toLowerCase()
          .includes(searchText.toLowerCase()),
      );
    }

    if (selectedClass) {
      filteredFees = filteredFees?.filter(
        fee => fee?.student?.class_section_id === selectedClass?.id,
      );
    }

    const updatedFeeUpdate = [];
    filteredFees.forEach(data => {
      const { fee_updates: feeUpdate, student } = data;

      const localupdatedFeeUpdate = feeUpdate.map(feeObj => {
        return { ...feeObj, student }; // Merging feeObj with student
      });
      updatedFeeUpdate.push(...localupdatedFeeUpdate);
    });

    setDataToShow(updatedFeeUpdate);
  }, [fee, searchText, selectedBranch, year, month, selectedClass]);

  const deleteFeeDialog = fee => {
    showDialog({
      title: 'Delete Fee Update',
      content: <DeleteFee fee={fee} />,
      disableDefaultClose: true,
    });
  };

  const columns = [
    {
      field: 'student_code',
      headerName: 'Student Code',
      sortable: false,
      width: 110,
      renderCell: ({ row }) => <>{row.student.student_code}</>,
    },
    {
      field: 'student',
      headerName: 'Name',
      sortable: false,
      width: 400,
      renderCell: ({ value }) => (
        <Tooltip
          title={getFullName({
            first_name: value?.first_name,
            last_name: value?.last_name,
            father_name: value?.father_name,
          })}>
          {getFullName({
            first_name: value?.first_name,
            last_name: value?.last_name,
            father_name: value?.father_name,
          })}
        </Tooltip>
      ),
    },

    {
      field: 'class',
      headerName: 'Class',
      description: 'Class Name',
      sortable: false,
      width: 110,
      renderCell: ({ row }) => {
        const studentClass = storeClasses.find(
          item => item.id === row?.student?.class_section_id,
        );

        return <>{studentClass?.class}</>;
      },
    },

    {
      field: 'paid_amount',
      headerName: 'Paid',
      description: 'Paid Amount',
      sortable: false,
      width: 110,
      renderCell: ({ value }) => <>{value} Rs.</>,
    },
    {
      field: 'total',
      headerName: 'Total',
      description: 'Total Amount',
      sortable: false,
      width: 110,
      renderCell: ({ value }) => <>{value} Rs.</>,
    },
    {
      field: 'balance',
      headerName: 'CPB',
      description: 'Current Payable Amount',
      sortable: false,
      width: 100,
      renderCell: ({ value, row }) => (
        <>{row?.status === 'pending' && value === 0 ? row?.total : value} Rs.</>
      ),
    },
    {
      field: 'received_by',
      headerName: 'Account',
      sortable: false,
      width: 80,
      renderCell: ({ value }) => (
        <Tooltip
          title={
            value
              ? `${value?.name} ${formatDate(value?.updatedAt, 'DD-MMMM-YYYY hh:mm A')}`
              : '-'
          }>
          {value
            ? `${value?.name} ${formatDate(value?.updatedAt, 'DD-MMMM-YYYY hh:mm A')}`
            : '-'}
        </Tooltip>
      ),
    },

    {
      field: 'id',
      headerName: 'Receipt#',
      sortable: false,
      width: 80,
      renderCell: ({ value }) => {
        const label = [{ label: value, color: FEE_STATUS[value] }];
        return (
          <Box pt={1.5}>
            <JumboChipsGroup
              chips={label}
              mapKeys={{ label: 'name' }}
              spacing={1}
              size={'small'}
              max={1}
            />
          </Box>
        );
      },
    },
    {
      field: 'action',
      headerName: 'Actions',
      headerWidth: 370,
      sortable: false,
      width: 100,

      renderCell: ({ row }) => {
        return (
          <Stack direction={'row'} gap={1} pt={1.5}>
            {isSuperAdmin() && (
              <LoadingButton
                disabled={!isSuperAdmin()}
                variant="contained"
                size="small"
                onClick={e => {
                  e.stopPropagation();
                  deleteFeeDialog(row);
                }}>
                Delete
              </LoadingButton>
            )}
          </Stack>
        );
      },
    },
  ];

  return (
    <Box width={1} height={1}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="end"
        mb={2}>
        <Stack direction="column" gap={2}>
          <Stack direction="row" gap={1} alignItems="end">
            <TextField
              fullWidth
              label="Search by Student Name"
              variant="outlined"
              size="small"
              name="search"
              value={searchText}
              onChange={handleSearchChange}
              sx={{
                minWidth: 200,
              }}
            />

            <StyledAutocomplete
              options={YEARS}
              value={year}
              label="Year"
              size="small"
              variant="outlined"
              onChange={(_, val) => setYear(val)}
              renderInput={params => (
                <TextField {...params} variant="outlined" placeholder="Year" />
              )}
              sx={{ minWidth: 120, maxWidth: 120 }}
            />

            <StyledAutocomplete
              options={MONTHS}
              value={month}
              label="Month"
              size="small"
              variant="outlined"
              onChange={(_, val) => setMonth(val)}
              renderInput={params => (
                <TextField {...params} variant="outlined" placeholder="Month" />
              )}
              sx={{ minWidth: 150, maxWidth: 150 }}
            />
          </Stack>
          <Stack direction="row" gap={1} alignItems="end">
            {hasPermission(
              [PERMISSIONS.BRANCH],
              [PERMISSION_TYPES.LISTING],
            ) && (
              <StyledAutocomplete
                fullWidth
                size="small"
                variant="outlined"
                name="branch_id"
                label="Class"
                getOptionLabel={option => option.name}
                renderOption={(props, option) => (
                  <li {...props}>{option.name}</li>
                )}
                options={branches}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Select Branch"
                  />
                )}
                sx={{
                  minWidth: 250,
                  maxWidth: 250,
                }}
                onChange={(_, val) => {
                  if (val) {
                    const filteredClasses = classes?.filter(
                      item => item?.branch?.id === val?.id,
                    );
                    setClasses(filteredClasses);
                  } else {
                    setClasses(storeClasses);
                  }

                  setSelectedBranch(val);
                }}
              />
            )}

            {hasPermission(
              [PERMISSIONS.CLASS_SECTION],
              [PERMISSION_TYPES.LISTING],
            ) && (
              <StyledAutocomplete
                fullWidth
                size="small"
                variant="outlined"
                name="class"
                label="Class Name"
                getOptionLabel={option => option.class}
                renderOption={(props, option) => (
                  <li {...props}>{option.class}</li>
                )}
                options={classes}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Select Class"
                  />
                )}
                sx={{
                  minWidth: 250,
                  maxWidth: 250,
                }}
                onChange={(_, val) => {
                  setSelectedClass(val);
                }}
              />
            )}
          </Stack>
        </Stack>
      </Stack>
      <Box width={'100%'} height={400}>
        <DataGrid
          getRowId={({ id }) => id}
          columns={columns}
          rows={dataToShow}
          disableColumnResize={true}
          checkboxSelection
          keepNonExistentRowsSelected
          onRowSelectionModelChange={itm => setSelectionModel(itm)}
          selectionModel={selectionModel}
          autoHeight
          loading={!isFeeFetched}
          sx={{
            boxShadow: 2,
            borderRadius: 2,
            '& .MuiDataGrid-cell:hover': {
              color: 'primary.main',
            },
            overflowX: 'auto',
            minHeight: 400,
          }}
        />
      </Box>
    </Box>
  );
};

export default FeeList;
