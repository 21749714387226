import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';

import { hasPermission } from 'app/utils/appHelpers';
import { Box, Button, Stack, TextField } from '@mui/material';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';

import { getAllBranches } from 'app/store/features/branchSlice';
import StyledAutocomplete from 'app/shared/components/Autocomplete';

import { PERMISSIONS, PERMISSION_TYPES } from 'app/utils/constants/permissions';
import { EXPENSE_STATUS } from 'app/shared/constants';

import { getExpenseList } from 'app/store/features/reportSlice';
import ExpenseReport from '@jumbo/components/Pdf/Report/ExpenseReports';
import { formatDate } from '@jumbo/utils';

const ExpenseReportList = () => {
  const dispatch = useDispatch();
  const { showDialog } = useJumboDialog();

  const feeList = useSelector(state => state.report.expenseList);
  const isFeeListFetched = useSelector(
    state => state.report.isExpenseListFetched,
  );

  const branches = useSelector(state => state.branches.branches);
  const isBranchesFetched = useSelector(state => state.branches.isDataFetched);

  const [selectionModel, setSelectionModel] = useState([]);
  const [dataToShow, setDataToShow] = useState(feeList);
  const [dataKey, setDataKey] = useState(false);

  // Filters
  const [searchText, setSearchText] = useState('');
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [status, setStatus] = useState(null);
  const [toDate, setToDate] = useState(formatDate(new Date(), 'YYYY-MM-DD'));
  const [fromDate, setFromDate] = useState(
    formatDate(new Date(), 'YYYY-MM-DD'),
  );

  const handleSearchChange = event => setSearchText(event.target.value);

  useEffect(() => {
    if (!isBranchesFetched) {
      dispatch(getAllBranches());
    }
  }, [dispatch, isBranchesFetched]);

  useEffect(() => {
    if (!isFeeListFetched) dispatch(getExpenseList());
  }, [dispatch, isFeeListFetched]);

  useEffect(() => {
    if (!feeList?.length) return;
    let filteredFees = feeList;

    if (status) {
      const boolStatus = status === 'active';
      filteredFees = filteredFees.filter(fee => fee.status === boolStatus);
    }

    if (searchText) {
      filteredFees = filteredFees.filter(
        fee =>
          fee.title?.toLowerCase().includes(searchText.toLowerCase()) ||
          fee.expense_category?.title
            .toLowerCase()
            .includes(searchText.toLowerCase()),
      );
    }

    setDataToShow(filteredFees);
  }, [status, searchText, feeList, selectedBranch]);

  const showFeeDetails = expense => {
    showDialog({
      title: <p />,
      content: <ExpenseReport expenses={expense} />,
      showCloseButton: true,
      width: 1000,
    });
    setSelectionModel([]);
    setDataKey(!dataKey);
  };

  const columns = [
    {
      field: 'title',
      headerName: 'Title',
      sortable: false,
      width: 200,
      renderCell: ({ value }) => <>{value}</>,
    },

    {
      field: 'detail',
      headerName: 'Detail',
      sortable: false,
      width: 400,
      renderCell: ({ value }) => <>{value}</>,
    },

    {
      field: 'is_compulsory_expense',
      headerName: 'Is Compulsory',
      description: 'Is Item Compulsory',
      sortable: false,
      width: 120,
      renderCell: ({ value }) => <>{value ? 'Yes' : 'No'}</>,
    },

    {
      field: 'expense_category',
      headerName: 'Expense Category',
      sortable: false,
      width: 200,
      renderCell: ({ value }) => <>{value.title}</>,
    },

    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      width: 120,
      renderCell: ({ value }) => <>{value ? 'Active' : 'Inactive'}</>,
    },
  ];

  return (
    <Box width={1} height={1}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="start"
        mb={2}>
        <Stack direction="column" gap={2}>
          <Stack direction="row" gap={1} alignItems="end">
            <TextField
              fullWidth
              label="Search..."
              variant="outlined"
              size="small"
              name="search"
              value={searchText}
              onChange={handleSearchChange}
              sx={{ minWidth: 220 }}
            />
            <TextField
              fullWidth
              label="From Date"
              variant="outlined"
              size="small"
              type="date"
              value={fromDate}
              InputLabelProps={{ shrink: true }}
              onChange={e => {
                setFromDate(e.target.value);
                if (new Date(toDate) < new Date(e.target.value)) {
                  setToDate(e.target.value);
                }
              }}
              sx={{ minWidth: 190 }}
            />
            <TextField
              fullWidth
              label="To Date"
              variant="outlined"
              size="small"
              type="date"
              value={toDate}
              InputLabelProps={{ shrink: true }}
              inputProps={{ min: fromDate }}
              onChange={e => setToDate(e.target.value)}
              sx={{ minWidth: 190 }}
            />
          </Stack>

          <Stack direction="row" gap={1} alignItems="end">
            {hasPermission(
              [PERMISSIONS.BRANCH],
              [PERMISSION_TYPES.LISTING],
            ) && (
              <StyledAutocomplete
                fullWidth
                size="small"
                variant="outlined"
                name="branch_id"
                label="Class"
                getOptionLabel={option => option.name}
                renderOption={(props, option) => (
                  <li {...props}>{option.name}</li>
                )}
                options={branches}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Select Branch"
                  />
                )}
                sx={{
                  minWidth: 410,
                  maxWidth: 410,
                }}
                onChange={(_, val) => setSelectedBranch(val)}
              />
            )}

            <StyledAutocomplete
              fullWidth
              size="small"
              variant="outlined"
              getOptionLabel={option => option.name}
              renderOption={(props, option) => (
                <li {...props}>{option.name}</li>
              )}
              options={EXPENSE_STATUS}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Status"
                />
              )}
              sx={{
                minWidth: 200,
                maxWidth: 200,
              }}
              onChange={(_, val) => setStatus(val?.id)}
            />
          </Stack>
        </Stack>
        <Stack>
          {hasPermission([PERMISSIONS.FEE], [PERMISSION_TYPES.ADD]) && (
            <Button
              fullWidth
              disableElevation
              disabled={!selectionModel.length}
              variant="contained"
              sx={{
                mb: 2,
                '& .MuiSvgIcon-root': {
                  fontSize: '1.5rem',
                },
              }}
              onClick={() => {
                const selectedData = feeList
                  .filter(item => selectionModel.includes(item.id))
                  .map(item => {
                    const branch = branches?.find(
                      branch => branch?.id === item?.student?.branch_id,
                    );
                    return { ...item, branch };
                  });

                showFeeDetails(selectedData);
              }}>
              Details
            </Button>
          )}
        </Stack>
      </Stack>
      <Box width={'100%'} height={400}>
        <DataGrid
          key={dataKey}
          getRowId={({ id }) => id}
          columns={columns}
          rows={dataToShow}
          disableColumnResize={true}
          checkboxSelection
          keepNonExistentRowsSelected
          onRowSelectionModelChange={itm => setSelectionModel(itm)}
          selectionModel={selectionModel}
          autoHeight
          loading={!isFeeListFetched}
          sx={{
            boxShadow: 2,
            borderRadius: 2,
            '& .MuiDataGrid-cell:hover': {
              color: 'primary.main',
            },
            overflowX: 'auto',
            minHeight: 400,
          }}
        />
      </Box>
    </Box>
  );
};

export default ExpenseReportList;
