import React from 'react';

import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import Div from '@jumbo/shared/Div';
import BranchesList from 'app/shared/components/BranchesList';
import { useNavigate } from 'react-router-dom';
import { hasPermission } from 'app/utils/appHelpers';
import { PERMISSIONS, PERMISSION_TYPES } from 'app/utils/constants/permissions';

const ExamInitiatorSidebar = () => {
  const navigate = useNavigate();

  return (
    <>
      <Div
        sx={{
          display: 'flex',
          alignItems: 'center',
          mb: 3,
        }}>
        <Div sx={{ flex: '1 1 auto' }}>
          <Typography variant={'h2'} mb={0.35}>
            Examination Initiator
          </Typography>
        </Div>
      </Div>
      {hasPermission([PERMISSIONS.EXAM_INITIATOR], [PERMISSION_TYPES.ADD]) && (
        <Button
          fullWidth
          disableElevation
          variant={'contained'}
          startIcon={<PersonAddIcon />}
          sx={{
            mb: 2,
            '& .MuiSvgIcon-root': {
              fontSize: '1.5rem',
            },
          }}
          onClick={() => navigate('/app/examination/initiator')}>
          Exam Initiator
        </Button>
      )}

      <BranchesList title="All Exam Initiators" identifier="initiators" />
    </>
  );
};
export default React.memo(ExamInitiatorSidebar);
