import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import SubjectsSidebar from './components/SubjectSidebar';
import SubjectsList from './components/SubjectsList';
import { getAllSubjects } from 'app/store/features/subjectsSlice';
import { hasPermission } from 'app/utils/appHelpers';
import { PERMISSIONS, PERMISSION_TYPES } from 'app/utils/constants/permissions';

const Subjects = () => {
  const dispatch = useDispatch();
  const isStoreSubjectsFetched = useSelector(
    state => state.subjects.isDataFetched,
  );

  const fetchData = useCallback(async () => {
    if (!isStoreSubjectsFetched) {
      dispatch(getAllSubjects());
    }
  }, [dispatch, isStoreSubjectsFetched]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <JumboContentLayout
        sidebar={
          hasPermission(
            [PERMISSIONS.CLASS_SECTION_SUBJECTS],
            [PERMISSION_TYPES.ADD],
          ) && <SubjectsSidebar />
        }>
        {hasPermission(
          [PERMISSIONS.CLASS_SECTION_SUBJECTS],
          [PERMISSION_TYPES.LISTING],
        ) && <SubjectsList />}
      </JumboContentLayout>
    </>
  );
};
export default Subjects;
