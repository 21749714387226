import { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';
import { Card } from '@mui/material';

import JumboRqList from '@jumbo/components/JumboReactQuery/JumboRqList';
import JumboListToolbar from '@jumbo/components/JumboList/components/JumboListToolbar';
import JumboSearch from '@jumbo/components/JumboSearch';

import ExamItem from './Item';

const ExamInitiatorList = () => {
  const { category } = useParams();
  const listRef = useRef();
  const examInitiators = useSelector(state => state.examination.examInitiators);

  const [examInitiatorsToShow, setExamInitiatorsToShow] =
    useState(examInitiators);

  useEffect(() => {
    setExamInitiatorsToShow(examInitiators);
  }, [examInitiators]);

  useEffect(() => {
    if (category === 'all') {
      setExamInitiatorsToShow(examInitiators);
    } else {
      const filtered = examInitiators?.filter(item => {
        const branchName = item.branch.name;
        if (category === branchName?.toLowerCase()) {
          return item;
        }
        return null;
      });
      setExamInitiatorsToShow(filtered);
    }
  }, [category, examInitiators]);

  const renderData = useCallback(item => {
    return <ExamItem item={item} view={'list'} />;
  }, []);

  const handleOnChange = useCallback(
    keyword => {
      let filtered = [];
      filtered = examInitiators?.filter(item => {
        return item?.name?.toLowerCase().search(keyword?.toLowerCase()) !== -1;
      });
      setExamInitiatorsToShow(filtered);
    },
    [examInitiators],
  );

  return (
    <JumboRqList
      ref={listRef}
      data={examInitiatorsToShow}
      wrapperComponent={Card}
      primaryKey={'id'}
      itemsPerPage={8}
      itemsPerPageOptions={[8, 15, 20]}
      renderItem={renderData}
      componentElement={'div'}
      // sx={view === 'grid' && { p: theme => theme.spacing(1, 3, 3) }}
      wrapperSx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
      toolbar={
        <JumboListToolbar hideItemsPerPage={true}>
          <JumboSearch
            onChange={handleOnChange}
            sx={{
              display: { xs: 'none', md: 'block' },
            }}
          />
        </JumboListToolbar>
      }
      view={'list'}
    />
  );
};

export default ExamInitiatorList;
