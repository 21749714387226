import { Tooltip, Typography } from '@mui/material';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';

import Div from '@jumbo/shared/Div';

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <Tooltip>
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    </Tooltip>
  );
};

const FeeStats = ({ dataToShow }) => {
  const results = [
    {
      name: 'Paid Fee',
      value: dataToShow.paidFee,
      color: 'rgb(59 210 162)',
    },
    {
      name: 'Remaining Fee',
      value: dataToShow.remainingFee,
      color: 'rgb(233 30 99)',
    },
    {
      name: 'Discounts',
      value: dataToShow.discount,
      color: '#1e88e5',
    },
  ];

  return (
    <>
      <ResponsiveContainer width="100%" height={200}>
        <PieChart>
          <Pie
            dataKey="value"
            data={results}
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={80}
            fill={'#1e88e5'}>
            {results.map((entry, index) => (
              <Cell key={index} fill={entry.color} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <Div
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around',
        }}>
        <Div sx={{ display: 'flex', alignItems: 'center' }}>
          <span
            style={{
              padding: '7px 10px',
              marginRight: 5,
              backgroundColor: 'rgb(59 210 162)',
            }}></span>
          <Typography>Paid Fee</Typography>
        </Div>
        <Div sx={{ display: 'flex', alignItems: 'center' }}>
          <span
            style={{
              padding: '7px 10px',
              marginRight: 5,
              backgroundColor: 'rgb(233 30 99)',
            }}></span>
          <Typography>Remaining Fee</Typography>
        </Div>
        <Div sx={{ display: 'flex', alignItems: 'center' }}>
          <span
            style={{
              padding: '7px 10px',
              marginRight: 5,
              backgroundColor: '#1e88e5',
            }}></span>
          <Typography>Fee Discounts</Typography>
        </Div>
      </Div>
    </>
  );
};

export default FeeStats;
