import * as yup from 'yup';

export const schema = yup.object().shape({
  user_id: yup
    .number()
    .positive()
    .integer()
    .required()
    .label('Assigned To User'),
  description: yup.string().max(500).required().label('Description'),
  due_date: yup.date().required().label('Due Date'),
  status: yup.string().required().label('Status'),
});
