import React from 'react';
import { useDispatch } from 'react-redux';

import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemAvatar,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Tooltip,
  Typography,
  Avatar,
  Stack,
  Grid,
} from '@mui/material';
import {
  Person,
  MoreHoriz,
  Edit,
  Delete,
  AcUnit,
  Money,
  Book,
  FollowTheSigns,
  Message,
} from '@mui/icons-material';
import styled from '@emotion/styled';

import JumboDdMenu from '@jumbo/components/JumboDdMenu';
import Span from '@jumbo/shared/Span';
import JumboListItem from '@jumbo/components/JumboList/components/JumboListItem';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import JumboGridItem from '@jumbo/components/JumboList/components/JumboGridItem';

import ItemDetails from './ItemDetails';
import { useNavigate } from 'react-router-dom';
import { deleteExamFollowUp } from 'app/store/features/examinationSlice';
import { hasPermission } from 'app/utils/appHelpers';
import { PERMISSIONS, PERMISSION_TYPES } from 'app/utils/constants/permissions';

const Item = styled(Span)(({ theme }) => ({
  minWidth: 0,
  flexGrow: 0,
  padding: theme.spacing(0, 1),
}));

const ExamItem = ({ item, view }) => {
  const { showDialog, hideDialog } = useJumboDialog();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const showContactDetail = React.useCallback(() => {
    showDialog({
      content: <ItemDetails item={item} onClose={hideDialog} />,
    });
  }, [showDialog, item, hideDialog]);

  const handleDelete = data => {
    dispatch(deleteExamFollowUp(data.id));
    hideDialog();
  };

  const handleItemAction = menuItem => {
    switch (menuItem.action) {
      case 'view':
        showContactDetail();
        break;
      case 'edit':
        navigate('/app/examination/followup', { state: item });
        break;
      case 'delete':
        showDialog({
          variant: 'confirm',
          title: 'Are you sure about deleting this Exam Followup?',
          content: "You won't be able to recover this Exam Followup later",
          onYes: () => handleDelete(item),
          onNo: hideDialog,
        });
        break;
      default:
        break;
    }
  };

  if (view === 'grid') {
    return (
      <JumboGridItem xs={12} md={6}>
        <Card variant="outlined" elevation={0}>
          <CardHeader
            avatar={
              <Avatar
                sx={{ width: 48, height: 48 }}
                alt={item?.checker?.name}
                src={item?.profile_pic}
              />
            }
            action={
              <>
                <JumboDdMenu
                  icon={<MoreHoriz />}
                  menuItems={[
                    { icon: <Edit />, title: 'Edit', action: 'edit' },
                    { icon: <Delete />, title: 'Delete', action: 'delete' },
                  ]}
                  onClickCallback={handleItemAction}
                />
              </>
            }
            title={
              <Tooltip title="Checker">
                <Typography variant={'h6'} color={'text.primary'} mb={0.25}>
                  {item?.checker?.username}
                </Typography>
              </Tooltip>
            }
            subheader={
              <Tooltip title="Invigilator">
                <Typography variant={'body1'} color={'text.secondary'}>
                  {item?.invigilator?.username}
                </Typography>
              </Tooltip>
            }
          />
          <CardContent sx={{ pt: 0 }}>
            <Divider sx={{ mb: 2 }} />
            <Grid container>
              <Grid item xs={12} sm={6}>
                <List disablePadding>
                  {/* exam types/terms */}
                  <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                    <ListItemIcon sx={{ minWidth: 50 }}>
                      <Money />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Tooltip title="Exam Type">
                          <Typography variant={'body1'} color={'text.primary'}>
                            {item?.exam_type}
                          </Typography>
                        </Tooltip>
                      }
                    />
                  </ListItem>

                  <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                    <ListItemIcon sx={{ minWidth: 50 }}>
                      <Book />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Tooltip title="Exam Term">
                          <Typography variant={'body1'}>
                            {item?.exam_term}
                          </Typography>
                        </Tooltip>
                      }
                    />
                  </ListItem>

                  {/* users */}
                  <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                    <ListItemIcon sx={{ minWidth: 50 }}>
                      <Person />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Tooltip title="Al Final Reviewer">
                          <Typography variant={'body1'}>
                            {item?.al_final_rev?.username}
                          </Typography>
                        </Tooltip>
                      }
                    />
                  </ListItem>
                  <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                    <ListItemIcon sx={{ minWidth: 50 }}>
                      <Person />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Tooltip title="Al Initiator">
                          <Typography variant={'body1'}>
                            {item?.al_initiator?.username}
                          </Typography>
                        </Tooltip>
                      }
                    />
                  </ListItem>

                  <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                    <ListItemIcon sx={{ minWidth: 50 }}>
                      <Person />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Tooltip title="Al second Initiator">
                          <Typography variant={'body1'}>
                            {item?.al_second_initiator?.username}
                          </Typography>
                        </Tooltip>
                      }
                    />
                  </ListItem>

                  <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                    <ListItemIcon sx={{ minWidth: 50 }}>
                      <Person />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Tooltip title="Al Verifier">
                          <Typography variant={'body1'}>
                            {item?.al_verifier?.username}
                          </Typography>
                        </Tooltip>
                      }
                    />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                  <ListItemIcon sx={{ minWidth: 50 }}>
                    <AcUnit />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Tooltip title="Exam Hall">
                        <Typography variant={'body1'}>
                          {item?.exam_hall}
                        </Typography>
                      </Tooltip>
                    }
                  />
                </ListItem>

                <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                  <ListItemIcon sx={{ minWidth: 50 }}>
                    <Book />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Tooltip title="Exam Status Active?">
                        <Typography variant={'body1'}>
                          {item?.exam_status ? 'Yes' : 'No'}
                        </Typography>
                      </Tooltip>
                    }
                  />
                </ListItem>

                <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                  <ListItemIcon sx={{ minWidth: 50 }}>
                    <Person />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Tooltip title="Rechecker">
                        <Typography variant={'body1'}>
                          {item?.rechecker?.username}
                        </Typography>
                      </Tooltip>
                    }
                  />
                </ListItem>
                {/* remarks_status */}
                <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                  <ListItemIcon sx={{ minWidth: 50 }}>
                    <Message />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Tooltip title="Remarks Status">
                        <Typography variant={'body1'}>
                          {item?.remarks_status ? 'Yes' : 'No'}
                        </Typography>
                      </Tooltip>
                    }
                  />
                </ListItem>
                {/* remarks_initiator */}
                <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                  <ListItemIcon sx={{ minWidth: 50 }}>
                    <FollowTheSigns />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Tooltip title="Remarks Initiator">
                        <Typography variant={'body1'}>
                          {item?.remarks_initiator?.username}
                        </Typography>
                      </Tooltip>
                    }
                  />
                </ListItem>
                {/* shift */}
                <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                  <ListItemIcon sx={{ minWidth: 50 }}>
                    <FollowTheSigns />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Tooltip title="Remarks Final Reviewer">
                        <Typography variant={'body1'}>
                          {item?.remarks_final_rev?.username}
                        </Typography>
                      </Tooltip>
                    }
                  />
                </ListItem>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </JumboGridItem>
    );
  }
  return (
    <JumboListItem
      componentElement={'div'}
      itemData={item}
      secondaryAction={
        hasPermission(
          [PERMISSIONS.EXAM_TASK_FOLLOWUP],
          [PERMISSION_TYPES.EDIT, PERMISSION_TYPES.DELETE],
        ) && (
          <JumboDdMenu
            icon={<MoreHoriz />}
            menuItems={[
              hasPermission(
                [PERMISSIONS.EXAM_TASK_FOLLOWUP],
                [PERMISSION_TYPES.EDIT],
              ) && { icon: <Edit />, title: 'Edit', action: 'edit' },
              hasPermission(
                [PERMISSIONS.EXAM_TASK_FOLLOWUP],
                [PERMISSION_TYPES.DELETE],
              ) && { icon: <Delete />, title: 'Delete', action: 'delete' },
            ]}
            onClickCallback={handleItemAction}
          />
        )
      }
      sx={{
        cursor: 'pointer',
        borderTop: 1,
        borderColor: 'divider',
        '&:hover': {
          bgcolor: 'action.hover',
        },
      }}>
      <ListItemAvatar onClick={showContactDetail}>
        <Avatar alt={item?.class_section_title} src={item?.profile_pic} />
      </ListItemAvatar>
      <ListItemText
        onClick={showContactDetail}
        primary={
          <Typography variant={'body1'} component={'div'}>
            <Stack direction={'row'} alignItems={'center'} sx={{ minWidth: 0 }}>
              <Item
                sx={{
                  flexBasis: { xs: '100%', sm: '50%', md: '25%' },
                }}>
                <Tooltip title="Exam Hall">
                  <Typography
                    variant={'h5'}
                    fontSize={14}
                    lineHeight={1.25}
                    mb={0}
                    noWrap>
                    {item?.exam_hall}
                  </Typography>
                </Tooltip>
              </Item>
              <Item
                sx={{
                  flexBasis: { sm: '50%', md: '28%' },
                  display: { xs: 'none', sm: 'block' },
                }}>
                <Tooltip title={'Checker'}>
                  <Typography variant={'body1'} noWrap>
                    {item?.checker?.username}
                  </Typography>
                </Tooltip>
              </Item>
              <Item
                sx={{
                  flexBasis: { sm: '50%', md: '28%' },
                  display: { xs: 'none', sm: 'block' },
                }}>
                <Tooltip title={'Invigilator'}>
                  <Typography variant={'body1'} noWrap>
                    {item?.invigilator?.username}
                  </Typography>
                </Tooltip>
              </Item>
              <Item
                sx={{
                  flexBasis: { sm: '50%', md: '28%' },
                  display: { xs: 'none', sm: 'block' },
                }}>
                <Tooltip title={'Rechecker'}>
                  <Typography variant={'body1'} noWrap>
                    {item?.rechecker?.username}
                  </Typography>
                </Tooltip>
              </Item>

              <Item
                sx={{
                  flexBasis: { md: '25%' },
                  display: { xs: 'none', md: 'block' },
                }}>
                <Tooltip title="Exam Type">
                  <Typography variant={'body1'} noWrap>
                    {item?.exam_type}
                  </Typography>
                </Tooltip>
              </Item>

              <Item
                sx={{
                  flexBasis: { md: '25%' },
                  display: { xs: 'none', md: 'block' },
                }}>
                <Tooltip title={'Exam Term'}>
                  <Typography variant={'body1'} noWrap>
                    {item?.exam_term}
                  </Typography>
                </Tooltip>
              </Item>
            </Stack>
          </Typography>
        }
      />
    </JumboListItem>
  );
};

export default ExamItem;
