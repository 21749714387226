import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import { getAllGroups } from 'app/store/features/configurationSlice';

import Sidebar from './components/Sidebar';
import List from './components/List';
import Loader from 'app/shared/components/Loader';
import { hasPermission } from 'app/utils/appHelpers';
import { PERMISSIONS, PERMISSION_TYPES } from 'app/utils/constants/permissions';

const Groups = () => {
  const dispatch = useDispatch();

  const { isGroupsFetched } = useSelector(state => state.configuration);

  useEffect(() => {
    if (!isGroupsFetched) dispatch(getAllGroups());
  }, [dispatch, isGroupsFetched]);

  if (!isGroupsFetched) {
    return <Loader />;
  }

  return (
    <JumboContentLayout
      sidebar={
        hasPermission([PERMISSIONS.GROUPS], [PERMISSION_TYPES.ADD]) && (
          <Sidebar />
        )
      }>
      {hasPermission([PERMISSIONS.GROUPS], [PERMISSION_TYPES.LISTING]) && (
        <List />
      )}
    </JumboContentLayout>
  );
};
export default Groups;
