import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  students: [
    {
      id: 1451,
      firstName: 'Anwar',
      lastName: 'Kabir',
      fatherName: 'Muhammad Kabir',
      fatherCnic: '35103-3510335-3',
      fatherQualification: 'BCS',
      fatherPhoneNumber: '0309-0909921',
      motherName: 'IDK',
      motherPhoneNumber: '0309-9898789',
      motherQualification: 'Intermediate',
      motherCnic: '35103-3510335-3',
      landline: 1234556,
      primaryContact: 'Father',
      cnic: '35103-3510335-3',
      district: 'Kasur',
      tehsil: 'Pattoki',
      village: 'Random',
      address: 'Hello World, its me mario',
      status: 'defaulter',
      branch: 'Pattoki',
      section: 'A',
      rollNo: 420,
      dob: 'Oct 22, 2001',
      age: 21,
      bloodGroup: 'B-',
      religion: 'Islam',
      nationality: 'Pakistani',
      prevSchool: 'FOTAN',
      noOfPrevSchool: 5,
      caution: 'null',
      medium: 'English',
      specialRemarks: 'A brilliant Student',
      recommendedFee: 9000,
      firstMonthFee: 2000,
      registerFeeDiscount: 1000,
      schoolTransport: false,
      isEmployeeChild: false,
      familyCode: 69,
      noOfSiblings: 5,
      paidFee: 5000,
      caste: '',
      labels: [{ label: 'Pattoki', color: '#12ab' }],
      feeHistory: [
        {
          id: 1,
          month: 'Jan',
          totalFee: 9000,
          discount: 500,
          paidFee: 8000,
          fee: 300,
        },
        {
          id: 2,
          month: 'Feb',
          totalFee: 4000,
          discount: 500,
          paidFee: 3000,
          fee: 200,
        },
        {
          id: 3,
          month: 'Mar',
          totalFee: 4000,
          discount: 500,
          paidFee: 3500,
          fee: 3100,
        },
        {
          id: 4,
          month: 'Apr',
          totalFee: 4000,
          discount: 500,
          paidFee: 3500,
          fee: 400,
        },
        {
          id: 5,
          month: 'May',
          totalFee: 4000,
          discount: 500,
          paidFee: 3500,
          fee: 100,
        },
        {
          id: 6,
          month: 'Jun',
          totalFee: 6000,
          discount: 500,
          paidFee: 5300,
          fee: 100,
        },
        {
          id: 7,
          month: 'Jul',
          totalFee: 4000,
          discount: 500,
          paidFee: 500,
          fee: 300,
        },
        {
          id: 8,
          month: 'Aug',
          totalFee: 4000,
          discount: 500,
          paidFee: 500,
          fee: 300,
        },
        {
          id: 9,
          month: 'Sep',
          totalFee: 4000,
          discount: 500,
          paidFee: 500,
          fee: 300,
        },
        {
          id: 10,
          month: 'Oct',
          totalFee: 4000,
          discount: 500,
          paidFee: 500,
          fee: 300,
        },
        {
          id: 11,
          month: 'Nov',
          totalFee: 4000,
          discount: 500,
          paidFee: 500,
          fee: 300,
        },
        {
          id: 12,
          month: 'Dec',
          totalFee: 4000,
          discount: 500,
          paidFee: 500,
          fee: 300,
        },
      ],
    },
    {
      id: 1452,
      firstName: 'Muhammad',
      lastName: 'Ali',
      fatherName: 'Muhammad Anwar',
      fatherCnic: '35103-3510335-3',
      fatherQualification: 'BCS',
      fatherPhoneNumber: '0309-0909921',
      motherName: 'IDK',
      motherPhoneNumber: '0309-9898789',
      motherQualification: 'Intermediate',
      motherCnic: '35103-3510335-3',
      landline: 1234556,
      primaryContact: 'Father',
      cnic: '35103-3510335-3',
      district: 'Kasur',
      tehsil: 'Pattoki',
      village: 'Random',
      address: 'Hello World, its me mario',
      status: 'cleared',
      branch: 'Gujranwala',
      section: 'A',
      rollNo: 420,
      dob: 'Oct 22, 2001',
      age: 21,
      bloodGroup: 'B-',
      religion: 'Islam',
      nationality: 'Pakistani',
      prevSchool: 'FOTAN',
      noOfPrevSchool: 5,
      caution: 'null',
      medium: 'English',
      specialRemarks: 'A brilliant Student',
      recommendedFee: 9000,
      firstMonthFee: 2000,
      registerFeeDiscount: 1000,
      schoolTransport: false,
      isEmployeeChild: false,
      familyCode: 69,
      paidFee: 2000,
      noOfSiblings: 5,
      caste: '',
      labels: [{ label: 'Gujranwala', color: '#23ab12' }],
      feeHistory: [
        {
          id: 1,
          month: 'Jan',
          totalFee: 10000,
          discount: 1000,
          paidFee: 4000,
          fee: 300,
        },
        {
          id: 2,
          month: 'Feb',
          totalFee: 4000,
          discount: 500,
          paidFee: 500,
          fee: 200,
        },
        {
          id: 3,
          month: 'Mar',
          totalFee: 4000,
          discount: 500,
          paidFee: 500,
          fee: 3100,
        },
        {
          id: 4,
          month: 'Apr',
          totalFee: 4000,
          discount: 500,
          paidFee: 500,
          fee: 400,
        },
        {
          id: 5,
          month: 'May',
          totalFee: 4000,
          discount: 500,
          paidFee: 500,
          fee: 100,
        },
        {
          id: 6,
          month: 'Jun',
          totalFee: 4000,
          discount: 500,
          paidFee: 500,
          fee: 100,
        },
        {
          id: 7,
          month: 'Jul',
          totalFee: 4000,
          discount: 500,
          paidFee: 500,
          fee: 300,
        },
        {
          id: 8,
          month: 'Aug',
          totalFee: 4000,
          discount: 500,
          paidFee: 500,
          fee: 300,
        },
        {
          id: 9,
          month: 'Sep',
          totalFee: 4000,
          discount: 500,
          paidFee: 500,
          fee: 300,
        },
        {
          id: 10,
          month: 'Oct',
          totalFee: 4000,
          discount: 500,
          paidFee: 500,
          fee: 300,
        },
        {
          id: 11,
          month: 'Nov',
          totalFee: 4000,
          discount: 500,
          paidFee: 500,
          fee: 300,
        },
        {
          id: 12,
          month: 'Dec',
          totalFee: 4000,
          discount: 500,
          paidFee: 500,
          fee: 300,
        },
      ],
    },
    {
      id: 145123,
      firstName: 'Anwar',
      lastName: 'Ali',
      fatherName: 'Ali',
      fatherCnic: '35103-3510335-3',
      fatherQualification: 'BCS',
      fatherPhoneNumber: '0309-0909921',
      motherName: 'IDK',
      motherPhoneNumber: '0309-9898789',
      motherQualification: 'Intermediate',
      motherCnic: '35103-3510335-3',
      landline: 1234556,
      primaryContact: 'Father',
      cnic: '35103-3510335-3',
      district: 'Kasur',
      tehsil: 'Pattoki',
      village: 'Random',
      address: 'Hello World, its me mario',
      status: 'partial',
      branch: 'Lahore',
      section: 'FA17',
      rollNo: 420,
      dob: 'Oct 22, 2001',
      age: 21,
      bloodGroup: 'B-',
      religion: 'Islam',
      nationality: 'Pakistani',
      prevSchool: 'FOTAN',
      noOfPrevSchool: 5,
      caution: 'null',
      medium: 'English',
      specialRemarks: 'A brilliant Student',
      recommendedFee: 9000,
      firstMonthFee: 2000,
      registerFeeDiscount: 1000,
      paidFee: 3000,
      schoolTransport: false,
      isEmployeeChild: false,
      familyCode: 69,
      noOfSiblings: 5,
      caste: '',
      labels: [{ label: 'Lahore', color: '#0F9AF7' }],
      feeHistory: [
        {
          id: 1,
          month: 'Jan',
          totalFee: 10000,
          discount: 1000,
          paidFee: 4000,
          fee: 300,
        },
        {
          id: 2,
          month: 'Feb',
          totalFee: 4000,
          discount: 500,
          paidFee: 500,
          fee: 200,
        },
        {
          id: 3,
          month: 'Mar',
          totalFee: 4000,
          discount: 500,
          paidFee: 500,
          fee: 3100,
        },
        {
          id: 4,
          month: 'Apr',
          totalFee: 4000,
          discount: 500,
          paidFee: 500,
          fee: 400,
        },
        {
          id: 5,
          month: 'May',
          totalFee: 4000,
          discount: 500,
          paidFee: 500,
          fee: 100,
        },
        {
          id: 6,
          month: 'Jun',
          totalFee: 4000,
          discount: 500,
          paidFee: 500,
          fee: 100,
        },
        {
          id: 7,
          month: 'Jul',
          totalFee: 4000,
          discount: 500,
          paidFee: 500,
          fee: 300,
        },
        {
          id: 8,
          month: 'Aug',
          totalFee: 4000,
          discount: 500,
          paidFee: 500,
          fee: 300,
        },
        {
          id: 9,
          month: 'Sep',
          totalFee: 4000,
          discount: 500,
          paidFee: 500,
          fee: 300,
        },
        {
          id: 10,
          month: 'Oct',
          totalFee: 4000,
          discount: 500,
          paidFee: 500,
          fee: 300,
        },
        {
          id: 11,
          month: 'Nov',
          totalFee: 4000,
          discount: 500,
          paidFee: 500,
          fee: 300,
        },
        {
          id: 12,
          month: 'Dec',
          totalFee: 4000,
          discount: 500,
          paidFee: 500,
          fee: 300,
        },
      ],
    },
  ],
};

export const feesSlice = createSlice({
  name: 'fees',
  initialState,
  reducers: {
    addFee: (state, { payload }) => {
      const fullName = payload.student.toLowerCase();
      let modifyStudent = {};
      const remainingStudents = [];
      console.log(state.students);

      for (let i = 0; i < state.students.length; i++) {
        let random = state.students[i];
        console.log(state.students[i]);
        const studentFullName =
          `${random.firstName} ${random.lastName}`.toLowerCase();

        if (studentFullName === fullName) {
          modifyStudent = random;
        } else {
          remainingStudents.push(random);
        }
      }

      state.students = [...remainingStudents, modifyStudent];
    },
    updateFee: (state, { payload }) => {
      const filtered = state.students.filter(
        student => student.id !== payload.id,
      );
      const newArray = [...filtered, payload];
      state.students = newArray;
    },
  },
});

export const { addFee, updateFee } = feesSlice.actions;

export default feesSlice.reducer;
