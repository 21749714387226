import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import {
  ContactPhone,
  Apartment,
  CakeOutlined,
  Badge,
  CottageOutlined,
  WhereToVote,
  Wc,
  FmdBad,
} from '@mui/icons-material';

import styled from '@emotion/styled';

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 24,
  height: 48,
  width: 48,
  borderRadius: '50%',
  minWidth: 42,
  marginRight: 16,
  padding: theme.spacing(1),
  alignItems: 'center',
  justifyContent: 'center',
  border: `solid 1px ${theme.palette.divider}`,
}));

const TeacherOverview = ({ student }) => {
  return (
    <>
      <ListItem
        sx={{
          width: { xs: '100%', sm: '50%', xl: '33.33%' },
        }}>
        <StyledListItemIcon>
          <Apartment fontSize={'inherit'} />
        </StyledListItemIcon>
        <ListItemText
          primary={
            <Typography
              fontSize={'12px'}
              variant="h6"
              color="text.secondary"
              mb={0.5}>
              Branch
            </Typography>
          }
          secondary={
            <Typography variant="body1" color="text.primary">
              {student?.branch}
            </Typography>
          }
        />
      </ListItem>
      <ListItem
        sx={{
          width: { xs: '100%', sm: '50%', xl: '33.33%' },
        }}>
        <StyledListItemIcon>
          <CakeOutlined fontSize={'inherit'} />
        </StyledListItemIcon>
        <ListItemText
          primary={
            <Typography
              fontSize={'12px'}
              variant="h6"
              color="text.secondary"
              mb={0.5}>
              Birthday
            </Typography>
          }
          secondary={
            <Typography variant="body1" color="text.primary">
              {student?.dob}
            </Typography>
          }
        />
      </ListItem>
      <ListItem
        sx={{
          width: { xs: '100%', sm: '50%', xl: '33.33%' },
        }}>
        <StyledListItemIcon>
          <Wc fontSize={'inherit'} />
        </StyledListItemIcon>
        <ListItemText
          primary={
            <Typography
              fontSize={'12px'}
              variant="h6"
              color="text.secondary"
              mb={0.5}>
              Gender
            </Typography>
          }
          secondary={
            <Typography variant="body1" color="text.primary">
              {student?.gender}
            </Typography>
          }
        />
      </ListItem>
      <ListItem
        sx={{
          width: { xs: '100%', sm: '50%', xl: '33.33%' },
        }}>
        <StyledListItemIcon>
          <Badge fontSize={'inherit'} />
        </StyledListItemIcon>
        <ListItemText
          primary={
            <Typography
              fontSize={'12px'}
              variant="h6"
              color="text.secondary"
              mb={0.5}>
              Favorite Employee
            </Typography>
          }
          secondary={
            <Typography variant="body1" color="text.primary">
              {student?.isFavorite === 'Yes' ? 'Yes' : 'No'}
            </Typography>
          }
        />
      </ListItem>
      <ListItem
        sx={{
          width: { xs: '100%', sm: '50%', xl: '33.33%' },
        }}>
        <StyledListItemIcon>
          <ContactPhone fontSize={'inherit'} />
        </StyledListItemIcon>
        <ListItemText
          primary={
            <Typography
              fontSize={'12px'}
              variant="h6"
              color="text.secondary"
              mb={0.5}>
              Contact Number
            </Typography>
          }
          secondary={
            <Typography variant="body1" color="text.primary">
              {student?.phoneNumber}
            </Typography>
          }
        />
      </ListItem>
      <ListItem
        sx={{
          width: { xs: '100%', sm: '50%', xl: '33.33%' },
        }}>
        <StyledListItemIcon>
          <ContactPhone fontSize={'inherit'} />
        </StyledListItemIcon>
        <ListItemText
          primary={
            <Typography
              fontSize={'12px'}
              variant="h6"
              color="text.secondary"
              mb={0.5}>
              Secondary Contact
            </Typography>
          }
          secondary={
            <Typography variant="body1" color="text.primary">
              {student?.otherContact}
            </Typography>
          }
        />
      </ListItem>
      <ListItem
        sx={{
          width: { xs: '100%', sm: '50%', xl: '33.33%' },
        }}>
        <StyledListItemIcon>
          <WhereToVote fontSize={'inherit'} />
        </StyledListItemIcon>
        <ListItemText
          primary={
            <Typography
              fontSize={'12px'}
              variant="h6"
              color="text.secondary"
              mb={0.5}>
              Nationality
            </Typography>
          }
          secondary={
            <Typography variant="body1" color="text.primary">
              {student?.nationality}
            </Typography>
          }
        />
      </ListItem>
      <ListItem
        sx={{
          width: { xs: '100%', sm: '50%', xl: '33.33%' },
        }}>
        <StyledListItemIcon>
          <FmdBad fontSize={'inherit'} />
        </StyledListItemIcon>
        <ListItemText
          primary={
            <Typography
              fontSize={'12px'}
              variant="h6"
              color="text.secondary"
              mb={0.5}>
              Caution
            </Typography>
          }
          secondary={
            <Typography variant="body1" color="text.primary">
              {student?.caution ? student.caution : 'No'}
            </Typography>
          }
        />
      </ListItem>
      <ListItem
        sx={{
          width: { xs: '100%', sm: '50%', xl: '33.33%' },
        }}>
        <StyledListItemIcon>
          <CottageOutlined fontSize={'inherit'} />
        </StyledListItemIcon>
        <ListItemText
          primary={
            <Typography
              fontSize={'12px'}
              variant="h6"
              color="text.secondary"
              mb={0.5}>
              Lives In {student?.village}
            </Typography>
          }
          secondary={
            <Typography variant="body1" color="text.primary">
              {student?.address}
            </Typography>
          }
        />
      </ListItem>
    </>
  );
};

export default TeacherOverview;
