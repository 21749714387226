import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  API_DELETE,
  API_GET,
  API_POST,
  API_PUT,
} from 'app/utils/constants/api/apiRequests';
import { setLoading, setLoadingFinished } from './loadingSlice';
import { showAlert } from './alertSlice';
import API from 'app/services/config';

const initialState = {
  fee: [],
  isDataFetched: false,
  feeItems: [],
  isFeeItemsFetched: false,
};

export const getAllFee = createAsyncThunk(
  'fee/getAllFee',
  async ({ descOrder }, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data, message } = await API_GET(
        `/fee?descOrder=${descOrder}`,
      );

      if (status) {
        return data;
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const getAllFeeItems = createAsyncThunk(
  'fee/getAllFeeItems',
  async ({ descOrder }, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data, message } = await API_GET(
        `/fee?showAll=true?descOrder=${descOrder}`,
      );

      if (status) {
        return data;
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const addNewFee = createAsyncThunk(
  'fee/generate',
  async ({ payload, type }, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, message } = await API_POST(`/fee/${type}`, payload);
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAllFee({ descOrder: false }));
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const editFee = createAsyncThunk(
  'fee/editFee',
  async (payload, { dispatch }) => {
    dispatch(setLoading());
    const dataToSend = {
      student_id: payload.student_id,
      status: payload.status,
      month: payload.month,
      year: payload.year,
      total: payload.total,
      regenerate: payload.regenerate,
    };
    try {
      const { status, data, message } = await API_PUT(
        '/fee',
        payload.id,
        dataToSend,
      );
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAllFee({ descOrder: false }));
        return data;
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const deleteFee = createAsyncThunk(
  'fee/deleteFee',
  async (id, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data, message } = await API_DELETE('/fee/update', id);
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAllFeeItems({ descOrder: true }));
        dispatch(getAllFee({ descOrder: false }));

        return data;
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const payFee = createAsyncThunk(
  'fee/payFee',
  async (payload, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data, message } = await API.put('/fee/pay', payload);
      if (status) {
        dispatch(getAllFee({ descOrder: false }));
        dispatch(getAllFeeItems({ descOrder: true }));
        return data;
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const colorSlice = createSlice({
  name: 'color',
  initialState,

  extraReducers: builder => {
    builder.addCase(getAllFee.fulfilled, (state, { payload }) => {
      state.fee = payload;
      state.isDataFetched = true;
    });

    builder.addCase(getAllFeeItems.fulfilled, (state, { payload }) => {
      state.feeItems = payload;
      state.isFeeItemsFetched = true;
    });
  },
});

export default colorSlice.reducer;
