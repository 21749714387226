import React from 'react';
import { useSelector } from 'react-redux';

import { Card, CardContent, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Div from '@jumbo/shared/Div';
import Span from '@jumbo/shared/Span';

import EarningExpensesChart from './EarningExpensesChart';

const EarningExpenses = () => {
  const [activeChart, setActiveChart] = React.useState('expense');
  const account = useSelector(state => state.accounts.accounts);

  return (
    <Card>
      <CardContent
        sx={{
          display: 'flex',
          minWidth: 0,
          alignItems: 'flex-start',
          justifyContent: 'space-between',
        }}>
        <Stack direction={'row'} spacing={3}>
          <Div>
            <Typography variant={'h3'} fontWeight={'500'} mb={0.5}>
              Rs. {account.earnings}
            </Typography>
            <Typography variant={'body1'} mb={2}>
              <Span
                sx={{
                  width: '5px',
                  height: '5px',
                  overflow: 'hidden',
                  background: '#38d2a2',
                }}></Span>{' '}
              Earning
            </Typography>
          </Div>
          <Div>
            <Typography variant={'h3'} fontWeight={'500'} mb={0.5}>
              Rs. {account.expenses}
            </Typography>
            <Typography variant={'body1'} mb={2}>
              Expenses
            </Typography>
          </Div>
        </Stack>
        <Stack direction={'row'} spacing={1}>
          <Button
            variant={activeChart === 'earning' ? 'contained' : 'outlined'}
            size={'small'}
            onClick={() => setActiveChart('earning')}>
            Earning
          </Button>
          <Button
            variant={activeChart === 'expense' ? 'contained' : 'outlined'}
            disableElevation
            color={'secondary'}
            size={'small'}
            onClick={() => setActiveChart('expense')}>
            Expenses
          </Button>
        </Stack>
      </CardContent>
      <EarningExpensesChart
        activeChart={activeChart}
        revenueSummary={account.revenueSummary}
      />
    </Card>
  );
};

export default EarningExpenses;
