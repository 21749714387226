export const isInDevelopment = process.env.NODE_ENV !== 'production';
export const baseURL = process.env.REACT_APP_BASE_URL;
export const UPLOAD_IMAGE_URL = process.env.REACT_APP_UPLOAD_IMAGE_URL;
export const PUBLIC_ASSETS_URL = process.env.REACT_APP_PUBLIC_ASSETS_URL;

export const QUALIFICATIONS = [
  {
    value: 'Metric',
    name: 'Metric',
  },
  {
    value: 'Intermediate',
    name: 'Intermediate',
  },
  {
    value: 'Bachelor',
    name: 'Bachelor',
  },
  {
    value: 'Masters',
    name: 'Masters',
  },
  {
    value: 'Doctor of Philosophy (PhD)',
    name: 'Doctor of Philosophy (PhD)',
  },
  {
    value: 'Others',
    name: 'Others',
  },
];
