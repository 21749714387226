import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  API_DELETE,
  API_GET,
  API_POST,
  API_PUT,
} from 'app/utils/constants/api/apiRequests';
import { setLoading, setLoadingFinished } from './loadingSlice';
import { showAlert } from './alertSlice';

const initialState = {
  classes: [],
  isClassesFetched: false,
  colors: [],
  isColorsFetched: false,
  groups: [],
  isGroupsFetched: false,
  inActivityReasons: [],
  isInActivityReasonsFetched: false,
  holidays: [],
  isHolidaysFetched: false,
  occupations: [],
  isOccupationsFetched: false,
  sections: [],
  isSectionsFetched: false,
  villages: [],
  isVillagesFetched: false,
  taskToUser: [],
  isTaskToUserFetched: false,
};

export const getAllClasses = createAsyncThunk(
  'configuration/classes/getAllClasses',
  async (_, { dispatch }) => {
    dispatch(setLoading());

    try {
      const { status, data } = await API_GET('/class');
      if (status) {
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const createClass = createAsyncThunk(
  'configuration/classes/createClass',
  async (classData, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data } = await API_POST('/class', classData);
      if (status) {
        dispatch(
          showAlert({
            type: 'success',
            message: 'Class Created Successfully',
          }),
        );
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const editClass = createAsyncThunk(
  'configuration/classes/editClass',
  async (payload, { dispatch }) => {
    dispatch(setLoading());
    const dataToSend = { ...payload };
    delete dataToSend['id'];
    try {
      const { status, data } = await API_PUT('/class', payload.id, dataToSend);
      if (status) {
        dispatch(
          showAlert({
            type: 'success',
            message: 'Class Updated Successfully',
          }),
        );
        dispatch(getAllClasses());
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const deleteClass = createAsyncThunk(
  'configuration/classes/deleteClass',
  async (id, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data } = await API_DELETE('/class', id);
      if (status) {
        dispatch(
          showAlert({
            type: 'success',
            message: 'Class Deleted Successfully',
          }),
        );
        dispatch(getAllClasses());
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const getAllColors = createAsyncThunk(
  'configuration/colors/getAllColors',
  async (_, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data } = await API_GET('/color');
      if (status) {
        dispatch(setLoadingFinished());
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const createColor = createAsyncThunk(
  'configuration/colors/createColor',
  async (colorData, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data } = await API_POST('/color', colorData);
      if (status) {
        dispatch(
          showAlert({
            type: 'success',
            message: 'Color Created Successfully',
          }),
        );
        dispatch(getAllColors());
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const editColor = createAsyncThunk(
  'configuration/colors/editColor',
  async ({ id, ...colorData }, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data } = await API_PUT(`/color`, id, colorData);
      if (status) {
        dispatch(
          showAlert({
            type: 'success',
            message: 'Color Updated Successfully',
          }),
        );
        dispatch(getAllColors());
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const deleteColor = createAsyncThunk(
  'configuration/colors/deleteColor',
  async (id, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data } = await API_DELETE(`/color`, id);
      if (status) {
        dispatch(
          showAlert({
            type: 'success',
            message: 'Color Deleted Successfully',
          }),
        );
        dispatch(getAllColors());
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const getAllGroups = createAsyncThunk(
  'configuration/groups/getAllGroups',
  async (_, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data } = await API_GET('/group');
      if (status) {
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const createGroup = createAsyncThunk(
  'configuration/groups/createGroup',
  async (groupData, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data } = await API_POST('/group', groupData);
      if (status) {
        dispatch(
          showAlert({
            type: 'success',
            message: 'Group Created Successfully',
          }),
        );
        dispatch(getAllGroups());
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const editGroup = createAsyncThunk(
  'configuration/groups/editGroup',
  async ({ id, ...groupData }, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data } = await API_PUT(`/group`, id, groupData);
      if (status) {
        dispatch(
          showAlert({
            type: 'success',
            message: 'Group Updated Successfully',
          }),
        );
        dispatch(getAllGroups());
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const deleteGroup = createAsyncThunk(
  'configuration/groups/deleteGroup',
  async (id, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data } = await API_DELETE(`/group`, id);
      if (status) {
        dispatch(
          showAlert({
            type: 'success',
            message: 'Group Deleted Successfully',
          }),
        );
        dispatch(getAllGroups());
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const getAllStudentInactivityReasons = createAsyncThunk(
  'configuration/studentInactivityReasons/getAllStudentInactivityReasons',
  async (_, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data } = await API_GET('/student-inactivity-reason');
      if (status) {
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const createStudentInActivityReason = createAsyncThunk(
  'configuration/studentInactivityReasons/createStudentInActivityReason',
  async (reasonData, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data } = await API_POST(
        '/student-inactivity-reason',
        reasonData,
      );
      if (status) {
        dispatch(
          showAlert({
            type: 'success',
            message: 'Student Inactivity Reason Added Successfully',
          }),
        );
        dispatch(getAllStudentInactivityReasons());
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const editStudentInactivityReason = createAsyncThunk(
  'configuration/studentInactivityReasons/editStudentInactivityReason',
  async ({ id, ...reasonData }, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data } = await API_PUT(
        `/student-inactivity-reason`,
        id,
        reasonData,
      );
      if (status) {
        dispatch(
          showAlert({
            type: 'success',
            message: 'Student Inactivity Reason Updated Successfully',
          }),
        );
        dispatch(getAllStudentInactivityReasons());
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const deleteStudentInactivityReason = createAsyncThunk(
  'configuration/studentInactivityReasons/deleteStudentInactivityReason',
  async (id, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data } = await API_DELETE(
        `/student-inactivity-reason`,
        id,
      );
      if (status) {
        dispatch(
          showAlert({
            type: 'success',
            message: 'Student Inactivity Reason Deleted Successfully',
          }),
        );
        dispatch(getAllStudentInactivityReasons());
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const getAllHolidays = createAsyncThunk(
  'configuration/holidays/getAllHolidays',
  async (_, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data } = await API_GET('/holiday');
      if (status) {
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const createHoliday = createAsyncThunk(
  'configuration/holidays/createHoliday',
  async (holidayData, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data } = await API_POST('/holiday', holidayData);
      if (status) {
        dispatch(
          showAlert({
            type: 'success',
            message: 'Holiday Created Successfully',
          }),
        );
        dispatch(getAllHolidays());
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const editHoliday = createAsyncThunk(
  'configuration/holidays/editHoliday',
  async ({ id, ...holidayData }, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data } = await API_PUT('/holiday', id, holidayData);
      if (status) {
        dispatch(
          showAlert({
            type: 'success',
            message: 'Holiday Updated Successfully',
          }),
        );
        dispatch(getAllHolidays());
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const deleteHoliday = createAsyncThunk(
  'configuration/holidays/deleteHoliday',
  async (id, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data } = await API_DELETE(`/holiday`, id);
      if (status) {
        dispatch(
          showAlert({
            type: 'success',
            message: 'Holiday Deleted Successfully',
          }),
        );
        dispatch(getAllHolidays());
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const getAllOccupations = createAsyncThunk(
  'configuration/occupations/getAllOccupations',
  async (_, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data } = await API_GET('/occupation');
      if (status) {
        dispatch(
          showAlert({
            type: 'success',
            message: 'Occupations Fetched Successfully',
          }),
        );
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const createOccupation = createAsyncThunk(
  'configuration/occupations/createOccupation',
  async (occupationData, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data } = await API_POST('/occupation', occupationData);
      if (status) {
        dispatch(
          showAlert({
            type: 'success',
            message: 'Occupation Created Successfully',
          }),
        );
        dispatch(getAllOccupations());
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const editOccupation = createAsyncThunk(
  'configuration/occupations/editOccupation',
  async ({ id, ...occupationData }, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data } = await API_PUT('/occupation', id, occupationData);
      if (status) {
        dispatch(
          showAlert({
            type: 'success',
            message: 'Occupation Updated Successfully',
          }),
        );
        dispatch(getAllOccupations());
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const deleteOccupation = createAsyncThunk(
  'configuration/occupations/deleteOccupation',
  async (id, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data } = await API_DELETE(`/occupation`, id);
      if (status) {
        dispatch(
          showAlert({
            type: 'success',
            message: 'Occupation Deleted Successfully',
          }),
        );
        dispatch(getAllOccupations());
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const getAllSections = createAsyncThunk(
  'configuration/sections/getAllSections',
  async (_, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data } = await API_GET('/section');
      if (status) {
        dispatch(
          showAlert({
            type: 'success',
            message: 'Sections Fetched Successfully',
          }),
        );
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const createSection = createAsyncThunk(
  'configuration/sections/createSection',
  async (sectionData, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data } = await API_POST('/section', sectionData);
      if (status) {
        dispatch(
          showAlert({
            type: 'success',
            message: 'Section Created Successfully',
          }),
        );
        dispatch(getAllSections());
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const editSection = createAsyncThunk(
  'configuration/sections/editSection',
  async ({ id, ...sectionData }, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data } = await API_PUT('/section', id, sectionData);
      if (status) {
        dispatch(
          showAlert({
            type: 'success',
            message: 'Section Updated Successfully',
          }),
        );
        dispatch(getAllSections());
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const deleteSection = createAsyncThunk(
  'configuration/sections/deleteSection',
  async (id, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data } = await API_DELETE(`/section`, id);
      if (status) {
        dispatch(
          showAlert({
            type: 'success',
            message: 'Section Deleted Successfully',
          }),
        );
        dispatch(getAllSections());
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const getAllVillages = createAsyncThunk(
  'configuration/villages/getAllVillages',
  async (_, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data } = await API_GET('/village');
      if (status) {
        dispatch(
          showAlert({
            type: 'success',
            message: 'Villages Fetched Successfully',
          }),
        );
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const createVillage = createAsyncThunk(
  'configuration/villages/createVillage',
  async (villageData, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data } = await API_POST('/village', villageData);
      if (status) {
        dispatch(
          showAlert({
            type: 'success',
            message: 'Village Created Successfully',
          }),
        );
        dispatch(getAllVillages());
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const editVillage = createAsyncThunk(
  'configuration/villages/editVillage',
  async ({ id, ...villageData }, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data } = await API_PUT('/village', id, villageData);
      if (status) {
        dispatch(
          showAlert({
            type: 'success',
            message: 'Village Updated Successfully',
          }),
        );
        dispatch(getAllVillages());
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const deleteVillage = createAsyncThunk(
  'configuration/villages/deleteVillage',
  async (id, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data } = await API_DELETE(`/village`, id);
      if (status) {
        dispatch(
          showAlert({
            type: 'success',
            message: 'Village Deleted Successfully',
          }),
        );
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const getAllTasksToUser = createAsyncThunk(
  'configuration/tasks/getAllTasksToUser',
  async (_, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data, message } = await API_GET('/task-to-user');
      if (status) {
        dispatch(
          showAlert({
            type: 'success',
            message,
          }),
        );
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const createTaskToUser = createAsyncThunk(
  'configuration/tasks/createTaskToUser',
  async (taskData, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data, message } = await API_POST(
        '/task-to-user',
        taskData,
      );
      if (status) {
        dispatch(
          showAlert({
            type: 'success',
            message,
          }),
        );
        dispatch(getAllTasksToUser());
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const editTaskToUser = createAsyncThunk(
  'configuration/tasks/editTaskToUser',
  async ({ id, ...taskData }, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data, message } = await API_PUT(
        '/task-to-user',
        id,
        taskData,
      );
      if (status) {
        dispatch(
          showAlert({
            type: 'success',
            message,
          }),
        );
        dispatch(getAllTasksToUser());
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const deleteTaskToUser = createAsyncThunk(
  'configuration/tasks/deleteTaskToUser',
  async (id, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data, message } = await API_DELETE(`/task-to-user`, id);
      if (status) {
        dispatch(
          showAlert({
            type: 'success',
            message,
          }),
        );
        return data;
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const configurationSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllClasses.fulfilled, (state, action) => {
        state.classes = action.payload;
        state.isClassesFetched = true;
      })
      .addCase(deleteClass.fulfilled, (state, action) => {
        state.classes = state.classes.filter(
          classItem => classItem.id !== action.meta.arg,
        );
      })
      .addCase(getAllColors.fulfilled, (state, action) => {
        state.colors = action.payload;
        state.isColorsFetched = true;
      })
      .addCase(deleteColor.fulfilled, (state, action) => {
        state.colors = state.colors.filter(
          colorItem => colorItem.id !== action.meta.arg,
        );
      })
      .addCase(getAllGroups.fulfilled, (state, action) => {
        state.groups = action.payload;
        state.isGroupsFetched = true;
      })
      .addCase(deleteGroup.fulfilled, (state, action) => {
        state.groups = state.groups.filter(
          groupItem => groupItem.id !== action.meta.arg,
        );
      })
      .addCase(getAllHolidays.fulfilled, (state, action) => {
        state.holidays = action.payload;
        state.isHolidaysFetched = true;
      })
      .addCase(deleteHoliday.fulfilled, (state, action) => {
        state.holidays = state.holidays.filter(
          holidayItem => holidayItem.id !== action.meta.arg,
        );
      })
      .addCase(getAllOccupations.fulfilled, (state, action) => {
        state.occupations = action.payload;
        state.isOccupationsFetched = true;
      })
      .addCase(deleteOccupation.fulfilled, (state, action) => {
        state.occupations = state.occupations.filter(
          occupationItem => occupationItem.id !== action.meta.arg,
        );
      })
      .addCase(getAllSections.fulfilled, (state, action) => {
        state.sections = action.payload;
        state.isSectionsFetched = true;
      })
      .addCase(deleteSection.fulfilled, (state, action) => {
        state.sections = state.sections.filter(
          sectionItem => sectionItem.id !== action.meta.arg,
        );
      })
      .addCase(getAllVillages.fulfilled, (state, action) => {
        state.villages = action.payload;
        state.isVillagesFetched = true;
      })
      .addCase(deleteVillage.fulfilled, (state, action) => {
        state.villages = state.villages.filter(
          villageItem => villageItem.id !== action.meta.arg,
        );
      })
      .addCase(getAllStudentInactivityReasons.fulfilled, (state, action) => {
        state.inActivityReasons = action.payload;
        state.isInActivityReasonsFetched = true;
      })
      .addCase(deleteStudentInactivityReason.fulfilled, (state, action) => {
        state.inActivityReasons = state.inActivityReasons.filter(
          inActivityReasonItem => inActivityReasonItem.id !== action.meta.arg,
        );
      })
      .addCase(getAllTasksToUser.fulfilled, (state, action) => {
        state.taskToUser = action.payload;
        state.isTaskToUserFetched = true;
      })
      .addCase(deleteTaskToUser.fulfilled, (state, action) => {
        state.taskToUser = state.taskToUser.filter(
          taskItem => taskItem.id !== action.meta.arg,
        );
      });
  },
});

export default configurationSlice.reducer;
