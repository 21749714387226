import { TableCell, styled } from '@mui/material';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(1),
  textAlign: 'center',
  fontSize: 12,
}));

export const StyledTableCellLeftAlign = styled(TableCell)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(1),
  textAlign: 'left',
  fontSize: 12,
}));
