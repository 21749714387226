import { createSlice } from '@reduxjs/toolkit';

// Inventory (table (name, quantity available, give item))
const initialState = {
  items: [
    {
      id: 1,
      name: 'Item 1',
      quantity: 120,
      available: 50,
      branchOwned: 'Lahore',
      givenTo: [
        {
          name: 'Muhammad Ali',
          id: 1,
          role: 'Student',
          color: '#12aa1d',
        },
      ],
    },
  ],
};

export const inventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    addItem: (state, { payload }) => {
      state.items.push(payload);
    },
    deleteItem: (state, { payload }) => {
      state.items = state.items.filter(item => item.id !== payload.id);
    },
    editItem: (state, { payload }) => {
      const filtered = state.items.filter(item => item.id !== payload.id);
      state.items = [...filtered, payload];
    },
  },
});

export const { addItem, deleteItem, editItem } = inventorySlice.actions;

export default inventorySlice.reducer;
