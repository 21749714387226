import React from 'react';
import { useDispatch } from 'react-redux';
import { ListItemText, Typography, Stack, Tooltip } from '@mui/material';
import { MoreHoriz, Edit, Delete } from '@mui/icons-material';
import styled from '@emotion/styled';

import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import JumboDdMenu from '@jumbo/components/JumboDdMenu';
import Span from '@jumbo/shared/Span';
import JumboListItem from '@jumbo/components/JumboList/components/JumboListItem';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';

import UserForm from '../UserForm';
import UserDetail from '../UserDetail';
import { openUserModal, removeUser } from 'app/store/features/usersSlice';
import { hasPermission, titlalize } from 'app/utils/appHelpers';
import { PERMISSIONS, PERMISSION_TYPES } from 'app/utils/constants/permissions';

const Item = styled(Span)(({ theme }) => ({
  minWidth: 0,
  flexGrow: 0,
  padding: theme.spacing(0, 1),
}));

const StudentItem = ({ user }) => {
  const { showDialog, hideDialog } = useJumboDialog();
  const Swal = useSwalWrapper();

  const dispatch = useDispatch();
  const showUserDetail = React.useCallback(() => {
    showDialog({
      content: <UserDetail teacher={user} onClose={hideDialog} />,
    });
  }, [showDialog, user, hideDialog]);

  const handleDeleteTeacher = async user => {
    await dispatch(removeUser(user.id)).unwrap();
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'User has been deleted successfully.',
      showConfirmButton: false,
      timer: 1500,
    });
    hideDialog();
  };

  const handleItemAction = menuItem => {
    switch (menuItem.action) {
      case 'edit':
        dispatch(openUserModal());
        showDialog({
          title: 'Update User detail',
          content: <UserForm userData={user} />,
        });
        break;
      case 'delete':
        showDialog({
          variant: 'confirm',
          title: 'Are you sure about deleting this User?',
          content: "You won't be able to recover this user later",
          onYes: () => handleDeleteTeacher(user),
          onNo: hideDialog,
        });
        break;
      default:
        break;
    }
  };

  if (!user?.branch) {
    return <></>;
  }

  return (
    <JumboListItem
      componentElement={'div'}
      itemData={user}
      secondaryAction={
        hasPermission(
          [PERMISSIONS.USER],
          [PERMISSION_TYPES.EDIT, PERMISSION_TYPES.DELETE],
        ) && (
          <JumboDdMenu
            icon={<MoreHoriz />}
            menuItems={[
              hasPermission([PERMISSIONS.USER], [PERMISSION_TYPES.EDIT]) && {
                icon: <Edit />,
                title: 'Edit',
                action: 'edit',
              },
              hasPermission([PERMISSIONS.USER], [PERMISSION_TYPES.DELETE]) && {
                icon: <Delete />,
                title: 'Delete',
                action: 'delete',
              },
            ]}
            onClickCallback={handleItemAction}
          />
        )
      }
      sx={{
        cursor: 'pointer',
        borderTop: 1,
        borderColor: 'divider',
        '&:hover': {
          bgcolor: 'action.hover',
        },
      }}>
      <ListItemText
        onClick={showUserDetail}
        primary={
          <Typography variant={'body1'} component={'div'}>
            <Stack direction={'row'} alignItems={'center'} sx={{ minWidth: 0 }}>
              <Item
                sx={{
                  flexBasis: { md: '25%' },
                }}>
                <Tooltip title="User's Name">
                  <Typography
                    variant={'h5'}
                    fontSize={14}
                    lineHeight={1.25}
                    mb={0}
                    noWrap>
                    {user?.name}
                  </Typography>
                </Tooltip>
              </Item>

              <Item
                sx={{
                  flexBasis: { md: '25%' },
                }}>
                <Tooltip title="User's Type">
                  <Typography
                    variant={'h5'}
                    fontSize={14}
                    lineHeight={1.25}
                    mb={0}
                    noWrap>
                    {titlalize(user?.user_type)}
                  </Typography>
                </Tooltip>
              </Item>

              <Item
                sx={{
                  flexBasis: { md: '25%' },
                  display: { xs: 'none', md: 'block' },
                }}>
                <Tooltip title="User Phone Number">
                  <Typography variant={'body1'} noWrap>
                    {user?.phone_no}
                  </Typography>
                </Tooltip>
              </Item>

              <Item
                sx={{
                  flexBasis: { md: '25%' },
                  display: { xs: 'none', md: 'block' },
                }}>
                <Tooltip title="User's Branch">
                  <Typography variant={'body1'} noWrap>
                    {user?.branch?.name}
                  </Typography>
                </Tooltip>
              </Item>
              <Item
                sx={{
                  flexBasis: { md: '25%' },
                  display: { xs: 'none', md: 'block' },
                }}>
                <Tooltip title="User's Assigned Color">
                  <Typography variant={'body1'} noWrap>
                    {user?.color?.title}
                  </Typography>
                </Tooltip>
              </Item>

              <Item
                sx={{
                  flexBasis: { md: '25%' },
                  display: { xs: 'none', md: 'block' },
                }}>
                <Tooltip title="User's Status">
                  <Typography variant={'body1'} noWrap>
                    {user?.status ? 'Active' : 'InActive'}
                  </Typography>
                </Tooltip>
              </Item>
            </Stack>
          </Typography>
        }
      />
    </JumboListItem>
  );
};

export default StudentItem;
