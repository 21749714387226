import { useDispatch } from 'react-redux';
import { Form, Formik } from 'formik';

import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import Div from '@jumbo/shared/Div';
import Button from '@jumbo/components/JumboButton';

import { schema } from '../../utils/schema';
import {
  createVillage,
  editVillage,
} from 'app/store/features/configurationSlice';
import JumboSwitch from '@jumbo/components/JumboFormik/JumboSwitch';
import { Grid } from '@mui/material';

const FormComponent = ({ payload }) => {
  const dispatch = useDispatch();
  const { hideDialog } = useJumboDialog();

  const isUpdate = payload ? true : false;
  const initialValues = {
    name: payload ? payload.name : '',
    user_village_code: payload ? payload.user_village_code : '',
    status: payload ? payload.status : true,
  };

  const addHandler = data => {
    const payload = { ...data };
    if (!data.user_village_code) {
      delete payload['user_village_code'];
    }
    dispatch(createVillage(payload));
  };

  const updateHandler = data => {
    const payload = {
      id: data.id,
      name: data.name,
      status: data.status,
    };

    if (data.user_village_code) {
      payload.user_village_code = data.user_village_code;
    }
    dispatch(editVillage(payload));
  };

  const onSave = data => {
    isUpdate ? updateHandler(data) : addHandler(data);
    hideDialog();
  };
  return (
    <Formik
      validateOnChange={true}
      initialValues={payload?.id ? payload : initialValues}
      enableReinitialize={true}
      validationSchema={schema}
      onSubmit={onSave}>
      {({ isSubmitting }) => (
        <Form noValidate autoComplete="off">
          <Div
            sx={{
              '& .MuiTextField-root': {
                mb: 3,
              },
            }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <JumboTextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  name="name"
                  label="Village name"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <JumboTextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  name="user_village_code"
                  label="User Village Code"
                  type="number"
                />
              </Grid>
            </Grid>

            <JumboSwitch name="status" label="Is Village Active?" />

            <Div
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}>
              <Button
                variant="outlined"
                sx={{
                  mr: 2,
                }}
                onClick={() => hideDialog()}>
                Close
              </Button>
              <Button type="submit" variant="contained" loading={isSubmitting}>
                Save
              </Button>
            </Div>
          </Div>
        </Form>
      )}
    </Formik>
  );
};

export default FormComponent;
