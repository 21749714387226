import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import Button from '@jumbo/components/JumboButton';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import Div from '@jumbo/shared/Div';
import JumboDropDown from '@jumbo/components/JumboFormik/JumboDropDown';

import { getAllExpenses } from 'app/store/features/expenseSlice';

const validationSchema = Yup.object().shape({
  expense_id: Yup.number().required('Expense Category is required'),
  description: Yup.string().required('Description is required'),
  quantity: Yup.number()
    .min(1, 'Quantity must be atleast 1')
    .required('Quantity is Required'),
  unit_rate: Yup.number()
    .min(1, 'Unit Rate must be atleast 1')
    .required('Unit Rate is Required'),
  // amount: Yup.number()
  //   .min(1, 'Amount must be greater than Rs. 1')
  //   .required('Amount is Required'),
});

const BillExpenseForm = ({ expenses, setExpenses }) => {
  const dispatch = useDispatch();
  const { hideDialog } = useJumboDialog();

  const storeExpenses = useSelector(state => state.expense.expenses);
  const isExpensesFetched = useSelector(state => state.expense.isDataFetched);

  const fetchData = useCallback(async () => {
    if (!isExpensesFetched) dispatch(getAllExpenses());
  }, [dispatch, isExpensesFetched]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const initialValues = {
    expense_id: '',
    description: '',
    quantity: 0,
    unit_rate: 0,
  };

  const onSave = values => {
    const payload = {
      ...values,
      id: expenses.length + 1,
      amount: values.quantity * values.unit_rate,
    };

    setExpenses(prev => [...prev, payload]);
    hideDialog();
  };
  return (
    <Formik
      validateOnChange={true}
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={onSave}>
      {({ isSubmitting }) => (
        <Form noValidate autoComplete="off">
          <Div
            sx={{
              '& .MuiTextField-root': {
                mb: 3,
              },
            }}>
            <JumboDropDown
              fullWidth
              size="small"
              variant="outlined"
              name="expense_id"
              label="Please Select Expenses"
              options={storeExpenses}
            />

            <JumboTextField
              fullWidth
              size="small"
              variant="outlined"
              name="description"
              label="Expense Description"
            />
            <JumboTextField
              fullWidth
              size="small"
              variant="outlined"
              name="quantity"
              label="Quantity"
              type="number"
            />
            <JumboTextField
              fullWidth
              size="small"
              variant="outlined"
              name="unit_rate"
              label="Unit Price"
              type="number"
            />

            <Div
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}>
              <Button
                type="submit"
                variant="outlined"
                onClick={hideDialog}
                sx={{ marginRight: 2 }}>
                Close
              </Button>
              <Button type="submit" variant="contained" loading={isSubmitting}>
                Save
              </Button>
            </Div>
          </Div>
        </Form>
      )}
    </Formik>
  );
};

export default BillExpenseForm;
