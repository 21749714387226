import React from 'react';
import { useSelector } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import { ThemeProvider } from '@mui/material';
import JumboDdPopover from '@jumbo/components/JumboDdPopover';
import { useJumboTheme } from '@jumbo/hooks';
import MenuDropDown from './MenuDropDown';
import { getPublicAsset } from 'app/utils/appHelpers';

const AuthUserDropdown = () => {
  const user = useSelector(state => state.auth.user);

  const { theme } = useJumboTheme();

  return (
    <ThemeProvider theme={theme}>
      <JumboDdPopover
        triggerButton={
          <Avatar
            src={getPublicAsset(user?.image)}
            sizes={'small'}
            sx={{ boxShadow: 25, cursor: 'pointer' }}
          />
        }>
        <MenuDropDown />
      </JumboDdPopover>
    </ThemeProvider>
  );
};

export default AuthUserDropdown;
