import { useState, useEffect, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Card } from '@mui/material';

import JumboRqList from '@jumbo/components/JumboReactQuery/JumboRqList';
import JumboListToolbar from '@jumbo/components/JumboList/components/JumboListToolbar';
import JumboSearch from '@jumbo/components/JumboSearch';

import UserItem from './UserItem';

const UsersList = ({ users }) => {
  const { category } = useParams();

  const listRef = useRef();

  const [usersToShow, setUsersToShow] = useState(users);

  useEffect(() => {
    setUsersToShow(users);
  }, [users]);

  useEffect(() => {
    if (category === 'all') {
      setUsersToShow(users);
    } else {
      const filteredTeachers = users?.filter(user => {
        const branch = user.branch?.name;
        if (category === branch?.toLowerCase()) {
          return user;
        }
        return false;
      });

      setUsersToShow(filteredTeachers);
    }
  }, [category, users]);

  const renderUser = useCallback(user => {
    return <UserItem user={user} view={'list'} />;
  }, []);

  const handleOnChange = useCallback(
    keyword => {
      const filtered = users?.filter(user =>
        user?.name?.toLowerCase().includes(keyword?.toLowerCase()),
      );
      setUsersToShow(filtered);
    },
    [users],
  );

  return (
    <JumboRqList
      ref={listRef}
      data={usersToShow}
      wrapperComponent={Card}
      primaryKey={'id'}
      itemsPerPage={50}
      renderItem={renderUser}
      componentElement={'div'}
      isClasses
      // sx={view === 'grid' && { p: theme => theme.spacing(1, 3, 3) }}
      wrapperSx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
      toolbar={
        <JumboListToolbar hideItemsPerPage={true}>
          <JumboSearch
            onChange={handleOnChange}
            sx={{
              display: { xs: 'none', md: 'block' },
            }}
          />
        </JumboListToolbar>
      }
      view={'list'}
    />
  );
};

export default UsersList;
