import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, Typography } from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import { getAllTasksToUser } from 'app/store/features/configurationSlice';

import List from './components/List';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import FormComponent from './components/Form';
import Loader from 'app/shared/components/Loader';
import { hasPermission } from 'app/utils/appHelpers';
import { PERMISSIONS, PERMISSION_TYPES } from 'app/utils/constants/permissions';

const TasksToUser = () => {
  const dispatch = useDispatch();
  const { isTaskToUserFetched } = useSelector(state => state.configuration);

  const { showDialog } = useJumboDialog();

  const showAddButton = useCallback(() => {
    showDialog({
      title: 'Assign New Task to User',
      content: <FormComponent />,
    });
  }, [showDialog]);

  useEffect(() => {
    if (!isTaskToUserFetched) dispatch(getAllTasksToUser());
  }, [dispatch, isTaskToUserFetched]);

  if (!isTaskToUserFetched) {
    return <Loader />;
  }

  return (
    <>
      <JumboContentLayout>
        <Box width="100%">
          {hasPermission(
            [PERMISSIONS.TASK_TO_USER],
            [PERMISSION_TYPES.ADD],
          ) && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
              <Typography variant={'h2'} mb={0.35}>
                Tasks to User
              </Typography>

              <Button
                disableElevation
                variant={'contained'}
                startIcon={<PersonAddIcon />}
                sx={{
                  mb: 2,
                  '& .MuiSvgIcon-root': {
                    fontSize: '1.5rem',
                  },
                }}
                onClick={showAddButton}>
                Assign New Task to User
              </Button>
            </Box>
          )}
          {hasPermission(
            [PERMISSIONS.TASK_TO_USER],
            [PERMISSION_TYPES.LISTING],
          ) && <List />}
        </Box>
      </JumboContentLayout>
    </>
  );
};
export default TasksToUser;
