import React from 'react';
import { useField } from 'formik';
import TextField from '@mui/material/TextField';

const JumboTextField = ({ onChange, onBlur, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : '';

  const handleBlur = e => {
    if (onBlur) onBlur(e);
    helpers.setTouched(true);
  };

  // const handleChange = e => {
  //   if (onChange) onChange(e);
  //   const capitalizedValue =
  //     e.target.value.charAt(0).toUpperCase() +
  //     e.target.value.slice(1).toLowerCase();
  //   helpers.setValue(capitalizedValue); // Update the Formik field value
  // };

  return (
    <TextField
      {...props}
      {...field}
      helperText={errorText}
      error={!!errorText}
      size="small"
      // onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

export default JumboTextField;
