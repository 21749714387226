import { useLocation, useNavigate } from 'react-router-dom';
import {
  Paper,
  Typography,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';

const BillExpensesDetails = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  if (!state || !state.data) navigate('/app/accounts/all');

  const { data } = state;

  const {
    paid_to,
    bill_date,
    paid_by_other,
    bill_amount,
    paid_bill_amount,
    balance,
    status,
    bill_expenses,
    vendor,
  } = data;

  return (
    <>
      <Typography variant="h4">Bill Detail</Typography>
      <Paper sx={{ padding: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h3">Bill Information</Typography>
            <Typography variant="h6">
              <strong>Paid To:</strong> {paid_to}
            </Typography>
            <Typography variant="h6">
              <strong>Bill Date:</strong> {bill_date}
            </Typography>
            <Typography variant="h6">
              <strong>Paid By Other:</strong> {paid_by_other ? 'Yes' : 'No'}
            </Typography>
            <Typography variant="h6">
              <strong>Bill Amount:</strong> {bill_amount}
            </Typography>
            <Typography variant="h6">
              <strong>Paid Bill Amount:</strong> {paid_bill_amount}
            </Typography>
            <Typography variant="h6">
              <strong>Balance:</strong> {balance}
            </Typography>
            <Typography variant="h6">
              <strong>Status:</strong> {status}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h3">Vendor Information</Typography>
            <Typography variant="h6">
              <strong>Username:</strong> {vendor.username}
            </Typography>
            <Typography variant="h6">
              <strong>Role:</strong> {vendor.role}
            </Typography>
            <Typography variant="h6">
              <strong>Status:</strong> {vendor.status ? 'Active' : 'Inactive'}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3">Bill Expenses</Typography>

            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Bill ID</TableCell>
                  <TableCell>Expense</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Unit Rate</TableCell>
                  <TableCell>Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bill_expenses?.map(row => (
                  <TableRow key={row.id}>
                    <TableCell>
                      <Typography variant="subtitle1">{row.id}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">{row.bill_id}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">
                        {row.expense.title}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">
                        {row.description}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">
                        {row.quantity}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">
                        {row.unit_rate}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">{row.amount}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default BillExpensesDetails;
