import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import NonExpenseSidebar from './components/NonExpenseSidebar';
import PaymentsList from './components/NonExpensePaymentsList';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { getAllNonExpensePayments } from 'app/store/features/nonExpenseSlice';
import { hasPermission } from 'app/utils/appHelpers';
import { PERMISSIONS, PERMISSION_TYPES } from 'app/utils/constants/permissions';

const NonExpensePayments = () => {
  const dispatch = useDispatch();
  const isNonExpensePaymentsFetched = useSelector(
    state => state.nonExpensePayments.isDataFetched,
  );

  const fetchData = useCallback(() => {
    if (!isNonExpensePaymentsFetched) dispatch(getAllNonExpensePayments());
  }, [dispatch, isNonExpensePaymentsFetched]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <JumboContentLayout
        sidebar={
          hasPermission(
            [PERMISSIONS.NON_EXPENSE_PAYMENT],
            [PERMISSION_TYPES.ADD],
          ) && <NonExpenseSidebar />
        }>
        {hasPermission(
          [PERMISSIONS.NON_EXPENSE_PAYMENT],
          [PERMISSION_TYPES.LISTING],
        ) && <PaymentsList />}
      </JumboContentLayout>
    </>
  );
};
export default NonExpensePayments;
