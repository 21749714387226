import moment from 'moment';
import { getCurrentFormattedDate } from 'app/utils/appHelpers';

export const calculateDailyAttendance = (data, selectedBranch) => {
  console.log({ data });
  const temp = data?.filter(
    item => getCurrentFormattedDate() === item.attendence_date,
  );
  const classSectionCounts = {};
  temp?.forEach(attendance => {
    const branchId = attendance.class_section.branch_id;
    if (branchId !== selectedBranch) {
      return;
    }
    const classSectionId = attendance.class_section.id;
    const classSectionName = attendance.class_section.class_section_title;
    if (!classSectionCounts[classSectionId]) {
      classSectionCounts[classSectionId] = {
        name: classSectionName,
        present: 0,
        excused: 0,
        unexcused: 0,
        late: 0,
      };
    }
    classSectionCounts[classSectionId].present +=
      attendance.attendence_students.filter(
        student => student.is_present,
      ).length;
    classSectionCounts[classSectionId].excused +=
      attendance.attendence_students.filter(
        student => student.is_excused,
      ).length;
    classSectionCounts[classSectionId].unexcused +=
      attendance.attendence_students.filter(
        student => student.is_unexcused,
      ).length;
    classSectionCounts[classSectionId].late +=
      attendance.attendence_students.filter(
        student => student.late_comer,
      ).length;
  });
  return classSectionCounts;
};

export const calculateAllAttendance = (data, selectedBranch) => {
  const classSectionCounts = {};
  data.forEach(attendance => {
    const branchId = attendance.class_section.branch_id;
    if (branchId !== selectedBranch) {
      return;
    }
    const classSectionId = attendance.class_section.id;
    const classSectionName = attendance.class_section.class_section_title;
    if (!classSectionCounts[classSectionId]) {
      classSectionCounts[classSectionId] = {
        name: classSectionName,
        present: 0,
        excused: 0,
        unexcused: 0,
        late: 0,
      };
    }
    classSectionCounts[classSectionId].present +=
      attendance.attendence_students.filter(
        student => student.is_present,
      ).length;
    classSectionCounts[classSectionId].excused +=
      attendance.attendence_students.filter(
        student => student.is_excused,
      ).length;
    classSectionCounts[classSectionId].unexcused +=
      attendance.attendence_students.filter(
        student => student.is_unexcused,
      ).length;
    classSectionCounts[classSectionId].late +=
      attendance.attendence_students.filter(
        student => student.late_comer,
      ).length;
  });
  return classSectionCounts;
};

export const calculateWeeklyAttendance = (data, selectedBranch) => {
  const weekStart = moment().startOf('week').format('YYYY-MM-DD');
  const weekEnd = moment().endOf('week').format('YYYY-MM-DD');

  const temp = data.filter(item => {
    const date = Date.parse(item.attendence_date);

    if (date >= Date.parse(weekStart) || date <= Date.parse(weekEnd)) {
      return item;
    }
    return null;
  });
  const classSectionCounts = {};
  temp.forEach(attendance => {
    const branchId = attendance.class_section.branch_id;
    if (branchId !== selectedBranch) {
      return;
    }
    const classSectionId = attendance.class_section.id;
    const classSectionName = attendance.class_section.class_section_title;
    if (!classSectionCounts[classSectionId]) {
      classSectionCounts[classSectionId] = {
        name: classSectionName,
        present: 0,
        excused: 0,
        unexcused: 0,
        late: 0,
      };
    }
    classSectionCounts[classSectionId].present +=
      attendance.attendence_students.filter(
        student => student.is_present,
      ).length;
    classSectionCounts[classSectionId].excused +=
      attendance.attendence_students.filter(
        student => student.is_excused,
      ).length;
    classSectionCounts[classSectionId].unexcused +=
      attendance.attendence_students.filter(
        student => student.is_unexcused,
      ).length;
    classSectionCounts[classSectionId].late +=
      attendance.attendence_students.filter(
        student => student.late_comer,
      ).length;
  });
  return classSectionCounts;
};

export const calculateMonthlyAttendance = (data, selectedBranch) => {
  const monthStart = moment().startOf('month').format('YYYY-MM-DD');
  const monthEnd = moment().endOf('month').format('YYYY-MM-DD');

  const temp = data.filter(item => {
    const date = Date.parse(item.attendence_date);

    if (date >= Date.parse(monthStart) || date <= Date.parse(monthEnd)) {
      return item;
    }
    return null;
  });

  const classSectionCounts = {};
  temp.forEach(attendance => {
    const branchId = attendance.class_section.branch_id;
    if (branchId !== selectedBranch) {
      return;
    }

    const classSectionId = attendance.class_section.id;
    const classSectionName = attendance.class_section.class_section_title;
    if (!classSectionCounts[classSectionId]) {
      classSectionCounts[classSectionId] = {
        name: classSectionName,
        present: 0,
        excused: 0,
        unexcused: 0,
        late: 0,
      };
    }
    classSectionCounts[classSectionId].present +=
      attendance.attendence_students.filter(
        student => student.is_present,
      ).length;
    classSectionCounts[classSectionId].excused +=
      attendance.attendence_students.filter(
        student => student.is_excused,
      ).length;
    classSectionCounts[classSectionId].unexcused +=
      attendance.attendence_students.filter(
        student => student.is_unexcused,
      ).length;
    classSectionCounts[classSectionId].late +=
      attendance.attendence_students.filter(
        student => student.late_comer,
      ).length;
  });
  return classSectionCounts;
};
