import * as yup from 'yup';

export const itemSchema = yup.object().shape({
  name: yup.string().required('Item Name is Required'),
  description: yup.string(),
  user_item_code: yup.number().positive('User Item Code must be positive'),
  price: yup
    .number()
    .positive('Price should be positive')
    .required('Price is Required'),
  group: yup.string(),
  current_stock: yup.number().positive('Current Stock cannot be negative'),
  min_threshhold: yup
    .number()
    .positive('Minimum Threshhold cannot be negative'),
  is_stockable: yup.boolean().required('Is Stockable is Required'),
  status: yup.boolean().required('Status is Required'),
});

export const assignItemSchema = yup
  .object({
    student_id: yup
      .array()
      .min(1)
      .of(yup.number().positive())
      .label('Student Id'),
    item: yup
      .object({
        id: yup.number().positive().required('Item ID is required.'),
        price: yup.number().positive().required('Item price is required.'),
      })
      .nullable()
      .required('Item is required.')
      .label('Item Id'),
    assigned_by_id: yup.number().positive().required().label('Assigned By Id'),
    quantity: yup.number().positive().min(1).label('Quantity'),
    receivable_amount: yup
      .number()
      .min(-1)
      .nullable()
      .label('Receivable Amount'),
    assigned_date: yup.string().required().label('Assigned Date'),
  })
  .label('Student Item Schema');
