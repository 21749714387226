import React from 'react';
import { useDispatch } from 'react-redux';

import { deleteClass } from 'app/store/features/classesSlice';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Tooltip,
  Typography,
  Avatar,
  Stack,
} from '@mui/material';
import {
  Person,
  AccountBalance,
  MoreHoriz,
  Edit,
  Delete,
  Group,
  Money,
  FilterTiltShift,
  LightMode,
  BookOnline,
  MoneyOff,
  ToggleOff,
  ToggleOn,
} from '@mui/icons-material';
import styled from '@emotion/styled';

import JumboDdMenu from '@jumbo/components/JumboDdMenu';
import Span from '@jumbo/shared/Span';
import Div from '@jumbo/shared/Div';
import JumboListItem from '@jumbo/components/JumboList/components/JumboListItem';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import JumboGridItem from '@jumbo/components/JumboList/components/JumboGridItem';

import ClassesDetail from '../ClassesDetail';
import { hasPermission, titlalize } from 'app/utils/appHelpers';
import { useNavigate } from 'react-router-dom';
import { PERMISSIONS, PERMISSION_TYPES } from 'app/utils/constants/permissions';

const Item = styled(Span)(({ theme }) => ({
  minWidth: 0,
  flexGrow: 0,
  padding: theme.spacing(0, 1),
}));

const ClassItem = ({ singleClass, view }) => {
  const { showDialog, hideDialog } = useJumboDialog();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const showClassDetail = React.useCallback(() => {
    showDialog({
      content: <ClassesDetail classData={singleClass} onClose={hideDialog} />,
    });
  }, [showDialog, singleClass, hideDialog]);

  const handleDeleteClass = data => {
    dispatch(deleteClass(data.id));
    hideDialog();
  };

  const handleItemAction = menuItem => {
    switch (menuItem.action) {
      case 'edit':
        navigate('/app/classes/form', { state: { id: singleClass?.id } });
        break;
      case 'delete':
        showDialog({
          variant: 'confirm',
          title: 'Are you sure about deleting this class?',
          content: "You won't be able to recover this class later",
          onYes: () => handleDeleteClass(singleClass),
          onNo: hideDialog,
        });
        break;
      default:
        break;
    }
  };

  if (view === 'grid') {
    return (
      <JumboGridItem xs={12} md={6} lg={4}>
        <Card variant="outlined" elevation={0}>
          <CardHeader
            avatar={
              <Avatar
                sx={{ width: 48, height: 48 }}
                alt={singleClass?.class_section_title}
                src={singleClass?.profile_pic}
              />
            }
            action={
              <>
                {hasPermission(
                  [PERMISSIONS.CLASS_SECTION],
                  [PERMISSION_TYPES.EDIT, PERMISSION_TYPES.DELETE],
                ) && (
                  <JumboDdMenu
                    icon={<MoreHoriz />}
                    menuItems={[
                      hasPermission(
                        [PERMISSIONS.CLASS_SECTION],
                        [PERMISSION_TYPES.EDIT],
                      ) && {
                        icon: <Edit />,
                        title: 'Edit',
                        action: 'edit',
                      },
                      hasPermission(
                        [PERMISSIONS.CLASS_SECTION],
                        [PERMISSION_TYPES.DELETE],
                      ) && {
                        icon: <Delete />,
                        title: 'Delete',
                        action: 'delete',
                      },
                    ]}
                    onClickCallback={handleItemAction}
                  />
                )}
              </>
            }
            title={
              <Tooltip title="Class Section Name">
                <Typography variant={'h6'} color={'text.primary'} mb={0.25}>
                  {singleClass?.class_section_title}
                </Typography>
              </Tooltip>
            }
            subheader={
              <Tooltip title="Class, Section">
                <Typography variant={'body1'} color={'text.secondary'}>
                  {singleClass?.class}, {singleClass?.section}
                </Typography>
              </Tooltip>
            }
          />
          <CardContent sx={{ pt: 0 }}>
            <Divider sx={{ mb: 2 }} />
            <List disablePadding>
              <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                <ListItemIcon sx={{ minWidth: 50 }}>
                  <Money />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Tooltip title="Fee Frequency">
                      <Typography variant={'body1'} color={'text.primary'}>
                        {titlalize(singleClass?.fee_frequency)}
                      </Typography>
                    </Tooltip>
                  }
                />
              </ListItem>

              <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                <ListItemIcon sx={{ minWidth: 50 }}>
                  <Money />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Tooltip title="Fee Type">
                      <Typography variant={'body1'} color={'text.primary'}>
                        {titlalize(singleClass?.fee_type)}
                      </Typography>
                    </Tooltip>
                  }
                />
              </ListItem>

              <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                <ListItemIcon sx={{ minWidth: 50 }}>
                  <Person />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Tooltip title="Class In Charge">
                      <Typography variant={'body1'}>
                        {singleClass?.incharge?.username}
                      </Typography>
                    </Tooltip>
                  }
                />
              </ListItem>

              <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                <ListItemIcon sx={{ minWidth: 50 }}>
                  <LightMode />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Tooltip title="Is Full Day?">
                      <Typography variant={'body1'}>
                        {singleClass?.is_full_day ? 'Yes' : 'No'}
                      </Typography>
                    </Tooltip>
                  }
                />
              </ListItem>

              <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                <ListItemIcon sx={{ minWidth: 50 }}>
                  <BookOnline />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Tooltip title="Is Pre Admission?">
                      <Typography variant={'body1'}>
                        {singleClass?.is_pre_admission ? 'Yes' : 'No'}
                      </Typography>
                    </Tooltip>
                  }
                />
              </ListItem>

              <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                <ListItemIcon sx={{ minWidth: 50 }}>
                  <MoneyOff />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Tooltip title="Is Refundable?">
                      <Typography variant={'body1'}>
                        {singleClass?.is_refundable ? 'Yes' : 'No'}
                      </Typography>
                    </Tooltip>
                  }
                />
              </ListItem>

              <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                <ListItemIcon sx={{ minWidth: 50 }}>
                  {singleClass?.status ? <ToggleOn /> : <ToggleOff />}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Tooltip title="Status?">
                      <Typography variant={'body1'}>
                        {singleClass?.status ? 'Active' : 'In Active'}
                      </Typography>
                    </Tooltip>
                  }
                />
              </ListItem>

              <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                <ListItemIcon sx={{ minWidth: 50 }}>
                  <AccountBalance />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Tooltip title="Fee Amount">
                      <Typography variant={'body1'}>
                        Rs. {singleClass?.fee_amount}
                      </Typography>
                    </Tooltip>
                  }
                />
              </ListItem>
              <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                <ListItemIcon sx={{ minWidth: 50 }}>
                  <FilterTiltShift />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Tooltip title="Shift">
                      <Typography variant={'body1'}>
                        {singleClass?.shift?.charAt(0).toUpperCase() +
                          singleClass?.shift?.slice(1)}
                      </Typography>
                    </Tooltip>
                  }
                />
              </ListItem>
            </List>
            <Divider sx={{ my: 2 }} />
            <Div
              sx={{
                display: 'flex',
                minWidth: 0,
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
              <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                <ListItemIcon sx={{ minWidth: 50 }}>
                  <Group />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Tooltip title="Minimum Strength">
                      <Typography variant={'body1'}>
                        {singleClass?.min_strength}
                      </Typography>
                    </Tooltip>
                  }
                />
              </ListItem>
              <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                <ListItemIcon sx={{ minWidth: 50 }}>
                  <Group />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Tooltip title="Maximum Strength">
                      <Typography variant={'body1'}>
                        {singleClass?.max_strength}
                      </Typography>
                    </Tooltip>
                  }
                />
              </ListItem>
            </Div>
          </CardContent>
        </Card>
      </JumboGridItem>
    );
  }
  return (
    <JumboListItem
      componentElement={'div'}
      itemData={singleClass}
      secondaryAction={
        <>
          {hasPermission(
            [PERMISSIONS.CLASS_SECTION],
            [PERMISSION_TYPES.EDIT, PERMISSION_TYPES.DELETE],
          ) && (
            <JumboDdMenu
              icon={<MoreHoriz />}
              menuItems={[
                hasPermission(
                  [PERMISSIONS.CLASS_SECTION],
                  [PERMISSION_TYPES.EDIT],
                ) && {
                  icon: <Edit />,
                  title: 'Edit',
                  action: 'edit',
                },
                hasPermission(
                  [PERMISSIONS.CLASS_SECTION],
                  [PERMISSION_TYPES.DELETE],
                ) && {
                  icon: <Delete />,
                  title: 'Delete',
                  action: 'delete',
                },
              ]}
              onClickCallback={handleItemAction}
            />
          )}
        </>
      }
      sx={{
        cursor: 'pointer',
        borderTop: 1,
        borderColor: 'divider',
        '&:hover': {
          bgcolor: 'action.hover',
        },
      }}>
      <ListItemText
        onClick={showClassDetail}
        primary={
          <Typography variant={'body1'} component={'div'}>
            <Stack direction={'row'} alignItems={'center'} sx={{ minWidth: 0 }}>
              <Item
                sx={{
                  flexBasis: { xs: '100%', sm: '50%', md: '25%' },
                }}>
                <Tooltip title="Title of Class">
                  <Typography
                    variant={'h5'}
                    fontSize={14}
                    lineHeight={1.25}
                    mb={0}
                    noWrap>
                    {singleClass?.class_section_title}
                  </Typography>
                </Tooltip>
              </Item>
              <Item
                sx={{
                  flexBasis: { sm: '50%', md: '28%' },
                  display: { xs: 'none', sm: 'block' },
                }}>
                <Tooltip title={'Class, Section'}>
                  <Typography variant={'body1'} noWrap>
                    {singleClass?.class}, {singleClass?.section}
                  </Typography>
                </Tooltip>
              </Item>
              <Item
                sx={{
                  flexBasis: { sm: '50%', md: '28%' },
                  display: { xs: 'none', sm: 'block' },
                }}>
                <Tooltip title={'Shift'}>
                  <Typography variant={'body1'} noWrap>
                    {titlalize(singleClass?.shift)}
                  </Typography>
                </Tooltip>
              </Item>
              <Item
                sx={{
                  flexBasis: { md: '25%' },
                  display: { xs: 'none', md: 'block' },
                }}>
                <Tooltip title="Class InCharge">
                  <Typography variant={'body1'} noWrap>
                    {singleClass?.incharge?.name}
                  </Typography>
                </Tooltip>
              </Item>
              <Item
                sx={{
                  flexBasis: { md: '25%' },
                  display: { xs: 'none', md: 'block' },
                }}>
                <Tooltip title="Fee Frequency">
                  <Typography variant={'body1'} noWrap>
                    {singleClass?.fee_frequency}
                  </Typography>
                </Tooltip>
              </Item>

              <Item
                sx={{
                  flexBasis: { md: '25%' },
                  display: { xs: 'none', md: 'block' },
                }}>
                <Tooltip title={'Branch'}>
                  <Typography variant={'body1'} noWrap>
                    {singleClass?.branch?.name}
                  </Typography>
                </Tooltip>
              </Item>

              <Item
                sx={{
                  flexBasis: { md: '25%' },
                  display: { xs: 'none', md: 'block' },
                }}>
                <Tooltip title="Class Status">
                  <Typography variant={'body1'} noWrap>
                    {singleClass?.status ? 'Active' : 'InActive'}
                  </Typography>
                </Tooltip>
              </Item>

              <Item
                sx={{
                  flexBasis: { md: '25%' },
                  display: { xs: 'none', md: 'block' },
                }}>
                <Tooltip title={'Minimum Strength'}>
                  <Typography variant={'body1'} noWrap>
                    {singleClass?.min_strength}
                  </Typography>
                </Tooltip>
              </Item>
              <Item
                sx={{
                  flexBasis: { md: '25%' },
                  display: { xs: 'none', md: 'block' },
                }}>
                <Tooltip title={'Maximum Strength'}>
                  <Typography variant={'body1'} noWrap>
                    {singleClass?.max_strength}
                  </Typography>
                </Tooltip>
              </Item>
            </Stack>
          </Typography>
        }
      />
    </JumboListItem>
  );
};

export default ClassItem;
