import { useRef } from 'react';
import { useFormik } from 'formik';
import { FormControl, FormControlLabel, Grid, Switch } from '@mui/material';
import { Upload } from '@mui/icons-material';

import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Div from '@jumbo/shared/Div';
import Button from '@jumbo/components/JumboButton';
import Input from 'app/shared/components/Input';
import Typography from 'app/shared/components/Typography';
import PreviewImage from 'app/shared/components/PreviewImage';

import useStyles from './style';

import { branchSchema } from '../../utils/schema';
import { formatPhoneNumber, getPublicAsset } from 'app/utils/appHelpers';
import { uploadImage } from 'app/services/uploadImage';

const AddNewBranch = ({ data, onSave }) => {
  const inputRef = useRef(null);
  const { hideDialog } = useJumboDialog();
  const classes = useStyles();

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      name: data?.name ? data.name : '',
      contact_number: data?.contact_number ? data.contact_number : '+92',
      email: data?.email ? data.email : '',
      address: data?.address ? data.address : '',
      incharge_name: data ? data.incharge_name : '',
      details: data ? data.details : '',
      status: data ? data.status : true,
      logo: data ? getPublicAsset(data.logo) : null,
      code: data ? data.code : '',
    },
    validationSchema: branchSchema,
    onSubmit: async (values, { resetForm }) => {
      let payload = values;
      if (data?.id) {
        payload.id = data.id;
      }

      if (values?.logo && typeof values.logo != 'string') {
        const formData = new FormData();
        formData.append('image', values.logo);
        const { data } = await uploadImage(formData);
        if (data) {
          payload.logo = data?.imag;
        }
      } else {
        payload.logo = data?.logo;
      }

      onSave(payload);
      resetForm();
    },
  });

  const handlePhoneNumberChange = e => {
    const { value } = e.target;
    const formattedValue = formatPhoneNumber(value);
    setFieldValue('contact_number', formattedValue);
  };

  return (
    <FormControl className={classes.form} onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.imageWrapper}>
          <PreviewImage file={values.logo} alt="branch " />
          <input
            onChange={e => {
              setFieldValue('logo', e.target.files[0]);
            }}
            type="file"
            hidden
            ref={inputRef}
            accept="image/*"
          />
          <Typography variant="body2" textAlign={'left'} color={'red'}>
            {touched.logo && errors.logo ? errors.logo : ''}
          </Typography>
          <Button onClick={() => inputRef.current.click()}>
            <Upload sx={{ mr: 1 }} />
            Upload
          </Button>
        </Grid>

        <Grid item xs={12} md={6}>
          <Input
            handleChange={handleChange}
            value={values.name}
            name="name"
            handleBlur={handleBlur}
            label="Branch Name"
            error={touched.name && errors.name}
            helperText={touched.name && errors.name ? errors.name : ''}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Input
            handleChange={handlePhoneNumberChange}
            value={values.contact_number}
            name="contact_number"
            handleBlur={handleBlur}
            label="Branch Contact Number"
            error={touched.contact_number && errors.contact_number}
            helperText={
              touched.contact_number && errors.contact_number
                ? errors.contact_number
                : ''
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Input
            handleChange={handleChange}
            value={values.email}
            name="email"
            handleBlur={handleBlur}
            label="Branch Email"
            error={touched.email && errors.email}
            helperText={touched.email && errors.email ? errors.email : ''}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Input
            handleChange={handleChange}
            value={values.incharge_name}
            name="incharge_name"
            handleBlur={handleBlur}
            label="Branch's In Charge Name"
            error={touched.incharge_name && errors.incharge_name}
            helperText={
              touched.incharge_name && errors.incharge_name
                ? errors.incharge_name
                : ''
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Input
            handleChange={handleChange}
            value={values.address}
            name="address"
            handleBlur={handleBlur}
            label="Branch Address"
            error={touched.address && errors.address}
            helperText={touched.address && errors.address ? errors.address : ''}
            minRows={4}
            multiline
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            handleChange={handleChange}
            value={values.details}
            name="details"
            handleBlur={handleBlur}
            label="Branch Details"
            error={touched.details && errors.details}
            helperText={touched.details && errors.details ? errors.details : ''}
            minRows={4}
            multiline
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            handleChange={handleChange}
            value={values.code}
            name="code"
            handleBlur={handleBlur}
            label="Branch's code"
            error={touched.code && errors.code}
            helperText={touched.code && errors.code ? errors.code : ''}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControlLabel
            control={
              <Switch
                checked={values.status}
                onChange={e => setFieldValue('status', e.target.checked)}
              />
            }
            label={'Status of Branch?'}
            sx={{ mb: 2 }}
          />
        </Grid>

        {/* <Div className={classes.inputWrapper}>
          <DropDown
            options={[
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ]}
            label="Branch Status(Active)"
            value={values.status}
          />
          <Typography variant="body2" textAlign={'left'} color={'red'}>
            {touched.status && errors.status ? errors.status : ''}
          </Typography>
        </Div> */}
      </Grid>

      <Div className={classes.submitButtonWrapper}>
        <Button
          onClick={hideDialog}
          variant="outlined"
          color="primary"
          sx={{
            marginRight: 2,
          }}>
          Close
        </Button>
        <Button type="submit" onClick={handleSubmit} variant={'contained'}>
          Save
        </Button>
      </Div>
    </FormControl>
  );
};

export default AddNewBranch;
