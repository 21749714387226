import { useCallback, useEffect } from 'react';
import { Box, Button } from '@mui/material';

import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import InventoryList from './components/InventoryList';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import ItemForm from './components/ItemForm';
import { useDispatch, useSelector } from 'react-redux';
import { getAllItems } from 'app/store/features/itemSlice';
import { hasPermission } from 'app/utils/appHelpers';
import { PERMISSIONS, PERMISSION_TYPES } from 'app/utils/constants/permissions';

const Inventory = () => {
  const { showDialog } = useJumboDialog();
  const dispatch = useDispatch();
  const isItemsFetched = useSelector(state => state.item.isDataFetched);

  const showAddContactDialog = useCallback(() => {
    showDialog({
      title: 'Add New Item',
      content: <ItemForm />,
    });
  }, [showDialog]);

  const fetchData = useCallback(() => {
    if (!isItemsFetched) dispatch(getAllItems());
  }, [dispatch, isItemsFetched]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <JumboContentLayout
      header={
        hasPermission([PERMISSIONS.ITEM], [PERMISSION_TYPES.ADD]) && (
          <Box display={'flex'} justifyContent={'flex-end'}>
            <Button
              disableElevation
              variant={'contained'}
              startIcon={<PersonAddIcon />}
              sx={{
                mb: 2,
                '& .MuiSvgIcon-root': {
                  fontSize: '1.5rem',
                },
              }}
              onClick={showAddContactDialog}>
              Add New Item
            </Button>
          </Box>
        )
      }>
      {hasPermission([PERMISSIONS.ITEM], [PERMISSION_TYPES.LISTING]) && (
        <InventoryList />
      )}
    </JumboContentLayout>
  );
};
export default Inventory;
