import { useDispatch } from 'react-redux';

import LoadingButton from '@mui/lab/LoadingButton';

import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';

import { Stack, Typography } from '@mui/material';
import { deleteFee } from 'app/store/features/feeSlice';

const DeleteFee = ({ fee }) => {
  const dispatch = useDispatch();

  const { hideDialog } = useJumboDialog();
  const handleDeleteFee = () => {
    dispatch(deleteFee(fee.id)).then(() => hideDialog());
  };
  return (
    <Stack direction="column" gap={4}>
      <Typography>Are you sure you want to Delete fee</Typography>
      <Stack direction="row" gap={2} justifyContent="flex-end">
        <LoadingButton onClick={hideDialog} variant="outlined" size="small">
          Cancel
        </LoadingButton>

        <LoadingButton
          onClick={handleDeleteFee}
          onCanPlay={hideDialog}
          variant="contained"
          size="small">
          Confirm
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default DeleteFee;
