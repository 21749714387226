import { useState } from 'react';

import Div from '@jumbo/shared/Div';

const PreviewImage = ({ file, alt = 'preview', width = 150, height = 150 }) => {
  const [preview, setPreview] = useState(
    (file && typeof file === 'string' && file) || null,
  );

  if (file && typeof file !== 'string') {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setPreview(reader.result);
    };
  }

  return (
    <Div
      sx={{
        borderRadius: '100%',
        width: 150,
        height: 150,
        marginBottom: 2,
        overflow: 'hidden',
      }}>
      <img
        src={
          preview
            ? preview
            : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ-XVQ5ALlao-QEuE0xY9aXq0aoCMVPSy5zsWTqdADmShLB1aQB4hAxyx6L20dm1C3YpX4&usqp=CAU'
        }
        alt={alt || 'default'}
        width="100%"
        height="100%"
        style={{
          objectFit: 'cover',
        }}
      />
    </Div>
  );
};

export default PreviewImage;
