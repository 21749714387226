import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import RolesSidebar from './components/RolesSidebar';
import RolesList from './components/RolesList';

const Roles = () => {
  return (
    <JumboContentLayout sidebar={<RolesSidebar />}>
      <RolesList />
    </JumboContentLayout>
  );
};

export default Roles;
