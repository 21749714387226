import { useRef } from 'react';
import { Card, Typography, Grid } from '@mui/material';
import { Upload } from '@mui/icons-material';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import Button from '@jumbo/components/JumboButton';
import PreviewImage from 'app/shared/components/PreviewImage';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import JumboCNICField from '@jumbo/components/JumboFormik/JumboCNICField';
import { uploadImage } from 'app/services/uploadImage';
import API from 'app/services/config';
import { showAlert } from 'app/store/features/alertSlice';
import { updateProfile } from 'app/store/features/authSlice';
import { getPublicAsset } from 'app/utils/appHelpers';
import Div from '@jumbo/shared/Div';

const schema = yup.object().shape({
  name: yup.string().required('Name is Required'),
  username: yup.string().required('Username is Required'),
  password: yup
    .string()
    .min(4, 'Password must be at least 4 characters long')
    .max(255, 'Password must be less than 255 characters'),
  phone_no: yup.string().max(20).required('Phone Number is Required'),
  phone_no_2: yup.string().max(20),
  cnic: yup.string(),
});

const EditProfile = () => {
  const { user } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const inputRef = useRef(null);

  const handleSubmit = async (values, formikHelpers) => {
    try {
      if (values?.image && typeof values.image !== 'string') {
        const formData = new FormData();
        formData.append('image', values.image);
        const { data } = await uploadImage(formData);
        values.image = data.image;
      }

      if (!values.password) {
        delete values['password'];
      }

      const {
        data: { status, message },
      } = await API.put('/auth/editProfile', values);

      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(updateProfile(values));
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      console.log(error);
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };

  return (
    <Card
      sx={{
        padding: 4,
      }}>
      <Typography variant="h3" textAlign="center" mb={3} color="primary.main">
        Edit Profile
      </Typography>

      <Formik
        validateOnChange={true}
        initialValues={{
          name: user?.name || '',
          username: user?.username || '',
          password: '',
          phone_no: user?.phone_no || '',
          phone_no_2: user?.phone_no_2 || '',
          image: user?.image ? getPublicAsset(user?.image) : null,
          cnic: user?.cnic || '',
        }}
        enableReinitialize={true}
        validationSchema={schema}
        onSubmit={(values, formikHelpers) =>
          handleSubmit(values, formikHelpers)
        }>
        {({ isSubmitting, values, setFieldValue, errors, touched }) => (
          <Form autoComplete="off">
            <Grid
              container
              columnSpacing={2}
              sx={{
                '& .MuiTextField-root': {
                  my: 3,
                },
              }}>
              <Grid
                item
                xs={12}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                justifyContent={'center'}>
                <PreviewImage file={values.image} alt="profile image" />
                <input
                  onChange={e => {
                    setFieldValue('image', e.target.files[0]);
                  }}
                  type="file"
                  hidden
                  ref={inputRef}
                  accept="image/*"
                />
                <Typography variant="body2" textAlign={'left'} color={'red'}>
                  {touched.image && errors.image ? errors.image : ''}
                </Typography>
                <Button onClick={() => inputRef.current.click()} variant="text">
                  <Upload sx={{ mr: 1 }} />
                  Upload
                </Button>
              </Grid>

              <Grid item xs={12} sm={6}>
                <JumboTextField
                  size="small"
                  variant="outlined"
                  name="name"
                  label="Name*"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <JumboTextField
                  size="small"
                  variant="outlined"
                  name="username"
                  label="Username*"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} mt={-2}>
                <JumboTextField
                  size="small"
                  variant="outlined"
                  name="password"
                  label="Password"
                  fullWidth
                  type="password"
                />
              </Grid>

              <Grid item xs={12} sm={6} mt={-2}>
                <JumboTextField
                  size="small"
                  variant="outlined"
                  name="phone_no"
                  label="Phone No*"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} mt={-2}>
                <JumboTextField
                  size="small"
                  variant="outlined"
                  name="phone_no_2"
                  label="Phone No 2"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} mt={-2}>
                <JumboCNICField
                  size="small"
                  variant="outlined"
                  name="cnic"
                  label="User's CNIC"
                  fullWidth
                />
              </Grid>
            </Grid>

            <Div sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="contained" loading={isSubmitting} type="submit">
                Update Profile
              </Button>
            </Div>
          </Form>
        )}
      </Formik>
    </Card>
  );
};

export default EditProfile;
