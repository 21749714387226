import { Typography, Tooltip } from '@mui/material';
import List from '@mui/material/List';
import { Scrollbars } from 'react-custom-scrollbars-2';

import Div from '@jumbo/shared/Div';

const BranchesRevenue = ({ data }) => {
  return (
    <>
      <Typography>Branch Earnings</Typography>
      <Scrollbars style={{ height: 200, paddingTop: 10 }}>
        <List
          sx={{
            height: '200px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'space-around',
          }}>
          {data?.map(({ name, Earning }, index) => (
            <Tooltip title={`${name} made PKR. ${Earning}`} key={index}>
              <Div
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '90%',
                  cursor: 'pointer',
                }}>
                <Typography>{name}</Typography>
                <Typography>PKR. {Earning}</Typography>
              </Div>
            </Tooltip>
          ))}
        </List>
      </Scrollbars>
    </>
  );
};

export default BranchesRevenue;
