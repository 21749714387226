import React, { Fragment, useCallback, useRef, useState } from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Stack,
  Avatar,
} from '@mui/material';
import { formatDate } from '@jumbo/utils';
import { getFullName, getMonthName } from 'app/utils/appHelpers';
import { PUBLIC_ASSETS_URL } from 'app/utils/constants/api/constants';
import { StyledTableCell } from '../components/styled';
import LabelWithBoldContent from '../components/LabelWithBoldContent';
import { LoadingButton } from '@mui/lab';
import { useReactToPrint } from 'react-to-print';

const FeeDetails = ({ fees }) => {
  const [loading, setLoading] = useState();
  const componentRef = useRef(null);

  const calculateCurrentMonthDues = fee => {
    return fee?.fee_items?.reduce((total, item) => {
      if (item.name !== 'Previous balance') {
        return total + item.amount;
      }
      return total;
    }, 0);
  };

  const handleBeforePrint = useCallback(() => {
    setLoading(true);
  }, []);

  const handleAfterPrint = useCallback(() => {
    setLoading(false);
  }, []);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Fee Invoice ${fees[0]?.student?.first_name} ${
      fees[0]?.student?.last_name
    } ${formatDate(Date.now(), 'DD-MMMM-YYYY-hh:mm A')}`,
    removeAfterPrint: true,
    onBeforePrint: handleBeforePrint,
    onAfterPrint: handleAfterPrint,
  });

  return (
    <>
      <LoadingButton
        variant="contained"
        size="small"
        loading={loading}
        onClick={handlePrint}
        sx={{ position: 'absolute', top: 15 }}>
        Print
      </LoadingButton>
      <Box px={2} py={0} ref={componentRef}>
        {fees.map(fee => (
          <div
            key={fee.id}
            style={{ pageBreakAfter: 'always', marginTop: 140 }}>
            <Stack pl={2} sx={{ mt: 4 }}>
              <Typography
                variant="h1"
                textAlign={'center'}
                sx={{ fontWeight: 'medium' }}>
                Invoice Month: {`${getMonthName(fee.month)} ${fee.year}`}
              </Typography>
              <Typography textAlign={'center'} sx={{ mt: 1, mb: 2 }}>
                Dues Payable to FOTAN Central School in this month in respect of
                your child,
              </Typography>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="start"
                gap={5}
                sx={{ mt: 3 }}>
                <Avatar
                  alt={fee?.student?.first_name}
                  src={`${PUBLIC_ASSETS_URL}/${fee?.student?.image}`}
                  variant="circular"
                  sx={{
                    height: 100,
                    width: 100,
                  }}
                />
                <Box>
                  <LabelWithBoldContent
                    label={'Student ID: '}
                    content={fee.student?.registration_no}
                  />

                  <LabelWithBoldContent
                    label={"Student's Name: "}
                    content={getFullName({
                      first_name: fee.student?.first_name || '',
                      last_name: fee.student?.last_name || '',
                    })}
                  />

                  <LabelWithBoldContent
                    label={"Father's Name: "}
                    content={fee.student?.father_name}
                  />
                  <LabelWithBoldContent
                    label={'Class: '}
                    content={fee?.student?.class_section?.class}
                  />
                </Box>
              </Stack>
            </Stack>

            <Box mt={3}>
              <Typography
                variant="body1"
                sx={{ textDecoration: 'underLine', mb: 2 }}>
                Details of account is given below
              </Typography>
              <TableContainer sx={{ boxShadow: 0 }}>
                <Table sx={{ minWidth: 650 }}>
                  <TableHead>
                    <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                      <StyledTableCell>#</StyledTableCell>
                      <StyledTableCell>Fee Name</StyledTableCell>
                      <StyledTableCell>Quantity</StyledTableCell>
                      <StyledTableCell>UP</StyledTableCell>
                      <StyledTableCell>TP</StyledTableCell>
                      <StyledTableCell>Date</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fee.fee_items.map((item, index) => (
                      <TableRow key={item?.id}>
                        <StyledTableCell>{index + 1}</StyledTableCell>
                        <StyledTableCell>{item?.name}</StyledTableCell>
                        <StyledTableCell>{item?.quantity}</StyledTableCell>
                        <StyledTableCell>
                          {item?.amount / item?.quantity}
                        </StyledTableCell>
                        <StyledTableCell>{item?.amount}</StyledTableCell>
                        <StyledTableCell>
                          {formatDate(item.createdAt, 'DD-MMMM-YYYY')}
                        </StyledTableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <StyledTableCell colSpan={4} sx={{ fontWeight: 'bold' }}>
                        Sub Total for this Month
                      </StyledTableCell>
                      <StyledTableCell sx={{ fontWeight: 'bold' }}>
                        {calculateCurrentMonthDues(fee)}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell colSpan={4} sx={{ fontWeight: 'bold' }}>
                        Grand Total
                      </StyledTableCell>
                      <StyledTableCell sx={{ fontWeight: 'bold' }}>
                        {fee.total}
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Typography textAlign={'center'} fontWeight={'bold'} mt={2}>
                Please Pay the above mentioned dues as soon as possible
              </Typography>
              <Typography textAlign={'center'}>
                Errors and mistakes are acceptable. Please don't hesitate to
                contact FOTAN Office, if you find any
              </Typography>
            </Box>
          </div>
        ))}
      </Box>
    </>
  );
};
export default FeeDetails;
