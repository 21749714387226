import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  API_DELETE,
  API_GET,
  API_POST,
} from 'app/utils/constants/api/apiRequests';
import { setLoading, setLoadingFinished } from './loadingSlice';
import { showAlert } from './alertSlice';

const initialState = {
  assignedItems: [],
  isDataFetched: false,
};

export const assignItem = createAsyncThunk(
  'assignItem',
  async (payload, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, message } = await API_POST('/student-item', payload);
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAssignedItems());
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const getAssignedItems = createAsyncThunk(
  'getAssignedItems',
  async (_, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data, message } = await API_GET('/student-item');

      if (status) {
        return data;
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const deleteAssignedItem = createAsyncThunk(
  'deleteAssignedItem',
  async (id, { dispatch }) => {
    dispatch(setLoading());
    try {
      const { status, data, message } = await API_DELETE('/student-item', id);
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        dispatch(getAssignedItems());
        return data;
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      dispatch(showAlert({ type: 'error', message: error.message }));
    } finally {
      dispatch(setLoadingFinished());
    }
  },
);

export const itemSlice = createSlice({
  name: 'assignedItems',
  initialState,
  extraReducers: builder => {
    builder.addCase(getAssignedItems.fulfilled, (state, { payload }) => {
      state.assignedItems = payload;
      state.isDataFetched = true;
    });
  },
});

export default itemSlice.reducer;
