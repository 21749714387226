import { configureStore } from '@reduxjs/toolkit';

import alertReducer from './features/alertSlice';
import accountsReducer from './features/accountsSlice';
import authReducer from './features/authSlice';
import classesReducer from './features/classesSlice';
import studentsReducer from './features/studentsSlice';
import usersReducer from './features/usersSlice';
import feeReducer from './features/feesSlice';
import inventoryReducer from './features/inventorySlice';
import branchesReducer from './features/branchSlice';
import expenseReducer from './features/expenseSlice';
import subjectsReducer from './features/subjectsSlice';
import examinationReducer from './features/examinationSlice';
import itemReducer from './features/itemSlice';
import assignedItemsSliceReducer from './features/assignedItemsSlice';
import nonExpensePaymentsReducer from './features/nonExpenseSlice';
import feeSlice from './features/feeSlice';
import studentItemSlice from './features/studentItemSlice';
import rolesSlice from './features/rolesSlice';
import configurationSlice from './features/configurationSlice';
import reportSlice from './features/reportSlice';

export default configureStore({
  reducer: {
    alert: alertReducer,
    accounts: accountsReducer,
    auth: authReducer,
    classes: classesReducer,
    students: studentsReducer,
    users: usersReducer,
    fees: feeReducer,
    inventory: inventoryReducer,
    branches: branchesReducer,
    expense: expenseReducer,
    subjects: subjectsReducer,
    examination: examinationReducer,
    item: itemReducer,
    assignedItems: assignedItemsSliceReducer,
    nonExpensePayments: nonExpensePaymentsReducer,
    fee: feeSlice,
    studentItem: studentItemSlice,
    roles: rolesSlice,
    configuration: configurationSlice,
    report: reportSlice,
  },
});
