import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import NonExpenseReportList from './List';
import { hasAnyPermissions } from 'app/utils/appHelpers';
import { PERMISSIONS } from 'app/utils/constants/permissions';

const NonExpenseReports = () => {
  return (
    <JumboContentLayout>
      {hasAnyPermissions([PERMISSIONS.FEE]) && <NonExpenseReportList />}
    </JumboContentLayout>
  );
};
export default NonExpenseReports;
