import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Form, Formik } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import Div from '@jumbo/shared/Div';
import JumboDropDown from '@jumbo/components/JumboFormik/JumboDropDown';

import { billSchema } from '../../utils/schema';

import { GENERIC_YES_NO, BILL_STATUS } from 'app/shared/constants';
import { getAllBranches } from 'app/store/features/branchSlice';
import { getAllUsers } from 'app/store/features/usersSlice';
import { addNewBill } from 'app/store/features/expenseSlice';
import BillExpense from './BillExpense/BillExpense';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import BillExpensesList from './BillExpensesList';
import { Box, Card, Typography } from '@mui/material';
import Button from '@jumbo/components/JumboButton';
import { hasPermission } from 'app/utils/appHelpers';
import { PERMISSIONS, PERMISSION_TYPES } from 'app/utils/constants/permissions';

const BillForm = ({ expenseData }) => {
  const { showDialog } = useJumboDialog();

  const [users, setUsers] = useState([]);
  const [billExpenses, setBillExpenses] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const storeBranches = useSelector(state => state.branches.branches);
  const isBranchesFetched = useSelector(state => state.branches.isDataFetched);
  const storeUsers = useSelector(state => state.users.users);
  const isUsersFetched = useSelector(state => state.users.isDataFetched);
  const user = useSelector(state => state.auth.user);

  const fetchData = useCallback(async () => {
    if (!isBranchesFetched) dispatch(getAllBranches());
    if (!isUsersFetched) dispatch(getAllUsers());
  }, [dispatch, isBranchesFetched, isUsersFetched]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const filtered = storeUsers?.filter(user => user.role === 'vendor');
    setUsers(filtered);
  }, [storeUsers]);

  const isUpdate = expenseData ? true : false;
  const initialValues = {
    branch_id: isUpdate
      ? expenseData.branch?.branch_id
      : !hasPermission([PERMISSIONS.BRANCH], [PERMISSION_TYPES.LISTING])
        ? user?.branch_id
        : '',
    vendor_id: '',
    paid_to: '',
    bill_date: '',
    paid_by_other: '',
    bill_amount: '',
    paid_bill_amount: '',
    balance: '',
    status: '',
  };

  const billExpense = useCallback(() => {
    showDialog({
      title: 'Add Bill Expenses',
      content: (
        <BillExpense setExpenses={setBillExpenses} expenses={billExpenses} />
      ),
    });
  }, [billExpenses, showDialog]);

  const addBill = data => {
    if (!billExpenses.length) {
      alert('Please add Bill Expenses');
      return;
    }

    const newExpenses = billExpenses.map(expense => {
      const { id, ...rest } = expense;
      return rest;
    });
    const payload = {
      ...data,
      paid_by_other: data.paid_by_other.toLowerCase() === 'yes' ? true : false,
      bill_expenses: newExpenses,
      balance: data.bill_amount - data.paid_bill_amount,
    };
    if (dispatch(addNewBill(payload)).unwrap()) navigate('/app/accounts/all');
  };

  const updateBill = data => {
    // console.log(data);
    // hideDialog();
  };

  const onClassSave = data => {
    isUpdate ? updateBill(data) : addBill(data);
  };
  return (
    <Card sx={{ padding: 4 }}>
      <Typography variant="h3" textAlign="center" mb={3} color="primary.main">
        Add New Bill
      </Typography>

      <Formik
        validateOnChange={true}
        initialValues={expenseData?.id ? expenseData : initialValues}
        enableReinitialize={true}
        validationSchema={billSchema}
        onSubmit={onClassSave}>
        {({ values }) => (
          <Form noValidate autoComplete="off">
            <Div
              sx={{
                '& .MuiTextField-root': {
                  mb: 3,
                },
              }}>
              <JumboDropDown
                fullWidth
                size="small"
                variant="outlined"
                name="branch_id"
                label="Please Select Branch"
                options={storeBranches}
                disabled={
                  !hasPermission(
                    [PERMISSIONS.BRANCH],
                    [PERMISSION_TYPES.LISTING],
                  )
                }
              />
              <JumboDropDown
                fullWidth
                size="small"
                variant="outlined"
                name="vendor_id"
                label="Please Select Vendor"
                options={users}
              />
              <JumboTextField
                name="bill_date"
                label="Bill Date"
                variant="outlined"
                margin="normal"
                fullWidth
                type="date"
                InputProps={{
                  inputProps: { min: '1990-01-01', max: '9999-01-01' },
                }}
              />
              <JumboTextField
                name="paid_to"
                label="Paid To"
                variant="outlined"
                margin="normal"
                fullWidth
              />
              <JumboTextField
                name="bill_amount"
                label="Bill Amount"
                variant="outlined"
                margin="normal"
                fullWidth
                type="number"
              />
              <JumboTextField
                name="paid_bill_amount"
                label="Paid Bill Amount"
                variant="outlined"
                margin="normal"
                fullWidth
                type="number"
              />
              <JumboTextField
                name="balance"
                label={`Balance: ${
                  values.bill_amount - values.paid_bill_amount
                }`}
                variant="outlined"
                margin="normal"
                fullWidth
                type="number"
                disabled={true}
                placeholder={`Balance: ${
                  values.bill_amount - values.paid_bill_amount
                }`}
              />
              <JumboDropDown
                fullWidth
                size="small"
                variant="outlined"
                name="paid_by_other"
                label="Is bill paid by Someone else?"
                options={GENERIC_YES_NO}
              />
              <JumboDropDown
                fullWidth
                size="small"
                variant="outlined"
                name="status"
                label="Please Select Bill Status"
                options={BILL_STATUS}
              />
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'flex-end'}>
                <Button
                  variant="outlined"
                  sx={{ marginBottom: 2 }}
                  onClick={billExpense}>
                  Add Expense
                </Button>
              </Box>
              <BillExpensesList
                expenses={billExpenses}
                setExpenses={setBillExpenses}
              />
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'flex-end'}>
                <Button type="submit" variant="contained">
                  Add Bill
                </Button>
              </Box>
            </Div>
          </Form>
        )}
      </Formik>
    </Card>
  );
};

export default BillForm;
