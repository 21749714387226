import { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';
import { Card } from '@mui/material';

import JumboRqList from '@jumbo/components/JumboReactQuery/JumboRqList';
import JumboListToolbar from '@jumbo/components/JumboList/components/JumboListToolbar';
import JumboSearch from '@jumbo/components/JumboSearch';

import ClassItem from './ClassItem';

const ClassesList = () => {
  const { category } = useParams();
  const listRef = useRef();
  const classes = useSelector(state => state.classes.classes);

  const [classesToShow, setClassesToShow] = useState(classes);

  useEffect(() => {
    setClassesToShow(classes);
  }, [classes]);

  useEffect(() => {
    if (category === 'all') {
      setClassesToShow(classes);
    } else {
      const filteredClasses = classes?.filter(singleClass => {
        const branchName = singleClass.branch.name;
        if (category === branchName?.toLowerCase()) {
          return singleClass;
        }
        return null;
      });
      setClassesToShow(filteredClasses);
    }
  }, [category, classes]);

  const renderClass = useCallback(contact => {
    return <ClassItem singleClass={contact} view={'list'} />;
  }, []);

  const handleOnChange = useCallback(
    keyword => {
      let filteredClasses = [];
      filteredClasses = classes?.filter(singleClass => {
        return (
          singleClass?.name?.toLowerCase().search(keyword?.toLowerCase()) !== -1
        );
      });
      setClassesToShow(filteredClasses);
    },
    [classes],
  );

  return (
    <JumboRqList
      ref={listRef}
      data={classesToShow}
      wrapperComponent={Card}
      primaryKey={'id'}
      itemsPerPage={50}
      itemsPerPageOptions={[8, 15, 20]}
      renderItem={renderClass}
      componentElement={'div'}
      isClasses
      // sx={view === 'grid' && { p: theme => theme.spacing(1, 3, 3) }}
      wrapperSx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
      toolbar={
        <JumboListToolbar hideItemsPerPage={true}>
          <JumboSearch
            onChange={handleOnChange}
            sx={{
              display: { xs: 'none', md: 'block' },
            }}
          />
        </JumboListToolbar>
      }
      view={'list'}
    />
  );
};

export default ClassesList;
