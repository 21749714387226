import React, { useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { Contacts, Stars } from '@mui/icons-material';

import BranchItem from './BranchItem';
import StyledMenu from 'app/shared/StyledMenu';
import { Typography } from '@mui/material';
import { getAllBranches } from 'app/store/features/branchSlice';
import { isSuperAdmin } from 'app/utils/appHelpers';

const FoldersList = ({ title = 'All Students', identifier = 'student' }) => {
  const folder = {
    icon: <Contacts fontSize={'small'} />,
    label: title,
    slug: 'all',
    path: `/app/${identifier}/all`,
  };

  const dispatch = useDispatch();
  const params = useParams();
  const storeBranches = useSelector(state => state.branches.branches);
  const isBranchesFetched = useSelector(state => state.branches.isDataFetched);

  const fetchBranches = useCallback(async () => {
    if (!isBranchesFetched) {
      dispatch(getAllBranches());
    }
  }, [dispatch, isBranchesFetched]);

  const branches = [];
  for (let i = 0; i < storeBranches.length; i++) {
    const link = `/app/${identifier.toLowerCase()}/${storeBranches[i].name}`;
    branches.push({
      icon: <Stars fontSize={'small'} />,
      label: storeBranches[i].name,
      slug: storeBranches[i].name,
      path: link.toLocaleLowerCase(),
    });
  }

  useEffect(() => {
    fetchBranches();
  }, [fetchBranches]);

  return (
    <>
      {isSuperAdmin() && (
        <StyledMenu sx={{ mb: 2 }}>
          <BranchItem
            key={folder.slug}
            path={folder.path}
            label={folder.label}
            icon={folder.icon}
            selected={folder.slug === params?.category}
          />

          <Typography sx={{ fontSize: 18, margin: '10px 0' }}>
            Branches
          </Typography>
          {branches?.map(({ slug, path, label, icon }) => {
            const temp = slug;

            return (
              <BranchItem
                key={slug}
                path={path}
                label={label}
                icon={icon}
                selected={temp.toLowerCase() === params?.category}
              />
            );
          })}
        </StyledMenu>
      )}
    </>
  );
};

export default React.memo(FoldersList);
