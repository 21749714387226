import React from 'react';
import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';

import { LocationOn, LocalPhoneOutlined } from '@mui/icons-material';

const Contacts = ({ teacher }) => {
  return (
    <JumboCardQuick title={'Contact'} noWrapper>
      <List disablePadding sx={{ mb: 2 }}>
        <ListItem
          alignItems="flex-start"
          sx={{ p: theme => theme.spacing(0.5, 3) }}>
          <ListItemIcon sx={{ minWidth: 36, color: 'text.secondary' }}>
            <LocalPhoneOutlined />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1" color="text.secondary">
                Phone Number
              </Typography>
            }
            secondary={<>{teacher?.phoneNumber}</>}
          />
        </ListItem>

        <ListItem
          alignItems="flex-start"
          sx={{ p: theme => theme.spacing(0.5, 3) }}>
          <ListItemIcon sx={{ minWidth: 36, color: 'text.secondary' }}>
            <LocalPhoneOutlined />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1" color="text.secondary">
                Secondary Contact
              </Typography>
            }
            secondary={<>{teacher?.otherContact}</>}
          />
        </ListItem>

        <ListItem
          alignItems="flex-start"
          sx={{ p: theme => theme.spacing(0.5, 3) }}>
          <ListItemIcon sx={{ minWidth: 36, color: 'text.secondary' }}>
            <LocationOn />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1" color="text.secondary">
                Address
              </Typography>
            }
            secondary={<>{teacher?.address}</>}
          />
        </ListItem>
      </List>
    </JumboCardQuick>
  );
};

export default Contacts;
