import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import Div from '@jumbo/shared/Div';

const Header = ({ title = 'Title of the Sections', link = '/' }) => {
  return (
    <Div
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
      <Typography variant="h3">{title}</Typography>
      <Link to={link}>View Details</Link>
    </Div>
  );
};

export default Header;
