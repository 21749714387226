import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import { getExamInitiators } from 'app/store/features/examinationSlice';
import ExaminationList from '../components/ExamInitiatorList';
import ExamInitiatorSidebar from '../components/ExamInitiatorSidebar';
import { hasPermission } from 'app/utils/appHelpers';
import { PERMISSIONS, PERMISSION_TYPES } from 'app/utils/constants/permissions';

const ExamInitiator = () => {
  const dispatch = useDispatch();
  const isDataFetched = useSelector(state => state.examination.isDataFetched);

  const fetchData = useCallback(() => {
    if (!isDataFetched) dispatch(getExamInitiators());
  }, [dispatch, isDataFetched]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <JumboContentLayout
        sidebar={
          hasPermission(
            [PERMISSIONS.EXAM_INITIATOR],
            [PERMISSION_TYPES.ADD],
          ) && <ExamInitiatorSidebar />
        }>
        {hasPermission(
          [PERMISSIONS.EXAM_INITIATOR],
          [PERMISSION_TYPES.LISTING],
        ) && <ExaminationList />}
      </JumboContentLayout>
    </>
  );
};
export default ExamInitiator;
