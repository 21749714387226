import * as yup from 'yup';

export const nonExpensePaymentSchema = yup.object().shape({
  paid_by_id: yup.string(),
  paid_to: yup.string().required('Paid To is required'),
  paid_amount: yup
    .number()
    .positive('Paid Amount must be positive')
    .min(10, 'Paid Amount must be greater than Rs.10')
    .required('Paid Amount is Required'),
  payment_mode: yup.string().required('Payment Mode is Required'),
  description: yup.string().required('Description is Required'),
  is_approved: yup
    .boolean('Is Approved must be Yes/No')
    .required('Is Expense Approved?'),
});
