import { useCallback, memo } from 'react';
import { Typography, Button } from '@mui/material';
import { PersonAdd } from '@mui/icons-material';

import Div from '@jumbo/shared/Div';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import AddNonExpensePaymentForm from '../NonExpensePaymentForm';

const NonExpenseSidebar = () => {
  const { showDialog } = useJumboDialog();

  const showAddNonExpensePayment = useCallback(() => {
    showDialog({
      title: 'Add Non Expense Payment',
      content: <AddNonExpensePaymentForm />,
    });
  }, [showDialog]);
  return (
    <>
      <Div
        sx={{
          display: 'flex',
          alignItems: 'center',
          mb: 3,
        }}>
        <Div sx={{ flex: '1 1 auto' }}>
          <Typography variant={'h2'} mb={0.35}>
            Non Expense Sidebar
          </Typography>
        </Div>
      </Div>
      <Button
        fullWidth
        disableElevation
        variant={'contained'}
        startIcon={<PersonAdd />}
        sx={{
          mb: 2,
          '& .MuiSvgIcon-root': {
            fontSize: '1.5rem',
          },
        }}
        onClick={showAddNonExpensePayment}>
        Add Expense
      </Button>
    </>
  );
};
export default memo(NonExpenseSidebar);
