import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import Div from '@jumbo/shared/Div';
import BranchesList from 'app/shared/components/BranchesList';

const StudentsSidebar = () => {
  const navigate = useNavigate();

  const showAddStudentDialog = useCallback(() => {
    navigate('/app/students/new');
  }, [navigate]);

  return (
    <>
      <Div
        sx={{
          display: 'flex',
          alignItems: 'center',
          mb: 3,
        }}>
        <Div sx={{ flex: '1 1 auto' }}>
          <Typography variant={'h2'} mb={0.35}>
            Students
          </Typography>
        </Div>
      </Div>

      <Button
        fullWidth
        disableElevation
        variant={'contained'}
        startIcon={<PersonAddIcon />}
        sx={{
          mb: 2,
          '& .MuiSvgIcon-root': {
            fontSize: '1.5rem',
          },
        }}
        onClick={showAddStudentDialog}>
        Add New Student
      </Button>
      <BranchesList identifier="students" title="All Students" />
    </>
  );
};
export default React.memo(StudentsSidebar);
