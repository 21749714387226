import { createSlice } from '@reduxjs/toolkit';
import { getDecodedToken } from 'app/utils/appHelpers';

// local storage
const token = localStorage.getItem('fotanistanAccessToken');
const user = getDecodedToken();
const userTheme = JSON.parse(localStorage.getItem('fotanistanUserTheme'));

const initialState = {
  user: user ? user : {},
  token,
  userTheme: userTheme ? userTheme : {},
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logOut: state => {
      state.token = null;
      state.user = {};
      localStorage.removeItem('fotanistanUser');
      localStorage.removeItem('fotanistanAccessToken');
    },
    updateProfile: (state, { payload }) => {
      const updatedUser = { ...state.user, ...payload };
      state.user = updatedUser;
      localStorage.setItem('fotanistanUser', JSON.stringify(updatedUser));
    },
    addUser: (state, { payload: { token, user } }) => {
      state.token = token;
      state.user = user;
    },
    updateUserTheme: (state, { payload }) => {
      const updatedTheme = { ...state.userTheme, ...payload };
      state.userTheme = updatedTheme;
      localStorage.setItem('fotanistanUserTheme', JSON.stringify(updatedTheme));
    },
  },
  extraReducers: builder => {},
});

export const { logOut, updateProfile, addUser, updateUserTheme } =
  authSlice.actions;

export default authSlice.reducer;
