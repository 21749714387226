import {
  Typography,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import { BrandingWatermark, Person, School } from '@mui/icons-material';
import styled from '@emotion/styled';

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 24,
  height: 48,
  width: 48,
  borderRadius: '50%',
  minWidth: 42,
  marginRight: 16,
  padding: theme.spacing(1),
  alignItems: 'center',
  justifyContent: 'center',
  border: `solid 1px ${theme.palette.divider}`,
}));

const ParentalOverview = ({ student }) => {
  return (
    <>
      <ListItem
        sx={{
          width: { xs: '100%', sm: '50%', xl: '33.33%' },
        }}>
        <StyledListItemIcon>
          <Person fontSize={'inherit'} />
        </StyledListItemIcon>
        <ListItemText
          primary={
            <Typography
              fontSize={'12px'}
              variant="h6"
              color="text.secondary"
              mb={0.5}>
              Father Name
            </Typography>
          }
          secondary={
            <Typography variant="body1" color="text.primary">
              {student?.father_name}
            </Typography>
          }
        />
      </ListItem>
      <ListItem
        sx={{
          width: { xs: '100%', sm: '50%', xl: '33.33%' },
        }}>
        <StyledListItemIcon>
          <BrandingWatermark fontSize={'inherit'} />
        </StyledListItemIcon>
        <ListItemText
          primary={
            <Typography
              fontSize={'12px'}
              variant="h6"
              color="text.secondary"
              mb={0.5}>
              Father CNIC
            </Typography>
          }
          secondary={
            <Typography variant="body1" color="text.primary">
              {student?.father_cnic}
            </Typography>
          }
        />
      </ListItem>
      <ListItem
        sx={{
          width: { xs: '100%', sm: '50%', xl: '33.33%' },
        }}>
        <StyledListItemIcon>
          <School fontSize={'inherit'} />
        </StyledListItemIcon>
        <ListItemText
          primary={
            <Typography
              fontSize={'12px'}
              variant="h6"
              color="text.secondary"
              mb={0.5}>
              Father Qualification
            </Typography>
          }
          secondary={
            <Typography variant="body1" color="text.primary">
              {student?.father_qualification}
            </Typography>
          }
        />
      </ListItem>
      <ListItem
        sx={{
          width: { xs: '100%', sm: '50%', xl: '33.33%' },
        }}>
        <StyledListItemIcon>
          <Person fontSize={'inherit'} />
        </StyledListItemIcon>
        <ListItemText
          primary={
            <Typography
              fontSize={'12px'}
              variant="h6"
              color="text.secondary"
              mb={0.5}>
              Mother Name
            </Typography>
          }
          secondary={
            <Typography variant="body1" color="text.primary">
              {student?.mother_name}
            </Typography>
          }
        />
      </ListItem>
      <ListItem
        sx={{
          width: { xs: '100%', sm: '50%', xl: '33.33%' },
        }}>
        <StyledListItemIcon>
          <BrandingWatermark fontSize={'inherit'} />
        </StyledListItemIcon>
        <ListItemText
          primary={
            <Typography
              fontSize={'12px'}
              variant="h6"
              color="text.secondary"
              mb={0.5}>
              Mother CNIC
            </Typography>
          }
          secondary={
            <Typography variant="body1" color="text.primary">
              {student?.mother_cnic}
            </Typography>
          }
        />
      </ListItem>
      <ListItem
        sx={{
          width: { xs: '100%', sm: '50%', xl: '33.33%' },
        }}>
        <StyledListItemIcon>
          <School fontSize={'inherit'} />
        </StyledListItemIcon>
        <ListItemText
          primary={
            <Typography
              fontSize={'12px'}
              variant="h6"
              color="text.secondary"
              mb={0.5}>
              Mother's Qualification
            </Typography>
          }
          secondary={
            <Typography variant="body1" color="text.primary">
              {student?.mother_qualification}
            </Typography>
          }
        />
      </ListItem>
    </>
  );
};

export default ParentalOverview;
