import React from 'react';
import Div from '@jumbo/shared/Div';
import { Link } from 'react-router-dom';
import { ASSET_IMAGES } from '../../utils/constants/paths';

const Logo = ({ mini, mode, sx }) => {
  return (
    <Div sx={{ display: 'inline-flex', ...sx }}>
      <Link to={'/dashboards/analytics'}>
        <div
          style={{
            width: 140,
            maxHeight: 100,
          }}>
          {!mini ? (
            <img
              src={
                mode === 'light'
                  ? `${ASSET_IMAGES}/logo.png`
                  : `${ASSET_IMAGES}/logo.png`
              }
              alt="FOTAN"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
          ) : (
            <img
              src={
                mode === 'light'
                  ? `${ASSET_IMAGES}/logo-short.png`
                  : `${ASSET_IMAGES}/logo-short-white.png`
              }
              alt="FOTAN"
            />
          )}
        </div>
      </Link>
    </Div>
  );
};

Logo.defaultProps = {
  mode: 'light',
};

export default Logo;
