import React from 'react';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CardHeader from '@mui/material/CardHeader';
import {
  alpha,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Divider,
} from '@mui/material';

import { Book, Category, Inventory, PriceChange } from '@mui/icons-material';

import List from '@mui/material/List';
import Div from '@jumbo/shared/Div';

const ItemDetail = ({ item, onClose }) => {
  return (
    <Div sx={{ m: theme => theme.spacing(-2.5, -3) }}>
      <CardHeader
        title={item?.name}
        subheader={item?.branchOwned}
        avatar={<Avatar src={item?.profile_pic} />}
        action={
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        }
      />
      <List disablePadding>
        <ListItem sx={{ px: 4 }}>
          <ListItemAvatar sx={{ minWidth: 66 }}>
            <Avatar
              variant="rounded"
              sx={{
                height: 48,
                width: 48,
                bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
              }}>
              <Category sx={{ color: 'primary.light' }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant={'body1'} color={'text.secondary'} mb={0.5}>
                Group
              </Typography>
            }
            secondary={
              <Typography variant={'body2'} mb={0}>
                {item?.group}
              </Typography>
            }
          />
        </ListItem>
        <Divider component={'li'} />

        <ListItem sx={{ px: 4 }}>
          <ListItemAvatar sx={{ minWidth: 66 }}>
            <Avatar
              variant="rounded"
              sx={{
                height: 48,
                width: 48,
                bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
              }}>
              <Book sx={{ color: 'primary.light' }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant={'body1'} color={'text.secondary'} mb={0.5}>
                Description
              </Typography>
            }
            secondary={
              <Typography variant={'body2'} mb={0}>
                {item?.description}
              </Typography>
            }
          />
        </ListItem>
        <Divider component={'li'} />

        <ListItem sx={{ px: 4 }}>
          <ListItemAvatar sx={{ minWidth: 66 }}>
            <Avatar
              variant="rounded"
              sx={{
                height: 48,
                width: 48,
                bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
              }}>
              <PriceChange sx={{ color: 'primary.light' }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant={'body1'} color={'text.secondary'} mb={0.5}>
                Price
              </Typography>
            }
            secondary={
              <Typography variant={'body2'} mb={0}>
                {item?.price}
              </Typography>
            }
          />
        </ListItem>
        <Divider component={'li'} />

        <ListItem sx={{ px: 4 }}>
          <ListItemAvatar sx={{ minWidth: 66 }}>
            <Avatar
              variant="rounded"
              sx={{
                height: 48,
                width: 48,
                bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
              }}>
              <Inventory sx={{ color: 'primary.light' }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant={'body1'} color={'text.secondary'} mb={0.5}>
                Is Item Stockable
              </Typography>
            }
            secondary={
              <Typography variant={'body2'} mb={0}>
                {item?.is_stockable ? 'Yes' : 'No'}
              </Typography>
            }
          />
        </ListItem>
        <Divider component={'li'} />

        {item?.current_stock && (
          <>
            <ListItem sx={{ px: 4 }}>
              <ListItemAvatar sx={{ minWidth: 66 }}>
                <Avatar
                  variant="rounded"
                  sx={{
                    height: 48,
                    width: 48,
                    bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
                  }}>
                  <Category sx={{ color: 'primary.light' }} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography
                    variant={'body1'}
                    color={'text.secondary'}
                    mb={0.5}>
                    Current Stock
                  </Typography>
                }
                secondary={
                  <Typography variant={'body2'} mb={0}>
                    {item?.current_stock}
                  </Typography>
                }
              />
            </ListItem>
            <Divider component={'li'} />
          </>
        )}

        <ListItem sx={{ px: 4 }}>
          <ListItemAvatar sx={{ minWidth: 66 }}>
            <Avatar
              variant="rounded"
              sx={{
                height: 48,
                width: 48,
                bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
              }}>
              <Category sx={{ color: 'primary.light' }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant={'body1'} color={'text.secondary'} mb={0.5}>
                Item Minimum Threshhold
              </Typography>
            }
            secondary={
              <Typography variant={'body2'} mb={0}>
                {item?.min_threshhold}
              </Typography>
            }
          />
        </ListItem>
        <Divider component={'li'} />

        <ListItem sx={{ px: 4 }}>
          <ListItemAvatar sx={{ minWidth: 66 }}>
            <Avatar
              variant="rounded"
              sx={{
                height: 48,
                width: 48,
                bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
              }}>
              <Category sx={{ color: 'primary.light' }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant={'body1'} color={'text.secondary'} mb={0.5}>
                User Item Code
              </Typography>
            }
            secondary={
              <Typography variant={'body2'} mb={0}>
                {item?.user_item_code}
              </Typography>
            }
          />
        </ListItem>
        <Divider component={'li'} />

        <ListItem sx={{ px: 4 }}>
          <ListItemAvatar sx={{ minWidth: 66 }}>
            <Avatar
              variant="rounded"
              sx={{
                height: 48,
                width: 48,
                bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
              }}>
              <Category sx={{ color: 'primary.light' }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant={'body1'} color={'text.secondary'} mb={0.5}>
                Item Status(Active)
              </Typography>
            }
            secondary={
              <Typography variant={'body2'} mb={0}>
                {item?.status ? 'Yes' : 'No'}
              </Typography>
            }
          />
        </ListItem>
        <Divider component={'li'} />
      </List>
    </Div>
  );
};

export default ItemDetail;
