import * as yup from 'yup';

import { IMAGE_SUPPORTED_FORMATS } from 'app/shared/constants';

export const branchSchema = yup.object().shape({
  name: yup
    .string()
    .max(50, 'Branch Name should be less than 50 characters')
    .required('Branch Name is Required'),
  logo: yup
    .mixed()
    .nullable()
    .test(
      'FILE_OR_STRING',
      'Logo must be a file (for new entries) or a valid URL (for edits)',
      value => {
        if (typeof value === 'string') {
          return /^(https?:\/\/).*/.test(value);
        } else if (typeof value === 'object') {
          return (
            value &&
            value.size <= 1024 * 1024 &&
            IMAGE_SUPPORTED_FORMATS.includes(value?.type)
          );
        }
        return true;
      },
    ),
  incharge_name: yup
    .string()
    .max(50, 'Name should be less than 50 characters')
    .required('Branch InCharge is Required'),
  contact_number: yup.string().required('Contact Number is Required'),
  email: yup
    .string()
    .email('Please enter correct Email')
    .required('Email is Required'),
  address: yup.string().required('Address is Required'),
  details: yup.string(),
  code: yup.string().required('Branch Code is Required'),
});
