import { useDispatch } from 'react-redux';
import CustomTypography from 'app/shared/components/Typography';

import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import BranchCard from './BranchCard';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import AddNewBranch from '../AddNewBranch';
import useStyles from './style';
import { editBranch, deleteBranch } from 'app/store/features/branchSlice';
import Div from '@jumbo/shared/Div/Div';

const AllBranches = ({ branches }) => {
  const classes = useStyles();
  const swal = useSwalWrapper();
  const dispatch = useDispatch();
  const { showDialog, hideDialog } = useJumboDialog();

  const handleDeleteHandler = async key => {
    dispatch(deleteBranch(key));
    hideDialog();
  };

  const handleDelete = key => {
    swal
      .fire({
        title: `Do you really want to delete to this Branch?`,
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete it!',
        cancelButtonText: 'No, Cancel!',
        reverseButtons: true,
      })
      .then(result => {
        if (result.value) {
          handleDeleteHandler(key);
        } else if (result.dismiss === swal.DismissReason.cancel) {
          swal.fire('Cancelled', 'Your Branch is safe :)', 'error');
        }
      });
  };

  const handleEditHandler = async payload => {
    if (typeof payload.image !== 'string') {
      delete payload['image'];
    } else {
      // TODO: upload image to server
    }
    dispatch(editBranch(payload));
    hideDialog();
  };

  const handleEdit = data => {
    showDialog({
      title: 'Edit Branch',
      content: <AddNewBranch onSave={handleEditHandler} data={data} />,
    });
  };

  return (
    <Div sx={{ mt: 3 }}>
      {branches.length ? (
        branches?.map(branch => (
          <BranchCard
            key={branch.id}
            name={branch.name}
            logo={branch.logo}
            address={branch.address}
            noOfStudents={branch.noOfStudents}
            inChargeName={branch.incharge_name}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
            id={branch.id}
            branch={branch}
            contactNumber={branch.contact_number}
            email={branch.email}
            code={branch.code}
            details={branch.details}
            status={branch.status ? 'Active' : 'InActive'}
            createdAt={branch.createdAt}
          />
        ))
      ) : (
        <CustomTypography className={classes.noBranchHeading} variant="h3">
          No Branch To Show!{' '}
        </CustomTypography>
      )}
    </Div>
  );
};

export default AllBranches;
