import { useCallback, useEffect } from 'react';
import { Box, Button } from '@mui/material';

import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import { useDispatch, useSelector } from 'react-redux';
import AssignItemForm from '../components/AssignItemForm';
import { getAssignedItems } from 'app/store/features/assignedItemsSlice';
import AssignedItemsList from '../components/AssignedItemsList';
import { hasPermission } from 'app/utils/appHelpers';
import { PERMISSIONS, PERMISSION_TYPES } from 'app/utils/constants/permissions';

const StudentsInventory = () => {
  const { showDialog } = useJumboDialog();
  const dispatch = useDispatch();
  const isItemsFetched = useSelector(
    state => state.assignedItems.isDataFetched,
  );

  const showAddContactDialog = useCallback(() => {
    showDialog({
      title: 'Assign Item Form',
      content: <AssignItemForm />,
      showCloseButton: true,
    });
  }, [showDialog]);

  const fetchData = useCallback(() => {
    if (!isItemsFetched) dispatch(getAssignedItems());
  }, [dispatch, isItemsFetched]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <JumboContentLayout
      header={
        hasPermission([PERMISSIONS.STUDENT_ITEM], [PERMISSION_TYPES.ADD]) && (
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'flex-end'}>
            <Button
              disableElevation
              variant={'contained'}
              startIcon={<PersonAddIcon />}
              sx={{
                mb: 2,
                '& .MuiSvgIcon-root': {
                  fontSize: '1.5rem',
                },
              }}
              onClick={showAddContactDialog}>
              Assign Item
            </Button>
          </Box>
        )
      }>
      {hasPermission(
        [PERMISSIONS.STUDENT_ITEM],
        [PERMISSION_TYPES.LISTING],
      ) && <AssignedItemsList />}
    </JumboContentLayout>
  );
};
export default StudentsInventory;
