import React from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
  Zoom,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import { mainTheme } from 'app/themes/main/default';
import { Close } from '@mui/icons-material';

const DialogBase = ({
  content,
  actions,
  title,
  subheader,
  contentProps,
  onClose,
  TransitionComponent,
  disableDefaultClose,
  showCloseButton = false,
  width,
  ...restProps
}) => {
  const { open, hideDialog } = useJumboDialog();

  const theme = createTheme({
    ...mainTheme,
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '*::-webkit-scrollbar': {
            width: '10px',
          },
          '*::-webkit-scrollbar-track': {
            background: '#E4EFEF',
          },
          '*::-webkit-scrollbar-thumb': {
            background: '#1D388F61',
            borderRadius: '2px',
          },
        },
      },
    },
  });

  const handleClose = () => {
    onClose();
    if (!disableDefaultClose) hideDialog();
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={open}
        {...restProps}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: width,
            },
          },
        }}
        TransitionComponent={TransitionComponent}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          px={2}
          pl={3}
          pt={1}>
          {title && typeof title === 'string' ? (
            <Typography
              variant="h3"
              sx={{
                color: theme => theme.palette.primary.main,
                textAlign: 'center',
              }}>
              {title}
            </Typography>
          ) : (
            title
          )}
          {showCloseButton && (
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          )}
        </Box>
        <DialogContent {...contentProps}>{content}</DialogContent>
        {actions && <DialogActions>{actions}</DialogActions>}
      </Dialog>
    </ThemeProvider>
  );
};

DialogBase.defaultProps = {
  onClose: () => {},
  TransitionComponent: Zoom,
  disableDefaultClose: false,
};

export default DialogBase;
