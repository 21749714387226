import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';

import { getFullName, hasPermission } from 'app/utils/appHelpers';
import { Box, Button, Stack, TextField, Tooltip } from '@mui/material';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';

import FeeDefaulters from '@jumbo/components/Pdf/Report/FeeDefaulters';

import { getAllBranches } from 'app/store/features/branchSlice';
import StyledAutocomplete from 'app/shared/components/Autocomplete';

import { PERMISSIONS, PERMISSION_TYPES } from 'app/utils/constants/permissions';
import {
  FEE_DEFAULTER_FILTERS_OPTIONS,
  FEE_DEFAULTERS_FILTERS,
  FEE_STATUS,
} from 'app/shared/constants';

import { getDefaulterStudentsList } from 'app/store/features/reportSlice';
import JumboChipsGroup from '@jumbo/components/JumboChipsGroup';
import { getAllClasses } from 'app/store/features/classesSlice';

const DefaulterList = () => {
  const dispatch = useDispatch();
  const { showDialog } = useJumboDialog();

  const feeList = useSelector(state => state.report.defaulterStudentsList);
  const isFeeListFetched = useSelector(
    state => state.report.isDefaulterStudentsListFetched,
  );

  const isClassesFetched = useSelector(state => state.classes.isDataFetched);
  const storeClasses = useSelector(state => state.classes.classes);

  const branches = useSelector(state => state.branches.branches);
  const isBranchesFetched = useSelector(state => state.branches.isDataFetched);

  const [classes, setClasses] = useState(storeClasses);

  const [selectionModel, setSelectionModel] = useState([]);
  const [dataToShow, setDataToShow] = useState(feeList);
  const [dataKey, setDataKey] = useState(false);

  // Filters
  const [searchText, setSearchText] = useState('');
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedFeeDefaulter, setSelectedFeeDefaulter] = useState(null);

  const handleSearchChange = event => setSearchText(event.target.value);

  useEffect(() => {
    if (!isBranchesFetched) {
      dispatch(getAllBranches());
    }

    if (!isClassesFetched) {
      dispatch(getAllClasses());
    }
  }, [dispatch, isBranchesFetched, isClassesFetched]);

  useEffect(() => {
    dispatch(
      getDefaulterStudentsList({
        filter: selectedFeeDefaulter
          ? selectedFeeDefaulter
          : FEE_DEFAULTERS_FILTERS.THIS_MONTH,
      }),
    );
  }, [dispatch, isFeeListFetched, selectedFeeDefaulter]);

  useEffect(() => {
    if (!feeList?.length) return;
    let filteredFees = feeList;

    if (searchText) {
      filteredFees = filteredFees.filter(fee =>
        getFullName(fee.student)
          .toLowerCase()
          .includes(searchText.toLowerCase()),
      );
    }

    if (selectedBranch) {
      filteredFees = filteredFees.filter(
        fee => fee.student?.branch_id === selectedBranch,
      );
    }

    if (selectedClass) {
      filteredFees = filteredFees?.filter(
        fee => fee?.student?.class_section_id === selectedClass?.id,
      );
    }

    setDataToShow(filteredFees);
  }, [searchText, feeList, selectedBranch, selectedClass]);

  useEffect(() => {
    setClasses(storeClasses);
  }, [storeClasses]);

  const showFeeDetails = fee => {
    showDialog({
      title: <p />,
      content: <FeeDefaulters fees={fee} />,
      showCloseButton: true,
      width: 1000,
    });
    setSelectionModel([]);
    setDataKey(!dataKey);
  };

  const columns = [
    {
      field: 'student',
      headerName: 'Name',
      sortable: false,
      width: 350,
      renderCell: ({ value }) => (
        <Tooltip
          title={getFullName({
            first_name: value?.first_name,
            last_name: value?.last_name,
            father_name: value?.father_name,
          })}>
          {getFullName({
            first_name: value?.first_name,
            last_name: value?.last_name,
            father_name: value?.father_name,
          })}
        </Tooltip>
      ),
    },

    {
      field: 'class',
      headerName: 'Class',
      description: 'Class Name',
      sortable: false,
      width: 110,
      renderCell: ({ row }) => {
        return <>{row?.student?.class_section?.class}</>;
      },
    },

    {
      field: 'section',
      headerName: 'Section',
      description: 'Section Name',
      sortable: false,
      width: 110,
      renderCell: ({ row }) => {
        return <>{row?.student?.class_section?.section}</>;
      },
    },

    {
      field: 'paid_amount',
      headerName: 'Paid',
      description: 'Paid Amount',
      sortable: false,
      width: 110,
      renderCell: ({ value }) => <>{value} Rs.</>,
    },
    {
      field: 'total',
      headerName: 'Total',
      description: 'Total Amount',
      sortable: false,
      width: 110,
      renderCell: ({ value }) => <>{value} Rs.</>,
    },
    {
      field: 'balance',
      headerName: 'CPB',
      description: 'Current Payable Amount',
      sortable: false,
      width: 100,
      renderCell: ({ value, row }) => (
        <>{row?.status === 'pending' && value === 0 ? row?.total : value} Rs.</>
      ),
    },

    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      width: 80,
      renderCell: ({ value }) => {
        const label = [{ label: value, color: FEE_STATUS[value] }];
        return (
          <Box pt={1.5}>
            <JumboChipsGroup
              chips={label}
              mapKeys={{ label: 'name' }}
              spacing={1}
              size={'small'}
              max={1}
            />
          </Box>
        );
      },
    },
  ];

  return (
    <Box width={1} height={1}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="start"
        mb={2}>
        <Stack direction="column" gap={2}>
          <Stack direction="row" gap={1} alignItems="end">
            <TextField
              fullWidth
              label="Search..."
              variant="outlined"
              size="small"
              name="search"
              value={searchText}
              onChange={handleSearchChange}
              sx={{ minWidth: 300 }}
            />
            <StyledAutocomplete
              options={FEE_DEFAULTER_FILTERS_OPTIONS}
              value={selectedFeeDefaulter}
              label="Select Filter"
              size="small"
              variant="outlined"
              onChange={(_, val) => setSelectedFeeDefaulter(val)}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select Filter"
                />
              )}
              sx={{ minWidth: 300, maxWidth: 300 }}
            />
          </Stack>
          <Stack direction="row" gap={2}>
            {hasPermission(
              [PERMISSIONS.BRANCH],
              [PERMISSION_TYPES.LISTING],
            ) && (
              <StyledAutocomplete
                fullWidth
                size="small"
                variant="outlined"
                name="branch_id"
                label="Class"
                getOptionLabel={option => option.name}
                renderOption={(props, option) => (
                  <li {...props}>{option.name}</li>
                )}
                options={branches}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Select Branch"
                  />
                )}
                sx={{ minWidth: 300, maxWidth: 300 }}
                onChange={(_, val) => {
                  if (val) {
                    const filteredClasses = classes?.filter(
                      item => item?.branch?.id === val?.id,
                    );
                    setClasses(filteredClasses);
                  } else {
                    setClasses(storeClasses);
                  }

                  setSelectedBranch(val);
                }}
              />
            )}

            {hasPermission(
              [PERMISSIONS.CLASS_SECTION],
              [PERMISSION_TYPES.LISTING],
            ) && (
              <StyledAutocomplete
                fullWidth
                size="small"
                variant="outlined"
                name="class"
                label="Class Name"
                getOptionLabel={option => option.class}
                renderOption={(props, option) => (
                  <li {...props}>{option.class}</li>
                )}
                options={classes}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Select Class"
                  />
                )}
                sx={{ minWidth: 300, maxWidth: 300 }}
                onChange={(_, val) => {
                  setSelectedClass(val);
                }}
              />
            )}
          </Stack>
        </Stack>
        <Stack>
          {hasPermission([PERMISSIONS.FEE], [PERMISSION_TYPES.ADD]) && (
            <Button
              fullWidth
              disableElevation
              disabled={!selectionModel.length}
              variant="contained"
              sx={{
                mb: 2,
                '& .MuiSvgIcon-root': {
                  fontSize: '1.5rem',
                },
              }}
              onClick={() => {
                const selectedData = feeList
                  .filter(item => selectionModel.includes(item.id))
                  .map(item => {
                    const branch = branches?.find(
                      branch => branch?.id === item?.student?.branch_id,
                    );
                    return { ...item, branch };
                  });

                showFeeDetails(selectedData);
              }}>
              Details
            </Button>
          )}
        </Stack>
      </Stack>
      <Box width={'100%'} height={400}>
        <DataGrid
          key={dataKey}
          getRowId={({ id }) => id}
          columns={columns}
          rows={dataToShow}
          disableColumnResize={true}
          checkboxSelection
          keepNonExistentRowsSelected
          onRowSelectionModelChange={itm => setSelectionModel(itm)}
          selectionModel={selectionModel}
          autoHeight
          loading={!isFeeListFetched}
          sx={{
            boxShadow: 2,
            borderRadius: 2,
            '& .MuiDataGrid-cell:hover': {
              color: 'primary.main',
            },
            overflowX: 'auto',
            minHeight: 400,
          }}
        />
      </Box>
    </Box>
  );
};

export default DefaulterList;
