import React, { Suspense, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { SnackbarProvider } from 'notistack';

import JumboApp from '@jumbo/components/JumboApp';
import AppLayout from './AppLayout';
import JumboTheme from '@jumbo/components/JumboTheme';
import AppRoutes from './AppRoutes';
import JumboDialog from '@jumbo/components/JumboDialog';
import JumboDialogProvider from '@jumbo/components/JumboDialog/JumboDialogProvider';
import AppProvider from './AppProvider';
import { config } from './config/main';
import JumboRTL from '@jumbo/JumboRTL/JumboRTL';
import Div from '@jumbo/shared/Div';
import store from './store';
import CustomAlert from './shared/components/Alert';
import 'react-datepicker/dist/react-datepicker.css';
import ErrorBoundary from './ErrorBoundary';
import UserTheme from './UserTheme';

const App = () => {
  useEffect(() => {
    const checkAuth = () => {
      const isAuthenticated = localStorage.getItem('fotanistanAccessToken');
      if (!isAuthenticated) {
        window.location.href = '/auth/login';
      }
    };

    window.addEventListener('storage', checkAuth);

    return () => {
      window.removeEventListener('storage', checkAuth);
    };
  }, []);

  return (
    <ErrorBoundary>
      <Provider store={store}>
        <BrowserRouter>
          <AppProvider>
            <JumboApp activeLayout={config.activeLayout}>
              <JumboTheme init={config.theme}>
                <JumboRTL>
                  <JumboDialogProvider>
                    <JumboDialog />
                    <SnackbarProvider
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      maxSnack={3}>
                      <AppLayout>
                        <Suspense
                          fallback={
                            <Div
                              sx={{
                                display: 'flex',
                                minWidth: 0,
                                alignItems: 'center',
                                alignContent: 'center',
                                height: '100%',
                              }}>
                              <CircularProgress
                                sx={{
                                  m: '-40px auto 0',
                                  color: theme => theme.palette.primary.main,
                                }}
                              />
                            </Div>
                          }>
                          <CustomAlert />
                          <AppRoutes />
                          <UserTheme />
                        </Suspense>
                      </AppLayout>
                    </SnackbarProvider>
                  </JumboDialogProvider>
                </JumboRTL>
              </JumboTheme>
            </JumboApp>
          </AppProvider>
        </BrowserRouter>
      </Provider>
    </ErrorBoundary>
  );
};

export default App;
