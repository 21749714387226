import React from 'react';
import { useDispatch } from 'react-redux';

import { ListItemText, Tooltip, Typography, Stack } from '@mui/material';
import { MoreHoriz, Edit, Delete } from '@mui/icons-material';
import styled from '@emotion/styled';

import JumboDdMenu from '@jumbo/components/JumboDdMenu';
import Span from '@jumbo/shared/Span';
import JumboListItem from '@jumbo/components/JumboList/components/JumboListItem';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';

import Form from '../Form';
import { deleteStudentInactivityReason } from 'app/store/features/configurationSlice';
import { hasPermission } from 'app/utils/appHelpers';
import { PERMISSIONS, PERMISSION_TYPES } from 'app/utils/constants/permissions';

const Item = styled(Span)(({ theme }) => ({
  minWidth: 0,
  flexGrow: 0,
  padding: theme.spacing(0, 1),
}));

const ItemComponent = ({ item }) => {
  const { showDialog, hideDialog } = useJumboDialog();
  const dispatch = useDispatch();

  const handleDelete = data => {
    dispatch(deleteStudentInactivityReason(data.id));
    hideDialog();
  };

  const handleItemAction = menuItem => {
    switch (menuItem.action) {
      case 'edit':
        showDialog({
          title: 'Update Student Inactivity reason',
          content: <Form payload={item} />,
        });
        break;
      case 'delete':
        showDialog({
          variant: 'confirm',
          title: 'Are you sure about deleting this inactivity reason?',
          content: "You won't be able to recover this inactivity reason",
          onYes: () => handleDelete(item),
          onNo: hideDialog,
        });
        break;
      default:
        break;
    }
  };

  return (
    <JumboListItem
      componentElement={'div'}
      itemData={item}
      secondaryAction={
        <>
          {hasPermission(
            [PERMISSIONS.STUDENT_INACTIVITY_REASON],
            [PERMISSION_TYPES.EDIT, PERMISSION_TYPES.DELETE],
          ) && (
            <JumboDdMenu
              icon={<MoreHoriz />}
              menuItems={[
                hasPermission(
                  [PERMISSIONS.STUDENT_INACTIVITY_REASON],
                  [PERMISSION_TYPES.EDIT],
                ) && {
                  icon: <Edit />,
                  title: 'Edit',
                  action: 'edit',
                },
                hasPermission(
                  [PERMISSIONS.STUDENT_INACTIVITY_REASON],
                  [PERMISSION_TYPES.DELETE],
                ) && {
                  icon: <Delete />,
                  title: 'Delete',
                  action: 'delete',
                },
              ]}
              onClickCallback={handleItemAction}
            />
          )}
        </>
      }
      sx={{
        cursor: 'pointer',
        borderTop: 1,
        borderColor: 'divider',
        '&:hover': {
          bgcolor: 'action.hover',
        },
      }}>
      <ListItemText
        primary={
          <Typography variant={'body1'} component={'div'}>
            <Stack direction={'row'} alignItems={'center'} sx={{ minWidth: 0 }}>
              <Item
                sx={{
                  flexBasis: { xs: '50%' },
                }}>
                <Tooltip title="Section name">
                  <Typography
                    variant={'h5'}
                    fontSize={14}
                    lineHeight={1.25}
                    mb={0}
                    noWrap>
                    {item?.name}
                  </Typography>
                </Tooltip>
              </Item>

              <Item
                sx={{
                  flexBasis: { xs: '50%' },
                }}>
                <Tooltip title={'Student Inactivity reason Status'}>
                  <Typography variant={'body1'} noWrap>
                    {item?.status ? 'Active' : 'Disabled'}
                  </Typography>
                </Tooltip>
              </Item>
            </Stack>
          </Typography>
        }
      />
    </JumboListItem>
  );
};

export default ItemComponent;
