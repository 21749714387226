import { useEffect, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import ClassesAppSidebar from './components/ClassesAppSidebar';
import ClassesList from './components/ClassesList';

import { getAllClasses } from 'app/store/features/classesSlice';

import { hasPermission } from 'app/utils/appHelpers';
import { PERMISSIONS, PERMISSION_TYPES } from 'app/utils/constants/permissions';

const Classes = () => {
  const dispatch = useDispatch();
  const { category } = useParams();
  const classes = useSelector(state => state.classes.classes);
  const isStoreClasses = useSelector(state => state.classes.isDataFetched);
  const [filteredData, setFilteredData] = useState(classes);

  const fetchClasses = useCallback(async () => {
    if (!isStoreClasses) {
      dispatch(getAllClasses());
    }
  }, [dispatch, isStoreClasses]);

  useEffect(() => {
    if (filteredData.length !== classes.length) setFilteredData(classes);
    fetchClasses();
  }, [fetchClasses, filteredData, classes]);

  useEffect(() => {
    if (category === 'all') {
      setFilteredData(classes);
    } else {
      const filteredClasses = classes?.filter(
        single => category === single.branch.name.toLowerCase(),
      );
      setFilteredData(filteredClasses);
    }
  }, [category, classes]);

  return (
    <>
      <JumboContentLayout
        sidebar={
          hasPermission(
            [PERMISSIONS.CLASS_SECTION],
            [PERMISSION_TYPES.ADD],
          ) && <ClassesAppSidebar />
        }>
        {hasPermission(
          [PERMISSIONS.CLASS_SECTION],
          [PERMISSION_TYPES.LISTING],
        ) && <ClassesList />}
      </JumboContentLayout>
    </>
  );
};
export default Classes;
