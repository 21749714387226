const COLORS = ['warning', 'secondary', 'success'];

export const calculate = branches => {
  const temp = JSON.parse(JSON.stringify(branches));

  const totalRevenue = temp.reduce(
    (previousValue, currentValue) => previousValue + currentValue?.Earning,
    0,
  );

  for (let i = 0; i < temp.length; i++) {
    temp[i].value = ((temp[i].Earning / totalRevenue) * 100).toFixed(2);
  }

  for (let i = 0; i < temp.length; i++) {
    if (temp[i].Earning <= 100000) {
      temp[i].color = COLORS[1];
    } else if (temp[i].Earning <= 50000) {
      temp[i].color = COLORS[0];
    } else {
      temp[i].color = COLORS[2];
    }
  }
  return temp;
};

export const calculateGraph = branches => {
  const tempArray = [];

  for (let i = 0; i < branches.length; i++) {
    const obj = {
      name: branches[i].name,
      Earning: branches[i].Earning,
      Expenses: branches[i].Expenses,
      'Non Expense Payments': Math.floor(
        branches[i].Expenses -
          (branches[i].Expenses /
            (branches[i].Earning + branches[i].Expenses)) *
            25,
      ),
      'Profit Loss':
        branches[i].Earning /
        (branches[i].Expenses -
          (branches[i].Expenses /
            (branches[i].Earning + branches[i].Expenses)) *
            25),
      'Cash In Hand':
        branches[i]['Cash In Hand'] -
        (branches[i].Expenses / (branches[i].Earning + branches[i].Expenses)) *
          25,
    };

    tempArray.push(obj);
  }

  return tempArray;
};

export const branchRevenue = (branches, time) => {
  let results = [];
  let value = 0;
  switch (time) {
    case 'Last Month': {
      value = -1;
      break;
    }
    case 'Last 3 Months': {
      value = -3;
      break;
    }
    case 'Last 6 Months': {
      value = -6;
      break;
    }
    case 'Whole year': {
      value = -12;
      break;
    }
    default: {
      break;
    }
  }

  for (let i = 0; i < branches.length; i++) {
    let obj = {
      name: branches[i].name,
      Expenses: 0,
      Earning: 0,
      'Cash In Hand': 0,
    };
    let splicedValue = branches[i].revenueSummary.slice(value);
    for (let j = 0; j < splicedValue.length; j++) {
      obj.Expenses += splicedValue[j].expense;
      obj.Earning += splicedValue[j].earning;
    }
    obj['Cash In Hand'] = obj.Earning - obj.Expenses;
    results.push(obj);
  }

  return results;
};
