import React from 'react';

import { LinearProgress, Typography, Tooltip } from '@mui/material';
import Div from '@jumbo/shared/Div';
import styled from '@mui/material/styles/styled';
import { Scrollbars } from 'react-custom-scrollbars-2';

import { calculate } from '../utils/calculations';

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  borderRadius: 5,
  flex: 1,
}));

const BranchesComparison = ({ data }) => {
  const results = calculate(data);

  return (
    <>
      <Typography>Branch Contribution</Typography>
      <Scrollbars style={{ height: 200, paddingTop: 10 }}>
        {results?.map((item, index) => (
          <React.Fragment key={index}>
            <Typography variant={'body1'} color={'text.secondary'}>
              {item.name}
            </Typography>
            <Div
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: 0.5,
              }}>
              <StyledLinearProgress
                variant={'determinate'}
                value={item.value}
                color={item.color}
              />
              <Tooltip title={`Rs. ${item.Earning}`}>
                <Typography
                  variant={'body1'}
                  color={'text.secondary'}
                  ml={1}>{`${item.value}%`}</Typography>
              </Tooltip>
            </Div>
          </React.Fragment>
        ))}
      </Scrollbars>
    </>
  );
};

export default BranchesComparison;
