import { useState, useEffect, useCallback } from 'react';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Card, CardHeader, CardContent } from '@mui/material';

import Div from '@jumbo/shared/Div';
import DropDown from 'app/shared/components/DropDown';

import { calculateMonthlyAverage } from '../../utils';

import { months } from '../../utils/months';
const years = [
  {
    id: 1,
    label: 2022,
    value: 2022,
  },
];

const MonthlyAverage = () => {
  const { branches } = useSelector(state => state.accounts.accounts);

  const [month, setMonth] = useState('Jan');
  const [year, setYear] = useState(2022);
  const temp = calculateMonthlyAverage(branches, month);
  const [graphResults, setGraphResults] = useState(temp);

  useEffect(() => {
    setGraphResults(calculateMonthlyAverage(branches, month));
  }, [month]);

  const handleMonthChange = useCallback(e => {
    setMonth(e.target.value);
  }, []);

  return (
    <Card height={152} sx={{ height: '100%' }}>
      <CardHeader title={'Monthly Average Performance'} />
      <CardContent>
        <Div sx={{ display: 'flex', alignItems: 'center' }}>
          <Div sx={{ width: '80%' }}>
            <DropDown
              label={'Select Month'}
              options={months}
              value={month}
              onChange={handleMonthChange}
            />
          </Div>

          <Div sx={{ paddingLeft: 1, width: '20%' }}>
            <DropDown
              label={'Select Year'}
              options={years}
              value={year}
              onChange={e => setYear(e.target.value)}
            />
          </Div>
        </Div>

        <Div>
          <ResponsiveContainer width="100%" height={200}>
            <BarChart
              data={graphResults}
              margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
              <XAxis dataKey="name" />
              <YAxis yAxisId="Expenses" orientation="left" stroke="#e91e63" />
              <YAxis yAxisId="Earning" orientation="right" stroke="#3BD2A2" />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip
                labelStyle={{ color: 'black' }}
                itemStyle={{ color: 'black' }}
                cursor={false}
              />
              <Legend />
              <defs>
                <linearGradient id="color1" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#6200EE" stopOpacity={1} />
                  <stop offset="95%" stopColor="#B819D2" stopOpacity={1} />
                </linearGradient>
              </defs>
              <defs>
                <linearGradient id="color2" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#1ABBDE" stopOpacity={1} />
                  <stop offset="95%" stopColor="#09BCA7" stopOpacity={1} />
                </linearGradient>
              </defs>
              <Bar
                yAxisId="Expenses"
                barSize={10}
                dataKey="Expenses"
                fill={'#e91e63'}
              />
              <Bar
                yAxisId="Earning"
                barSize={10}
                dataKey="Earning"
                fill={'#3BD2A2'}
              />
            </BarChart>
          </ResponsiveContainer>
        </Div>

        <Div sx={{ marginTop: 2 }}>
          <Typography variant={'h4'}>Monthly Average</Typography>
          <Div
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
            <Typography variant={'body1'}>
              Earning: PKR.{' '}
              {graphResults.reduce(
                (prev, current) => prev + current.Earning,
                0,
              )}
            </Typography>
            <Typography variant={'body1'}>
              Expenses: PKR.{' '}
              {graphResults.reduce(
                (prev, current) => prev + current.Expenses,
                0,
              )}
            </Typography>
          </Div>
        </Div>
      </CardContent>
    </Card>
  );
};

export default MonthlyAverage;
