export const months = [
  {
    id: 1,
    label: 'January',
    value: 'Jan',
  },
  {
    id: 2,
    label: 'February',
    value: 'Feb',
  },
  {
    id: 3,
    label: 'March',
    value: 'Mar',
  },
  {
    id: 4,
    label: 'April',
    value: 'Apr',
  },
  {
    id: 5,
    label: 'May',
    value: 'May',
  },
  {
    id: 6,
    label: 'June',
    value: 'Jun',
  },
  {
    id: 7,
    label: 'July',
    value: 'Jul',
  },
  {
    id: 8,
    label: 'August',
    value: 'Aug',
  },
  {
    id: 9,
    label: 'September',
    value: 'Sep',
  },
  {
    id: 10,
    label: 'October',
    value: 'Oct',
  },
  {
    id: 11,
    label: 'November',
    value: 'Nov',
  },
  {
    id: 12,
    label: 'December',
    value: 'Dec',
  },
];
