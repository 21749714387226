import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Card, CardContent, Typography, TextField } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import { alpha } from '@mui/material/styles';
import * as yup from 'yup';
import { useFormik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import { Link } from 'react-router-dom';

import Div from '@jumbo/shared/Div';
import { useJumboApp } from '@jumbo/hooks';
import { LAYOUT_NAMES } from '../../../layouts/layouts';
import { ASSET_IMAGES } from '../../../utils/constants/paths';
import { API_POST } from 'app/utils/constants/api/apiRequests';
import { showAlert } from 'app/store/features/alertSlice';

const validationSchema = yup.object({
  username: yup.string().required('User Name is Required'),
  password: yup
    .string()
    .min(4, 'Password must be at least 4 characters long')
    .max(255, 'Password must be less than 255 characters')
    .required('Password is Required'),
});

const Login = () => {
  const dispatch = useDispatch();
  const { setActiveLayout } = useJumboApp();
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = async values => {
    setIsLoading(true);
    try {
      const { status, message, data } = await API_POST('/auth/login', values);
      if (status) {
        dispatch(showAlert({ type: 'success', message }));
        localStorage.setItem('fotanistanAccessToken', data.token);
        if (data.user.user_type === 'admin') {
          window.location.assign('/dashboards/analytics');
        } else {
          window.location.assign('/welcome');
        }
      } else {
        dispatch(showAlert({ type: 'error', message }));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const { values, handleChange, handleBlur, handleSubmit, touched, errors } =
    useFormik({
      initialValues: {
        username: '',
        password: '',
      },
      validationSchema,
      onSubmit: values => {
        handleLogin(values);
      },
    });

  useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.SOLO_PAGE);
  }, [setActiveLayout]);

  return (
    <Div
      sx={{
        flex: 1,
        flexWrap: 'wrap',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        p: theme => theme.spacing(4),
      }}>
      <Div sx={{ mb: 3, display: 'inline-flex' }}>
        <Link href="#" underline="none" sx={{ display: 'inline-flex' }}>
          <div
            style={{
              width: 200,
            }}>
            <img
              src={`${ASSET_IMAGES}/logo.png`}
              alt="Jumbo React"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
          </div>
        </Link>
      </Div>
      <Card sx={{ maxWidth: '100%', width: 360, mb: 4 }}>
        <Div sx={{ position: 'relative', height: '200px' }}>
          <CardMedia
            component="img"
            alt="green iguana"
            height="200"
            image={`${ASSET_IMAGES}/login.jpg`}
          />
          <Div
            sx={{
              flex: 1,
              inset: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              backgroundColor: theme => alpha(theme.palette.common.black, 0.5),
              p: theme => theme.spacing(3),
            }}>
            <Typography
              variant={'h2'}
              sx={{
                color: 'common.white',
                fontSize: '1.5rem',
                mb: 0,
              }}>
              Sign In
            </Typography>
          </Div>
        </Div>
        <CardContent sx={{ pt: 0 }}>
          <form style={{ textAlign: 'left' }} onSubmit={handleSubmit}>
            <Div sx={{ mt: 3, mb: 1, height: 80 }}>
              <TextField
                fullWidth
                id="username"
                label="User Name"
                value={values.username}
                error={touched.username && errors.username}
                helperText={touched.username && errors.username}
                name="username"
                onChange={handleChange}
                onBlur={handleBlur}
                InputLabelProps={{ shrink: true }}
              />
            </Div>
            <Div sx={{ mt: 1, mb: 1, height: 80 }}>
              <TextField
                fullWidth
                id="password"
                label="Password"
                name="password"
                value={values.password}
                error={touched.password && errors.password}
                helperText={touched.password && errors.password}
                onChange={handleChange}
                onBlur={handleBlur}
                type="password"
                InputLabelProps={{ shrink: true }}
              />
            </Div>

            <LoadingButton
              fullWidth
              type="submit"
              variant="contained"
              size="large"
              sx={{ mb: 3 }}
              loading={isLoading}>
              Login
            </LoadingButton>
          </form>
        </CardContent>
      </Card>
    </Div>
  );
};

export default Login;
