import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import ReportList from './List';
import { hasAnyPermissions } from 'app/utils/appHelpers';
import { PERMISSIONS } from 'app/utils/constants/permissions';

const ExpenseReports = () => {
  return (
    <JumboContentLayout>
      {hasAnyPermissions([PERMISSIONS.FEE]) && <ReportList />}
    </JumboContentLayout>
  );
};
export default ExpenseReports;
