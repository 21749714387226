import { Grid } from '@mui/material';
import BranchesStatistics from 'app/pages/dashboards/accounts/components/BranchesStats';
import CurrentBalance from '../../../shared/metrics/PortfolioBalance';
import HistoryGraph from '../../../shared/metrics/EarningExpenses';
import BranchesSummary from './components/BranchesSummary';
import MonthlyStats from './components/MonthlyStats';
import MonthlyAverage from './components/MonthlyAverage';
import ExpenseForm from './components/ExpenseForm';
import DepositForm from './components/DepositForm';
import TwoBranchesComparison from './components/TwoBranchesComparison';
import MultipleBranchesComparison from './components/MultipleBranchesComparison';

const AccountsDashboard = () => {
  return (
    <Grid container spacing={3.75}>
      <Grid item xs={12} lg={6}>
        <CurrentBalance ExpenseForm={ExpenseForm} DepositForm={DepositForm} />
      </Grid>
      <Grid item xs={12} lg={6}>
        <HistoryGraph />
      </Grid>
      <Grid item xs={12}>
        <BranchesSummary />
      </Grid>
      <Grid item xs={12} lg={6}>
        <MonthlyStats />
      </Grid>
      <Grid item xs={12} lg={6}>
        <MonthlyAverage />
      </Grid>
      <Grid item xs={12}>
        <TwoBranchesComparison />
      </Grid>
      <Grid item xs={12}>
        <MultipleBranchesComparison />
      </Grid>
      <Grid item xs={12}>
        <BranchesStatistics />
      </Grid>
    </Grid>
  );
};

export default AccountsDashboard;
