import React from 'react';
import JumboNavSection from '@jumbo/components/JumboVerticalNavbar/JumboNavSection';
import JumboNavCollapsible from '@jumbo/components/JumboVerticalNavbar/JumboNavCollapsible';
import JumboNavItem from '@jumbo/components/JumboVerticalNavbar/JumboNavItem';
import { hasAnyPermissions } from 'app/utils/appHelpers';

const NAV_VARIANTS = {
  section: JumboNavSection,
  collapsible: JumboNavCollapsible,
  'nav-item': JumboNavItem,
};

const JumboNavIdentifier = ({ item, isNested }) => {
  if (
    item.type === 'section' &&
    item?.children?.length === 2 &&
    item?.children[0]?.key === 'home'
  ) {
    return null;
  }

  // This check is only for array of keys. In this current case it is for accounts

  if (typeof item.key === 'object') {
    if (hasAnyPermissions(item.key)) {
      const NavComponent = NAV_VARIANTS?.['nav-item'];
      return NavComponent ? (
        <NavComponent translate item={item} isNested={isNested} />
      ) : null;
    }
  }

  if (!item?.key || hasAnyPermissions([item.key])) {
    const NavComponent = NAV_VARIANTS?.[item.type];
    return NavComponent ? (
      <NavComponent translate item={item} isNested={isNested} />
    ) : null;
  }

  return null;
};

JumboNavIdentifier.defaultProps = {
  isNested: false,
};

export default JumboNavIdentifier;
