import React from 'react';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CardHeader from '@mui/material/CardHeader';
import {
  alpha,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Tooltip,
} from '@mui/material';

import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import Divider from '@mui/material/Divider';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';
import List from '@mui/material/List';
import Div from '@jumbo/shared/Div';
import {
  BookOnline,
  LightMode,
  ToggleOff,
  ToggleOn,
} from '@mui/icons-material';

const ClassesDetail = ({ classData, onClose }) => {
  return (
    <Div sx={{ m: theme => theme.spacing(-2.5, -3) }}>
      <CardHeader
        title={classData?.class_section_title}
        subheader={
          <Tooltip title={'Class, Section'}>
            <Typography variant={'body1'} noWrap>
              {classData?.class}, {classData?.section}
            </Typography>
          </Tooltip>
        }
        avatar={<Avatar src={classData?.profile_pic} />}
        action={
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        }
      />
      <List disablePadding>
        <ListItem sx={{ px: 4 }}>
          <ListItemAvatar sx={{ minWidth: 66 }}>
            <Avatar
              variant="rounded"
              sx={{
                height: 48,
                width: 48,
                bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
              }}>
              <CorporateFareIcon sx={{ color: 'primary.light' }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant={'body1'} color={'text.secondary'} mb={0.5}>
                Section
              </Typography>
            }
            secondary={
              <Typography variant={'body2'} mb={0}>
                {classData?.section}
              </Typography>
            }
          />
        </ListItem>
        <Divider component={'li'} />

        <ListItem sx={{ px: 4 }}>
          <ListItemAvatar sx={{ minWidth: 66 }}>
            <Avatar
              variant="rounded"
              sx={{
                height: 48,
                width: 48,
                bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
              }}>
              <LabelOutlinedIcon sx={{ color: 'primary.light' }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant={'body1'} color={'text.secondary'} mb={0.5}>
                Branch
              </Typography>
            }
            secondary={
              <Typography component={'div'}>
                {classData?.branch?.name}
              </Typography>
            }
          />
        </ListItem>
        <Divider component={'li'} />

        <ListItem sx={{ px: 4 }}>
          <ListItemAvatar sx={{ minWidth: 66 }}>
            <Avatar
              variant="rounded"
              sx={{
                height: 48,
                width: 48,
                bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
              }}>
              <MailOutlineIcon sx={{ color: 'primary.light' }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant={'body1'} color={'text.secondary'} mb={0.5}>
                InCharge
              </Typography>
            }
            secondary={
              <Typography variant={'body2'} mb={0}>
                {classData?.incharge?.name}
              </Typography>
            }
          />
        </ListItem>
        <Divider component={'li'} />

        <ListItem sx={{ px: 4 }}>
          <ListItemAvatar sx={{ minWidth: 66 }}>
            <Avatar
              variant="rounded"
              sx={{
                height: 48,
                width: 48,
                bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
              }}>
              <LabelOutlinedIcon sx={{ color: 'primary.light' }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant={'body1'} color={'text.secondary'} mb={0.5}>
                Minimum Students
              </Typography>
            }
            secondary={
              <Typography variant={'body2'}>
                {classData?.min_strength}
              </Typography>
            }
          />
        </ListItem>
        <Divider component={'li'} />

        <ListItem sx={{ px: 4 }}>
          <ListItemAvatar sx={{ minWidth: 66 }}>
            <Avatar
              variant="rounded"
              sx={{
                height: 48,
                width: 48,
                bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
              }}>
              <LabelOutlinedIcon sx={{ color: 'primary.light' }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant={'body1'} color={'text.secondary'} mb={0.5}>
                Maximum Students
              </Typography>
            }
            secondary={
              <Typography variant={'body2'}>
                {classData?.max_strength}
              </Typography>
            }
          />
        </ListItem>
        <Divider component={'li'} />

        <ListItem sx={{ px: 4 }}>
          <ListItemAvatar sx={{ minWidth: 66 }}>
            <Avatar
              variant="rounded"
              sx={{
                height: 48,
                width: 48,
                bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
              }}>
              <BookOnline sx={{ color: 'primary.light' }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant={'body1'} color={'text.secondary'} mb={0.5}>
                Is Pre Admission?
              </Typography>
            }
            secondary={
              <Typography variant={'body2'}>
                {classData?.is_pre_admission ? 'Yes' : 'No'}
              </Typography>
            }
          />
        </ListItem>
        <Divider component={'li'} />

        <ListItem sx={{ px: 4 }}>
          <ListItemAvatar sx={{ minWidth: 66 }}>
            <Avatar
              variant="rounded"
              sx={{
                height: 48,
                width: 48,
                bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
              }}>
              <BookOnline sx={{ color: 'primary.light' }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant={'body1'} color={'text.secondary'} mb={0.5}>
                Is Fee Refundable?
              </Typography>
            }
            secondary={
              <Typography variant={'body2'}>
                {classData?.is_refundable ? 'Yes' : 'No'}
              </Typography>
            }
          />
        </ListItem>
        <Divider component={'li'} />

        <ListItem sx={{ px: 4 }}>
          <ListItemAvatar sx={{ minWidth: 66 }}>
            <Avatar
              variant="rounded"
              sx={{
                height: 48,
                width: 48,
                bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
              }}>
              <LightMode sx={{ color: 'primary.light' }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant={'body1'} color={'text.secondary'} mb={0.5}>
                Is Full Day?
              </Typography>
            }
            secondary={
              <Typography variant={'body2'}>
                {classData?.is_full_day ? 'Yes' : 'No'}
              </Typography>
            }
          />
        </ListItem>
        <Divider component={'li'} />

        <ListItem sx={{ px: 4 }}>
          <ListItemAvatar sx={{ minWidth: 66 }}>
            <Avatar
              variant="rounded"
              sx={{
                height: 48,
                width: 48,
                bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
              }}>
              {classData ? (
                <ToggleOn sx={{ color: 'primary.light' }} />
              ) : (
                <ToggleOff sx={{ color: 'primary.light' }} />
              )}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant={'body1'} color={'text.secondary'} mb={0.5}>
                Status
              </Typography>
            }
            secondary={
              <Typography component={'div'}>
                {classData?.status ? 'Active' : 'In Active'}
              </Typography>
            }
          />
        </ListItem>
        <Divider component={'li'} />
      </List>
    </Div>
  );
};

export default ClassesDetail;
