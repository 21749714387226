import { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import JumboContentLayout from '@jumbo/components/JumboContentLayout';

import UsersSidebar from './components/UsersSidebar';
import UsersList from './components/UsersList';

import { getAllUsers } from 'app/store/features/usersSlice';
import { hasPermission } from 'app/utils/appHelpers';
import { PERMISSIONS, PERMISSION_TYPES } from 'app/utils/constants/permissions';

const Users = () => {
  const { category } = useParams();
  const dispatch = useDispatch();

  const users = useSelector(state => state.users.users);
  // const nonTeachers = users.filter(user => user.user_type !== 'teacher');
  const [filteredData, setFilteredData] = useState(users);
  const isUsersFetched = useSelector(state => state.users.isDataFetched);

  const fetchUsers = useCallback(async () => {
    if (!isUsersFetched) {
      dispatch(getAllUsers());
    }
  }, [dispatch, isUsersFetched]);

  useEffect(() => {
    if (
      filteredData.length !== users.length ||
      JSON.stringify(filteredData) !== JSON.stringify(users)
    ) {
      setFilteredData(users);
    }
    fetchUsers();
  }, [fetchUsers, filteredData, users]);

  useEffect(() => {
    if (category === 'all') {
      setFilteredData(users);
    } else {
      const filteredUsers = users?.filter(
        single => category === single?.branch?.name?.toLowerCase(),
      );
      const nonTeachers = filteredUsers.filter(
        user => user?.role !== 'teacher',
      );
      setFilteredData(nonTeachers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  return (
    <JumboContentLayout
      sidebar={
        hasPermission([PERMISSIONS.USER], [PERMISSION_TYPES.ADD]) && (
          <UsersSidebar />
        )
      }>
      {hasPermission([PERMISSIONS.USER], [PERMISSION_TYPES.LISTING]) && (
        <UsersList users={filteredData} />
      )}
    </JumboContentLayout>
  );
};

export default Users;
