import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Tooltip,
  Typography,
  Stack,
} from '@mui/material';
import {
  Person,
  AccountBalance,
  MoreHoriz,
  Group,
  Money,
  RemoveRedEye,
} from '@mui/icons-material';
import styled from '@emotion/styled';

import JumboDdMenu from '@jumbo/components/JumboDdMenu';
import Span from '@jumbo/shared/Span';
import Div from '@jumbo/shared/Div';
import JumboListItem from '@jumbo/components/JumboList/components/JumboListItem';
import JumboGridItem from '@jumbo/components/JumboList/components/JumboGridItem';

const Item = styled(Span)(({ theme }) => ({
  minWidth: 0,
  flexGrow: 0,
  padding: theme.spacing(0, 1),
}));

const BillItem = ({ item, view }) => {
  const navigate = useNavigate();
  const showContactDetail = React.useCallback(() => {
    navigate('/app/accounts/bill', {
      state: {
        data: item,
      },
    });
  }, [navigate, item]);

  const handleItemAction = menuItem => {
    switch (menuItem.action) {
      case 'view':
        showContactDetail();
        break;
      default:
        break;
    }
  };

  if (view === 'grid') {
    return (
      <JumboGridItem xs={12} md={6} lg={4}>
        <Card variant="outlined" elevation={0}>
          <CardHeader
            action={
              <>
                <JumboDdMenu
                  icon={<MoreHoriz />}
                  menuItems={[
                    { icon: <RemoveRedEye />, title: 'View', action: 'view' },
                  ]}
                  onClickCallback={handleItemAction}
                />
              </>
            }
            title={
              <Tooltip title="Paid To">
                <Typography variant={'h6'} color={'text.primary'} mb={0.25}>
                  {item?.paid_to}
                </Typography>
              </Tooltip>
            }
            subheader={
              <Tooltip title="Bill Status">
                <Typography variant={'body1'} color={'text.secondary'}>
                  {item?.status?.charAt(0)?.toUpperCase() +
                    item?.status?.slice(1)}
                </Typography>
              </Tooltip>
            }
          />
          <CardContent sx={{ pt: 0 }}>
            <Divider sx={{ mb: 2 }} />
            <List disablePadding>
              <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                <ListItemIcon sx={{ minWidth: 50 }}>
                  <Money />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Tooltip title="Bill Date">
                      <Typography variant={'body1'} color={'text.primary'}>
                        {item?.bill_date}
                      </Typography>
                    </Tooltip>
                  }
                />
              </ListItem>

              <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                <ListItemIcon sx={{ minWidth: 50 }}>
                  <Person />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Tooltip title="Paid by Other">
                      <Typography variant={'body1'}>
                        {item?.paid_by_other ? 'Yes' : 'No'}
                      </Typography>
                    </Tooltip>
                  }
                />
              </ListItem>
              <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                <ListItemIcon sx={{ minWidth: 50 }}>
                  <AccountBalance />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Tooltip title="Total Bill Amount">
                      <Typography variant={'body1'}>
                        {item?.bill_amount}
                      </Typography>
                    </Tooltip>
                  }
                  secondary={
                    <>
                      <Tooltip title="Paid Amount">
                        <Typography variant={'body1'}>
                          {item?.paid_bill_amount}
                        </Typography>
                      </Tooltip>
                      <Tooltip title="Balance">
                        <Typography
                          variant={'body1'}
                          sx={
                            item?.balance > 0
                              ? { color: '#90EE90' }
                              : { color: 'maroon' }
                          }>
                          {item?.balance}
                        </Typography>
                      </Tooltip>
                    </>
                  }
                />
              </ListItem>
            </List>
            <Divider sx={{ my: 2 }} />
            <Div
              sx={{
                display: 'flex',
                minWidth: 0,
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
              <ListItem sx={{ p: theme => theme.spacing(0.75, 1.5) }}>
                <ListItemIcon sx={{ minWidth: 50 }}>
                  <Group />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Tooltip title="Vendor">
                      <Typography variant={'body1'}>
                        {item?.vendor?.username}
                      </Typography>
                    </Tooltip>
                  }
                />
              </ListItem>
            </Div>
          </CardContent>
        </Card>
      </JumboGridItem>
    );
  }
  return (
    <JumboListItem
      componentElement={'div'}
      itemData={item}
      secondaryAction={
        <JumboDdMenu
          icon={<MoreHoriz />}
          menuItems={[
            { icon: <RemoveRedEye />, title: 'View', action: 'view' },
          ]}
          onClickCallback={handleItemAction}
        />
      }
      sx={{
        cursor: 'pointer',
        borderTop: 1,
        borderColor: 'divider',
        '&:hover': {
          bgcolor: 'action.hover',
        },
      }}>
      <ListItemText
        onClick={showContactDetail}
        primary={
          <Typography variant={'body1'} component={'div'}>
            <Stack direction={'row'} alignItems={'center'} sx={{ minWidth: 0 }}>
              <Item
                sx={{
                  flexBasis: { xs: '100%', sm: '50%', md: '25%' },
                }}>
                <Tooltip title="Bill Date">
                  <Typography
                    variant={'h5'}
                    fontSize={14}
                    lineHeight={1.25}
                    mb={0}
                    noWrap>
                    {item?.bill_date}
                  </Typography>
                </Tooltip>
              </Item>
              <Item
                sx={{
                  flexBasis: { sm: '50%', md: '28%' },
                  display: { xs: 'none', sm: 'block' },
                }}>
                <Tooltip title={'Paid To'}>
                  <Typography variant={'body1'} noWrap>
                    {item?.paid_to}
                  </Typography>
                </Tooltip>
              </Item>
              <Item
                sx={{
                  flexBasis: { sm: '50%', md: '28%' },
                  display: { xs: 'none', sm: 'block' },
                }}>
                <Tooltip title={'Status'}>
                  <Typography variant={'body1'} noWrap>
                    {item?.status?.charAt(0)?.toUpperCase() +
                      item?.status?.slice(1)}
                  </Typography>
                </Tooltip>
              </Item>
              <Item
                sx={{
                  flexBasis: { md: '25%' },
                  display: { xs: 'none', md: 'block' },
                }}>
                <Tooltip title="Total Bill Amount">
                  <Typography variant={'body1'} noWrap>
                    {item?.bill_amount}
                  </Typography>
                </Tooltip>
              </Item>
              <Item
                sx={{
                  flexBasis: { md: '25%' },
                  display: { xs: 'none', md: 'block' },
                }}>
                <Tooltip title="Paid Bill Amount">
                  <Typography variant={'body1'} noWrap>
                    {item?.paid_bill_amount}
                  </Typography>
                </Tooltip>
              </Item>

              <Item
                sx={{
                  flexBasis: { md: '25%' },
                  display: { xs: 'none', md: 'block' },
                }}>
                <Tooltip title={'Balance'}>
                  <Typography variant={'body1'} noWrap>
                    {item?.balance}
                  </Typography>
                </Tooltip>
              </Item>
            </Stack>
          </Typography>
        }
      />
    </JumboListItem>
  );
};

export default BillItem;
