import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Grid, Typography } from '@mui/material';
import Header from './Header';
import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import { useJumboTheme } from '@jumbo/hooks';
import { alpha } from '@mui/material/styles';
import About from './components/About';
import { ASSET_IMAGES } from 'app/utils/constants/paths';
import { getAssetPath } from 'app/utils/appHelpers';

import Div from '@jumbo/shared/Div';
import Contacts from './components/Contacts';
import { useCallback, useEffect } from 'react';
import { getAllStudents } from 'app/store/features/studentsSlice';

const StudentProfile = () => {
  const { theme } = useJumboTheme();
  const students = useSelector(state => state.students.students);
  const isStudentsFetched = useSelector(state => state.students.isDataFetched);
  const { id } = useParams();
  const navigate = useNavigate();
  const selectedStudent = students?.find(student => student.id === Number(id));

  const dispatch = useDispatch();

  const fetchData = useCallback(() => {
    if (!isStudentsFetched) dispatch(getAllStudents());
  }, [dispatch, isStudentsFetched]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (!isStudentsFetched) {
    return (
      <Div
        sx={{
          width: '80vw',
          height: '100vh',
          background: 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Typography>Loading...</Typography>
      </Div>
    );
  }

  if (!selectedStudent) {
    return (
      <Div
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          minHeight: '60vh',
        }}>
        <Div sx={{ width: '500', height: '500' }}>
          <img
            src="/images/empty.png"
            alt="No Results"
            width="100%"
            height="100%"
          />
        </Div>
        <Typography fontSize={20} fontWeight={500}>
          {' '}
          No User Found Try Again
        </Typography>
        <Button
          variant="contained"
          onClick={() => navigate('/app/students/all')}>
          Back to Student's List
        </Button>
      </Div>
    );
  }

  return (
    <JumboContentLayout
      header={<Header student={selectedStudent} />}
      layoutOptions={{
        header: {
          sx: {
            mt: -4,
            mb: -7.25,
            mx: { xs: -4, lg: -6 },
            p: { xs: theme.spacing(6, 4, 11), lg: theme.spacing(6, 6, 11) },
            background: `#002447 url(${getAssetPath(
              `${ASSET_IMAGES}/profile-bg.jpg`,
              '1920x580',
            )})s no-repeat center`,
            backgroundSize: 'cover',
            color: 'common.white',
            position: 'relative',

            '&::after': {
              display: 'inline-block',
              position: 'absolute',
              content: `''`,
              inset: 0,
              backgroundColor: alpha(theme.palette.common.black, 0.65),
            },
          },
        },
        wrapper: {
          sx: {
            [theme.breakpoints.down('lg')]: {
              flexDirection: 'column',
            },
          },
        },
        main: {
          sx: {
            [theme.breakpoints.down('lg')]: {
              minHeight: 0,
            },
          },
        },
      }}>
      <Grid container spacing={3.75}>
        <Grid item xs={12} sm={6} md={4}>
          <Contacts student={selectedStudent} />
        </Grid>
        <Grid item xs={12} sm={6} md={8}>
          <About student={selectedStudent} />
        </Grid>
      </Grid>
    </JumboContentLayout>
  );
};

export default StudentProfile;
