import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  alpha,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Tooltip,
  Avatar,
  IconButton,
  CardHeader,
} from '@mui/material';
import { Person, Book } from '@mui/icons-material';

import Divider from '@mui/material/Divider';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import List from '@mui/material/List';
import Div from '@jumbo/shared/Div';
import Scrollbars from 'react-custom-scrollbars-2';

const ItemDetails = ({ item, onClose }) => {
  return (
    <Div sx={{ m: theme => theme.spacing(-2.5, -3) }}>
      <CardHeader
        title={
          <Tooltip title="Checker">
            <Typography variant={'h6'} color={'text.primary'} mb={0.25}>
              {item?.checker?.username}
            </Typography>
          </Tooltip>
        }
        subheader={
          <Tooltip title="Rechecker">
            <Typography variant={'body1'} color={'text.secondary'}>
              {item?.rechecker?.username}
            </Typography>
          </Tooltip>
        }
        avatar={<Avatar src={item?.profile_pic} />}
        action={
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        }
      />

      <Scrollbars style={{ height: 500 }}>
        <List disablePadding>
          <ListItem sx={{ px: 4 }}>
            <ListItemAvatar sx={{ minWidth: 66 }}>
              <Avatar
                variant="rounded"
                sx={{
                  height: 48,
                  width: 48,
                  bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
                }}>
                <MailOutlineIcon sx={{ color: 'primary.light' }} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography variant={'body1'} color={'text.secondary'} mb={0.5}>
                  Exam Hall
                </Typography>
              }
              secondary={
                <Typography variant={'body2'} mb={0}>
                  {item?.exam_hall}
                </Typography>
              }
            />
          </ListItem>
          {/* exam types/terms */}
          <Divider component={'li'} />
          <ListItem sx={{ px: 4 }}>
            <ListItemAvatar sx={{ minWidth: 66 }}>
              <Avatar
                variant="rounded"
                sx={{
                  height: 48,
                  width: 48,
                  bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
                }}>
                <MailOutlineIcon sx={{ color: 'primary.light' }} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography variant={'body1'} color={'text.secondary'} mb={0.5}>
                  Exam Type
                </Typography>
              }
              secondary={
                <Typography variant={'body2'} mb={0}>
                  {item?.exam_type?.charAt(0)?.toUpperCase() +
                    item?.exam_type?.slice(1)}
                </Typography>
              }
            />
          </ListItem>
          <Divider component={'li'} />
          <ListItem sx={{ px: 4 }}>
            <ListItemAvatar sx={{ minWidth: 66 }}>
              <Avatar
                variant="rounded"
                sx={{
                  height: 48,
                  width: 48,
                  bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
                }}>
                <Book sx={{ color: 'primary.light' }} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography variant={'body1'} color={'text.secondary'} mb={0.5}>
                  Exam Term
                </Typography>
              }
              secondary={
                <Typography variant={'body2'} mb={0}>
                  {item.exam_term}
                </Typography>
              }
            />
          </ListItem>
          <Divider component={'li'} />

          {/* users */}
          <ListItem sx={{ px: 4 }}>
            <ListItemAvatar sx={{ minWidth: 66 }}>
              <Avatar
                variant="rounded"
                sx={{
                  height: 48,
                  width: 48,
                  bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
                }}>
                <Person sx={{ color: 'primary.light' }} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography variant={'body1'} color={'text.secondary'} mb={0.5}>
                  Invigilator
                </Typography>
              }
              secondary={
                <Typography variant={'body2'}>
                  {item?.invigilator?.username}
                </Typography>
              }
            />
          </ListItem>
          <Divider component={'li'} />
          <ListItem sx={{ px: 4 }}>
            <ListItemAvatar sx={{ minWidth: 66 }}>
              <Avatar
                variant="rounded"
                sx={{
                  height: 48,
                  width: 48,
                  bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
                }}>
                <Person sx={{ color: 'primary.light' }} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography variant={'body1'} color={'text.secondary'} mb={0.5}>
                  Al Initiator
                </Typography>
              }
              secondary={
                <Typography variant={'body2'}>
                  {item?.al_initiator?.username}
                </Typography>
              }
            />
          </ListItem>
          <Divider component={'li'} />
          <ListItem sx={{ px: 4 }}>
            <ListItemAvatar sx={{ minWidth: 66 }}>
              <Avatar
                variant="rounded"
                sx={{
                  height: 48,
                  width: 48,
                  bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
                }}>
                <Person sx={{ color: 'primary.light' }} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography variant={'body1'} color={'text.secondary'} mb={0.5}>
                  Al Second Initiator
                </Typography>
              }
              secondary={
                <Typography variant={'body2'}>
                  {item?.al_second_initiator?.username}
                </Typography>
              }
            />
          </ListItem>
          <Divider component={'li'} />
          <ListItem sx={{ px: 4 }}>
            <ListItemAvatar sx={{ minWidth: 66 }}>
              <Avatar
                variant="rounded"
                sx={{
                  height: 48,
                  width: 48,
                  bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
                }}>
                <Person sx={{ color: 'primary.light' }} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography variant={'body1'} color={'text.secondary'} mb={0.5}>
                  Al Final Reviewer
                </Typography>
              }
              secondary={
                <Typography variant={'body2'}>
                  {item?.al_verifier?.username}
                </Typography>
              }
            />
          </ListItem>
          <Divider component={'li'} />
          <ListItem sx={{ px: 4 }}>
            <ListItemAvatar sx={{ minWidth: 66 }}>
              <Avatar
                variant="rounded"
                sx={{
                  height: 48,
                  width: 48,
                  bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
                }}>
                <Person sx={{ color: 'primary.light' }} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography variant={'body1'} color={'text.secondary'} mb={0.5}>
                  Al Verifier
                </Typography>
              }
              secondary={
                <Typography variant={'body2'}>
                  {item?.al_verifier?.username}
                </Typography>
              }
            />
          </ListItem>
          <Divider component={'li'} />

          {/* stats */}

          <ListItem sx={{ px: 4 }}>
            <ListItemAvatar sx={{ minWidth: 66 }}>
              <Avatar
                variant="rounded"
                sx={{
                  height: 48,
                  width: 48,
                  bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
                }}>
                <Book sx={{ color: 'primary.light' }} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography variant={'body1'} color={'text.secondary'} mb={0.5}>
                  Remarks Status?
                </Typography>
              }
              secondary={
                <Typography variant={'body2'}>
                  {item?.remarks_status ? 'Yes' : 'No'}
                </Typography>
              }
            />
          </ListItem>
          <Divider component={'li'} />

          <ListItem sx={{ px: 4 }}>
            <ListItemAvatar sx={{ minWidth: 66 }}>
              <Avatar
                variant="rounded"
                sx={{
                  height: 48,
                  width: 48,
                  bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
                }}>
                <Person sx={{ color: 'primary.light' }} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography variant={'body1'} color={'text.secondary'} mb={0.5}>
                  Remarks Initiator
                </Typography>
              }
              secondary={
                <Typography variant={'body2'}>
                  {item?.remarks_initiator?.username}
                </Typography>
              }
            />
          </ListItem>
          <Divider component={'li'} />

          <ListItem sx={{ px: 4 }}>
            <ListItemAvatar sx={{ minWidth: 66 }}>
              <Avatar
                variant="rounded"
                sx={{
                  height: 48,
                  width: 48,
                  bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
                }}>
                <Person sx={{ color: 'primary.light' }} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography variant={'body1'} color={'text.secondary'} mb={0.5}>
                  Remarks Final Reviewer
                </Typography>
              }
              secondary={
                <Typography variant={'body2'}>
                  {item?.remarks_final_rev?.username}
                </Typography>
              }
            />
          </ListItem>
          <Divider component={'li'} />
        </List>
      </Scrollbars>
    </Div>
  );
};

export default ItemDetails;
