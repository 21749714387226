import React from 'react';
import TabContext from '@mui/lab/TabContext';
import Div from '@jumbo/shared/Div';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import List from '@mui/material/List';

import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import StudentOverview from './StudentOverview';
import FeesOverview from './FeesOverview';
import ParentalOverview from './ParentalOverveiw';
import ResultOverview from './ResultOverview';

const About = ({ student }) => {
  const [value, setValue] = React.useState('Overview');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <JumboCardQuick
      title={'About'}
      action={
        <TabContext value={value}>
          <Div
            sx={{
              marginTop: -2.25,
              marginBottom: -2.5,

              '& .MuiTab-root': {
                py: 2.5,
              },
            }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Overview" value="Overview" />
              {/* <Tab label="Fee History" value="Fee History" /> */}
              <Tab label="Parental Information" value="Parental Information" />
              {/* <Tab label="Result" value="Result" /> */}
            </TabList>
          </Div>
        </TabContext>
      }
      headerSx={{
        borderBottom: 1,
        borderColor: 'divider',
      }}
      sx={{ mb: 3.75 }}>
      <List
        disablePadding
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          margin: theme => theme.spacing(0, -2),
        }}>
        {value === 'Overview' ? <StudentOverview student={student} /> : null}
        {value === 'Fee History' ? <FeesOverview student={student} /> : null}
        {value === 'Parental Information' ? (
          <ParentalOverview student={student} />
        ) : null}
        {value === 'Result' ? <ResultOverview student={student} /> : null}
      </List>
    </JumboCardQuick>
  );
};

export default About;
