import { Avatar, Divider, Typography, Stack, Tooltip } from '@mui/material';
import ContentHeader from 'app/layouts/shared/headers/ContentHeader';
import styled from '@emotion/styled';
import { ASSET_AVATARS } from 'app/utils/constants/paths';
import { getAssetPath } from 'app/utils/appHelpers';
import { PUBLIC_ASSETS_URL } from 'app/utils/constants/api/constants';

const Item = styled('div')({
  textAlign: 'center',
});

const Header = ({ student }) => {
  return (
    <ContentHeader
      avatar={
        <Avatar
          sx={{ width: 72, height: 72 }}
          alt={`${student?.first_name} ${student?.last_name}`}
          src={
            student?.image
              ? `${PUBLIC_ASSETS_URL}/${student?.image}`
              : getAssetPath(`${ASSET_AVATARS}/avatar3.jpg`, '72x72')
          }
        />
      }
      title={
        <Tooltip title="Student's Name">
          <Typography>
            {`${student?.first_name} ${student?.last_name}`}
          </Typography>
        </Tooltip>
      }
      subheader={
        <Tooltip title="Village, Branch">
          <Typography fontSize={12} variant={'body1'} color="white" mt={0.5}>
            <>
              {student?.village?.name}, {student?.branch?.name}
            </>
          </Typography>
        </Tooltip>
      }
      children={
        <Stack
          direction={'row'}
          justifyContent={'space-evenly'}
          divider={<Divider orientation="vertical" flexItem />}
          spacing={2}
          sx={{
            mx: 1,
          }}>
          <Item>
            <Typography variant={'h6'} color={'inherit'} mb={0}>
              {student?.roll_no}
            </Typography>
            <Typography variant={'body1'} fontSize={12}>
              Roll No.
            </Typography>
          </Item>
          <Item>
            <Typography variant={'h6'} color={'inherit'} mb={0}>
              {student?.branch?.name}
            </Typography>
            <Typography variant={'body1'} fontSize={12}>
              Branch
            </Typography>
          </Item>
          <Item>
            <Typography variant={'h6'} color={'inherit'} mb={0}>
              {student?.class_section?.class_section_title}
            </Typography>
            <Typography variant={'body1'} fontSize={12}>
              Class Section
            </Typography>
          </Item>
        </Stack>
      }
      sx={{
        position: 'relative',
        zIndex: 1,

        '& .MuiCardHeader-action': {
          alignSelf: 'center',
        },
      }}
    />
  );
};

export default Header;
