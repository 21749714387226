import React from 'react';
import { useDispatch } from 'react-redux';

import {
  ListItemText,
  ListItemAvatar,
  Tooltip,
  Typography,
  Avatar,
  Stack,
} from '@mui/material';
import { MoreHoriz, Edit, Delete } from '@mui/icons-material';
import styled from '@emotion/styled';

import JumboDdMenu from '@jumbo/components/JumboDdMenu';
import Span from '@jumbo/shared/Span';
import JumboListItem from '@jumbo/components/JumboList/components/JumboListItem';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';

import SubjectDetail from '../SubjectDetail';
import SubjectForm from '../SubjectForm';
import { deleteSubject } from 'app/store/features/subjectsSlice';
import { hasPermission } from 'app/utils/appHelpers';
import { PERMISSIONS, PERMISSION_TYPES } from 'app/utils/constants/permissions';

const Item = styled(Span)(({ theme }) => ({
  minWidth: 0,
  flexGrow: 0,
  padding: theme.spacing(0, 1),
}));

const SubjectItem = ({ item, view }) => {
  const { showDialog, hideDialog } = useJumboDialog();

  const dispatch = useDispatch();

  const showContactDetail = React.useCallback(() => {
    showDialog({
      content: <SubjectDetail data={item} onClose={hideDialog} />,
    });
  }, [showDialog, item, hideDialog]);

  const handleDeleteSubject = data => {
    dispatch(deleteSubject(data.id));
    hideDialog();
  };

  const handleItemAction = menuItem => {
    switch (menuItem.action) {
      case 'edit':
        showDialog({
          title: 'Update Subject',
          content: <SubjectForm payload={item} />,
        });
        break;
      case 'delete':
        showDialog({
          variant: 'confirm',
          title: 'Are you sure about deleting this subject?',
          content: "You won't be able to recover this subject later",
          onYes: () => handleDeleteSubject(item),
          onNo: hideDialog,
        });
        break;
      default:
        break;
    }
  };

  return (
    <JumboListItem
      componentElement={'div'}
      itemData={item}
      secondaryAction={
        <>
          {hasPermission(
            [PERMISSIONS.CLASS_SECTION_SUBJECTS],
            [PERMISSION_TYPES.EDIT, PERMISSION_TYPES.DELETE],
          ) && (
            <JumboDdMenu
              icon={<MoreHoriz />}
              menuItems={[
                hasPermission(
                  [PERMISSIONS.CLASS_SECTION_SUBJECTS],
                  [PERMISSION_TYPES.EDIT],
                ) && {
                  icon: <Edit />,
                  title: 'Edit',
                  action: 'edit',
                },
                hasPermission(
                  [PERMISSIONS.CLASS_SECTION_SUBJECTS],
                  [PERMISSION_TYPES.DELETE],
                ) && {
                  icon: <Delete />,
                  title: 'Delete',
                  action: 'delete',
                },
              ]}
              onClickCallback={handleItemAction}
            />
          )}
        </>
      }
      sx={{
        cursor: 'pointer',
        borderTop: 1,
        borderColor: 'divider',
        '&:hover': {
          bgcolor: 'action.hover',
        },
      }}>
      <ListItemAvatar onClick={showContactDetail}>
        <Avatar alt={item?.name} src={item?.profile_pic} />
      </ListItemAvatar>
      <ListItemText
        onClick={showContactDetail}
        primary={
          <Typography variant={'body1'} component={'div'}>
            <Stack direction={'row'} alignItems={'center'} sx={{ minWidth: 0 }}>
              <Item
                sx={{
                  flexBasis: { xs: '100%', sm: '50%', md: '25%' },
                }}>
                <Tooltip title="Title of Subject">
                  <Typography
                    variant={'h5'}
                    fontSize={14}
                    lineHeight={1.25}
                    mb={0}
                    noWrap>
                    {item?.name}
                  </Typography>
                </Tooltip>
              </Item>
              <Item
                sx={{
                  flexBasis: { sm: '50%', md: '28%' },
                  display: { xs: 'none', sm: 'block' },
                }}>
                <Tooltip title={'Subject Group'}>
                  <Typography variant={'body1'} noWrap>
                    {item?.group}
                  </Typography>
                </Tooltip>
              </Item>
              <Item
                sx={{
                  flexBasis: { sm: '50%', md: '28%' },
                  display: { xs: 'none', sm: 'block' },
                }}>
                <Tooltip title={'Fee Amount'}>
                  <Typography variant={'body1'} noWrap>
                    {item?.fee_amount}
                  </Typography>
                </Tooltip>
              </Item>
              <Item
                sx={{
                  flexBasis: { md: '25%' },
                  display: { xs: 'none', md: 'block' },
                }}>
                <Tooltip title="User Subject Code">
                  <Typography variant={'body1'} noWrap>
                    {item?.user_subject_code}
                  </Typography>
                </Tooltip>
              </Item>
              <Item
                sx={{
                  flexBasis: { md: '25%' },
                  display: { xs: 'none', md: 'block' },
                }}>
                <Tooltip title="Is Subject Active(status)?">
                  <Typography variant={'body1'} noWrap>
                    {item?.status ? 'Yes' : 'No'}
                  </Typography>
                </Tooltip>
              </Item>
            </Stack>
          </Typography>
        }
      />
    </JumboListItem>
  );
};

export default SubjectItem;
