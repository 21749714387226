import React from 'react';

import { ListItemText, Typography, Stack, Tooltip } from '@mui/material';
import { MoreHoriz, Edit, Delete } from '@mui/icons-material';

import styled from '@emotion/styled';

import JumboDdMenu from '@jumbo/components/JumboDdMenu';
import Span from '@jumbo/shared/Span';
import JumboListItem from '@jumbo/components/JumboList/components/JumboListItem';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';

import NonExpensePaymentForm from '../NonExpensePaymentForm';
import ExpenseDetails from '../StudentDetail';
import { useDispatch } from 'react-redux';
import { deleteNonExpensePayment } from 'app/store/features/nonExpenseSlice';
import { hasPermission } from 'app/utils/appHelpers';
import { PERMISSIONS, PERMISSION_TYPES } from 'app/utils/constants/permissions';

const Item = styled(Span)(({ theme }) => ({
  minWidth: 0,
  flexGrow: 0,
  padding: theme.spacing(0, 1),
}));

const ExpenseItem = ({ item, view }) => {
  const { showDialog, hideDialog } = useJumboDialog();
  const dispatch = useDispatch();

  const showExpenseDetails = React.useCallback(() => {
    showDialog({
      content: <ExpenseDetails item={item} onClose={hideDialog} />,
    });
  }, [showDialog, item, hideDialog]);

  const handleDelete = () => {
    dispatch(deleteNonExpensePayment(item.id));
  };

  const handleItemAction = menuItem => {
    switch (menuItem.action) {
      case 'edit':
        showDialog({
          title: 'Update Payment details',
          content: <NonExpensePaymentForm item={item} />,
        });
        break;
      case 'view':
        showExpenseDetails();
        break;
      case 'delete':
        handleDelete();
        break;
      default:
        break;
    }
  };

  return (
    <JumboListItem
      componentElement={'div'}
      itemData={item}
      secondaryAction={
        hasPermission(
          [PERMISSIONS.NON_EXPENSE_PAYMENT],
          [PERMISSION_TYPES.EDIT, PERMISSION_TYPES.DELETE],
        ) && (
          <>
            <JumboDdMenu
              icon={<MoreHoriz />}
              menuItems={[
                hasPermission(
                  [PERMISSIONS.NON_EXPENSE_PAYMENT],
                  [PERMISSION_TYPES.EDIT],
                ) && {
                  icon: <Edit />,
                  title: 'Update',
                  action: 'edit',
                },
                hasPermission(
                  [PERMISSIONS.NON_EXPENSE_PAYMENT],
                  [PERMISSION_TYPES.DELETE],
                ) && {
                  icon: <Delete />,
                  title: 'Delete',
                  action: 'delete',
                },
              ]}
              onClickCallback={handleItemAction}
            />
          </>
        )
      }
      sx={{
        cursor: 'pointer',
        borderTop: 1,
        borderColor: 'divider',
        '&:hover': {
          bgcolor: 'action.hover',
        },
      }}>
      <ListItemText
        onClick={showExpenseDetails}
        primary={
          <Typography variant={'body1'} component={'div'}>
            <Stack direction={'row'} alignItems={'center'} sx={{ minWidth: 0 }}>
              <Item
                sx={{
                  flexBasis: { xs: '100%', sm: '50%', md: '25%' },
                }}>
                <Tooltip title="Paid To">
                  <Typography
                    variant={'h5'}
                    fontSize={14}
                    lineHeight={1.25}
                    mb={0}
                    noWrap>
                    {item?.paid_to}
                  </Typography>
                </Tooltip>
              </Item>
              <Item
                sx={{
                  flexBasis: { sm: '50%', md: '28%' },
                  display: { xs: 'none', sm: 'block' },
                }}>
                <Tooltip title="Paid By">
                  <Typography variant={'body1'} noWrap>
                    {item?.paid_by?.username}
                  </Typography>
                </Tooltip>
              </Item>
              <Item
                sx={{
                  flexBasis: { md: '25%' },
                  display: { xs: 'none', md: 'block' },
                }}>
                <Tooltip title="Payment Mode">
                  <Typography variant={'body1'} noWrap>
                    {item?.payment_mode.charAt(0).toUpperCase() +
                      `${item?.payment_mode.slice(1)}`}
                  </Typography>
                </Tooltip>
              </Item>
              <Item
                sx={{
                  flexBasis: { md: '25%' },
                  display: { xs: 'none', md: 'block' },
                }}>
                <Tooltip title="Payment Amount">
                  <Typography variant={'body1'} noWrap>
                    Rs. {item?.paid_amount}
                  </Typography>
                </Tooltip>
              </Item>
              <Item
                sx={{
                  flexBasis: { md: '25%' },
                  display: { xs: 'none', md: 'block' },
                }}>
                <Tooltip title="Is Payment Approved">
                  <Typography variant={'body1'} noWrap>
                    {item?.is_approved ? 'Yes' : 'No'}
                  </Typography>
                </Tooltip>
              </Item>
            </Stack>
          </Typography>
        }
      />
    </JumboListItem>
  );
};

export default ExpenseItem;
