import { useDispatch } from 'react-redux';
import { Form, Formik } from 'formik';
import { Grid } from '@mui/material';

import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import Div from '@jumbo/shared/Div';
import Button from '@jumbo/components/JumboButton';

import { schema } from '../../utils/schema';
import { createColor, editColor } from 'app/store/features/configurationSlice';

const FormComponent = ({ payload }) => {
  const dispatch = useDispatch();
  const { hideDialog } = useJumboDialog();

  const isUpdate = payload ? true : false;
  const initialValues = {
    title: payload ? payload.title : '',
    description: payload ? payload.description : '',
    color: payload ? payload.color : '',
  };

  const addHandler = data => {
    dispatch(createColor(data));
  };

  const updateHandler = data => {
    const payload = {
      id: data.id,
      title: data.title,
      description: data.description,
      color: data.color,
    };
    dispatch(editColor(payload));
  };

  const onSave = data => {
    isUpdate ? updateHandler(data) : addHandler(data);
    hideDialog();
  };
  return (
    <Formik
      validateOnChange={true}
      initialValues={payload?.id ? payload : initialValues}
      enableReinitialize={true}
      validationSchema={schema}
      onSubmit={onSave}>
      {({ isSubmitting, values, setValues }) => (
        <Form noValidate autoComplete="off">
          <Div
            sx={{
              '& .MuiTextField-root': {
                mb: 3,
              },
            }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <JumboTextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  name="title"
                  label="Color Title"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <JumboTextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  name="description"
                  label="Color Description"
                />
              </Grid>
            </Grid>

            <JumboTextField
              fullWidth
              size="small"
              variant="outlined"
              name="color"
              label="Color code in hex #xxxxxx"
            />

            <Div
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}>
              <Button
                variant="outlined"
                sx={{
                  mr: 2,
                }}
                onClick={() => hideDialog()}>
                Close
              </Button>
              <Button type="submit" variant="contained" loading={isSubmitting}>
                Save
              </Button>
            </Div>
          </Div>
        </Form>
      )}
    </Formik>
  );
};

export default FormComponent;
