import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import AccountsSidebar from './components/AccountsSidebar';
import ExpensesList from './components/ExpensesList';
import { hasPermission } from 'app/utils/appHelpers';
import { PERMISSIONS, PERMISSION_TYPES } from 'app/utils/constants/permissions';

const Accounts = () => {
  return (
    <>
      <JumboContentLayout
        sidebar={
          hasPermission(
            [
              PERMISSIONS.BILL,
              PERMISSIONS.EXPENSE,
              PERMISSIONS.EXPENSE_CATEGORY,
            ],
            [PERMISSION_TYPES.ADD],
          ) && <AccountsSidebar />
        }>
        {hasPermission([PERMISSIONS.BILL], [PERMISSION_TYPES.LISTING]) && (
          <ExpensesList />
        )}
      </JumboContentLayout>
    </>
  );
};
export default Accounts;
