import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from '@mui/material';

const AttendancePaginatedTable = ({ data }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Attendance Date</TableCell>
            <TableCell>Student Name</TableCell>
            <TableCell>Roll Number</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Class</TableCell>
            <TableCell>Section</TableCell>
            <TableCell>Incharge</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!!data.length &&
            data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(row => (
                <TableRow key={row.id}>
                  <TableCell>{row.attendence_date}</TableCell>
                  <TableCell>
                    {row.attendence_students[0].student.first_name +
                      ' ' +
                      row.attendence_students[0].student.last_name}
                  </TableCell>
                  <TableCell>
                    {row.attendence_students[0].student.roll_no}
                  </TableCell>
                  <TableCell>
                    {row.attendence_students[0].is_present
                      ? 'Present'
                      : row.attendence_students[0].is_excused
                        ? 'On Leave'
                        : row.attendence_students[0].is_unexcused
                          ? 'Absent'
                          : 'Late'}
                  </TableCell>
                  <TableCell>{row.class_section.class.name}</TableCell>
                  <TableCell>{row.class_section.section.name}</TableCell>
                  <TableCell>{row.class_section.incharge.username}</TableCell>
                </TableRow>
              ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};

export default AttendancePaginatedTable;
